import { useEffect } from 'react';
import styled from 'styled-components';
import { ThemeProps } from '../lib/theme';

interface StyleProps {
  displayBorder?: boolean;
  displayArrow?: boolean;
  isListItem?: true;
}

export interface AccordionProps extends StyleProps {
  id: number | string;
  content: React.ReactNode;
  className?: string;
  closeOnClickOutside?: boolean;
  open?: boolean;
  contentPadding?: string;
  children: React.ReactNode;
}

const listItemPadding = '1rem 1rem 1.5em';

export const ContentArea = styled.div(
  ({ contentPadding }: Pick<AccordionProps, 'contentPadding'>) => `
  padding: ${contentPadding || '1rem 0 1.5em'} ;
`,
);

const arrowStyling = (theme: ThemeProps['theme']) => `
  position: relative;
  padding-right: 3rem;

  &:after {
    content: "";
    width: 1rem;
    height: 1rem;
    background: url(/icons/arrow.svg) center no-repeat;
    position: absolute;
    top: calc(50% - 0.5rem);
    right: 1rem;
    transform: rotate(90deg);
    transition-timing-function: ease-out;
    transition-duration: ${theme.transitionTime.snappy};
  }
`;

const borderStyling = (theme: ThemeProps['theme']) => `
  border-top: 1px solid ${theme.colors.borderColor};

  &:last-of-type {
    border-bottom: 1px solid ${theme.colors.borderColor};
  }
`;

const Summary = styled.summary<ThemeProps & StyleProps>(
  ({ displayArrow, isListItem, theme }: ThemeProps & StyleProps) => `
  width: 100%;
  padding: ${isListItem ? '1rem' : '0'};
  ${displayArrow ? arrowStyling(theme) : ''}
  
  ::marker,  
  ::-webkit-details-marker  { 
    content: none; /* Chrome, Firefox, Edge */
    display: none; /* Safari */
  }
`,
);

const Details = styled.details<ThemeProps & StyleProps>(
  ({ theme, displayBorder }) => `
  
  ${displayBorder ? borderStyling(theme) : ''};

  &[open] summary:after {
    transform: rotate(-90deg);
  }
  
  &:not(:first-child) {
    margin-top: 2em;
  }

  & ~ & {
    margin-top: 0;
  }
`,
);

const Accordion = ({
  id,
  content,
  children = null,
  className,
  closeOnClickOutside = false,
  displayBorder,
  displayArrow = false,
  isListItem,
  open = false,
  contentPadding,
}: AccordionProps) => {
  const detailsId = `details-${id}`;
  const summaryId = `summary-${id}`;
  const contentId = `content-${id}`;

  useEffect(() => {
    const onOutsideClick = (e: MouseEvent) => {
      const details = document.getElementById(detailsId);
      const summary = document.getElementById(summaryId);
      const isOutsideClick = e.target !== details && e.target !== summary;

      if (details?.hasAttribute('open') && isOutsideClick) {
        details.removeAttribute('open');
      }
    };

    if (closeOnClickOutside) {
      window.addEventListener('click', onOutsideClick);
    }
    return () => {
      window.removeEventListener('click', onOutsideClick);
    };
  });

  return (
    <Details
      displayBorder={displayBorder}
      id={detailsId}
      className={className}
      open={open}
    >
      <Summary
        id={summaryId}
        displayArrow={displayArrow}
        isListItem={isListItem}
      >
        {children}
      </Summary>
      <ContentArea
        id={contentId}
        contentPadding={contentPadding || (isListItem && listItemPadding)}
      >
        {content}
      </ContentArea>
    </Details>
  );
};

export default Accordion;
