import { useLogout, useTranslation } from '@lib/hooks';
import { ThemeProps } from '@lib/theme';
import styled from 'styled-components';

const ButtonStyle = styled.button.attrs({
  type: 'button',
})(
  ({
    theme: { breakpoints, colors, fontPresets, transitionTime },
  }: ThemeProps) => `
    ${fontPresets.paragraph};
    font-weight: 600;
    text-decoration: underline;
    
    @media (min-width: ${breakpoints.md}) {    
      font-weight: 400;
      text-decoration: inherit;
    }
  
  
    @media (hover: hover) and (pointer: fine) {
      &:hover {
        transition: ${transitionTime.snappy} ease-in;
        color: ${colors.highlightColorAlt};
        text-decoration: underline;
      }
    }
  `,
);

interface Props {
  callback?: () => void;
}

const LogoutButton = ({ callback }: Props) => {
  const t = useTranslation();
  const logout = useLogout();

  return (
    <ButtonStyle
      onClick={async (e) => {
        e.preventDefault();
        await logout(callback);
      }}
    >
      {t('nav.logout')}
    </ButtonStyle>
  );
};

export default LogoutButton;
