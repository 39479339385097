import {
  Gender,
  Macro,
  MeasurementUnit,
  ProductType,
  ShippingCountry,
  SiteLocale,
  SpecialNeed,
} from './enums';

type SKU = string;

export interface DogForRecommendation {
  activity_level: string;
  birthday: string;
  body_condition: number;
  gender: Gender;
  special_needs: string[];
  weight: string;
  preferred_ingredients: string[];
  dietShareDry: number;
  dietShareWet: number;
  dietShareTreats: number;
  dietShareOther: number;
  shipping_country: ShippingCountry;
}

export interface ErrorResponse {
  error: string;
  description: string;
}

/**
 * Endpoint: {publicApi}/recommendation/breeds
 */

export type RecommendationApiBreedsResponse = Breed[] | ErrorResponse;

export interface Breed {
  female: WeightEstimate;
  male: WeightEstimate;
  name: string;
  fci: {
    id?: number;
  };
  id: number;
  names: Record<SiteLocale, string>;
}

export interface WeightEstimate {
  maxWeight: number;
  minWeight: number;
  avgWeight: number;
}

/**
 * Endpoint: {publicApi}/recommendation/preferred-ingredients
 */

export type RecommendationApiPreferredIngredientsResponse =
  | PreferredIngredientObject[]
  | ErrorResponse;

export interface PreferredIngredientObject {
  key: string;
  name: string;
  title: string;
}

/**
 * Endpoint: {publicApi}/recommendation/special-needs/
 */

export type RecommendationApiSpecialNeedsResponse =
  | SpecialNeedsObject[]
  | ErrorResponse;

export interface SpecialNeedsObject {
  key: string;
  name: string;
  title: string;
}

export type ComplexType =
  | 'skin_&_coat'
  | 'digestion'
  | 'sensitivity'
  | 'weight_control'
  | 'joint_support'
  | 'puppy'
  | 'senior'
  | 'mother_&_puppy';

export interface Complex {
  description: string[];
  image: string;
  listDescription: string;
  title: string;
  type: string;
}

/**
 * Endpoint: {publicApi}/recommendation/[recommendation query]
 */

export type RecommendationApiResponse = Recommendation | ErrorResponse;

export interface Recommendation {
  activityLevel: number;
  ageInMonths: number;
  ageInYears: number;
  bcs: number;
  gender: Gender;
  kcal: number;
  metabolicWeight: number;
  neutered: boolean;
  preferredIngredients: string[];
  scenario: string;
  specialNeeds: string[];
  weight: number;
  shippingCountry: ShippingCountry;
  lang: SiteLocale;
  onlySuitable: boolean;
  dietShareDry: number;
  dietShareWet: number;
  dietShareTreats: number;
  dietShareOther: number;
  recommendedDryFood: DietItem;
  recommendedWetFood: DietItem | null;
  recommendedTreat: DietItem | null;
  recommendedSupplement: DietItem | null;
  alternativeDryFoods: DietItem[];
  alternativeWetFoods: DietItem[];
  alternativeTreats: DietItem[];
  alternativeSupplements: DietItem[];
  unsuitableDryFoods: DietItem[];
  uncategorizedDryFoods: DietItem[];
  version: string;
}

export enum VariantSalesUnit {
  kilo = 'kilo',
  cm = 'cm',
  liter = 'liter',
  pcs = 'pcs',
  fallback = 'fallback',
}

export interface Variant {
  title: string;
  shortTitle: string;
  wordpressId: number;
  size: number;
  unit: VariantSalesUnit;
  pcs: number;
  regularPrice: number;
  salePrice?: number | null;
  emission: number | null;
  emissionComparison?: number | null;
  inStock: boolean;
}

export interface ImageObject {
  src: string;
  alt: string;
  title: string;
}

export interface IngredientHighlight {
  content: string;
  title: string;
  image: ImageObject;
  value: number;
}

export interface RecipeCoveredNeed {
  description: string;
  type: SpecialNeed | string;
}

interface AlternativeMeasureObject {
  unit: MeasurementUnit;
  measure: number;
}

interface ListObject {
  title: string;
  list: string;
}

export enum DietItemFit {
  recommended = 'recommended',
  good = 'good',
  notSuitable = 'notSuitable',
}

export interface DietItem extends RecommendationProduct {
  shareOfDiet: number;
  dailyKcal: number;
  dailyGrams: number;
  alternativeMeasure: AlternativeMeasureObject;
  fit?: DietItemFit;
  selectionCriteria?: string;
}

export interface MacroObject {
  type: Macro;
  value: number;
}

/**
 * Endpoint: {publicApi}/products
 */

export type RecommendationProductsApiResponse =
  | RecommendationProduct[]
  | ErrorResponse;

export interface RecommendationProduct {
  complexes: Complex[];
  description: { base: string; more?: string; listDescription: string };
  emission: number | null; // dry foods are the same for all sizes, this is the smallest variant for other categories.
  emissionDetails: {
    ingredients?: number;
    shipping?: number;
    packaging?: number;
    production?: number;
    warehousing?: number;
    materials?: number;
    total: number;
  } | null;
  emissionComparison: number | null; // dry foods are the same for all sizes, this is the first variant for other categories
  feedingGuide: string;
  grainFree: boolean;
  heroImage: ImageObject | null;
  id: SKU;
  images: ImageObject[];
  inStock: boolean;
  ingredients: IngredientHighlight[];
  ingredientsFull: string;
  kibbleDensity: number;
  maskedImage: ImageObject;
  mainProtein: string | undefined;
  nutrition: MacroObject[];
  nutritionLists: {
    key:
      | 'analytical_constituents'
      | 'added_vitamins'
      | 'trace_elements'
      | 'amino_acids'
      | string;
    items: { key: string; amount: number; unit: string }[];
  }[];
  prices: {
    size: number;
    price: number;
  }[];
  shortTitle: string;
  specialNeeds: RecipeCoveredNeed[];
  title: string;
  type: ProductType;
  variants: Variant[];
  wordpressId: number;
  wcCategories: number[];
  oneOff: true | null;
}
