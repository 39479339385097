import { ReactNode } from 'react';
import styled, { StyledComponent } from 'styled-components';
import ArrowLink from './ArrowLink';
import { SmallText } from './Typography';
import { ReactComponent as CloseIcon } from '../public/icons/close.svg';
import { ReactComponent as WarningTriangle } from '../public/icons/warning-triangle.svg';
import { ReactComponent as CheckMark } from '../public/icons/check.svg';
import { ReactComponent as Heart } from '../public/icons/heart.svg';
import { ReactComponent as Gift } from '../public/illustrations/gift.svg';
import { ReactComponent as Bowl } from '../public/icons/bowl.svg';

export type NotificationType =
  | 'error'
  | 'warning'
  | 'success'
  | 'shipping-headsup'
  | 'missing-payment'
  | 'free-shipping'
  | 'referral-order'
  | 'session-expired'
  | 'feeding-heads-up'
  | 'green';

interface Props {
  type: NotificationType;
  href?: string;
  children: ReactNode;
  className?: string;
  linkLike?: boolean; // linkLike = looks and feels like a link, but isn't one. Use when you need to wrap Notification with a button for example, which handles async stuff first eg.
  onRemove?: () => void;
  onClick?: () => void;
}

const RemoveButton = styled.button.attrs({
  children: <CloseIcon aria-label="close" alt="close" />,
  type: 'button',
})`
  display: flex;
  align-items: center;
  transition: transform 0.125s ease-in;
  &:hover,
  &:focus {
    transform: scale(1.075);
    transition-timing-function: ease-out;
  }
`;

const NotificationStyle = styled.p(
  ({ theme: { colors } }) => `
  display: flex;
  border-radius: 4px;
  width: 100%;
  gap: 0.5rem;
  background: ${colors.status.alarm};
  padding: 1rem;
  margin-top: 1rem;

  ${SmallText} {
    text-align: left;
    display: flex;
    align-items: center;
    color: inherit;
    flex: 1 1 auto;
  }

  ${RemoveButton} {
    padding: 0 .75rem;
    margin-right: -.75rem;
  }
 
  &[data-type="success"], 
  &[data-type="free"],
  &[data-type="positive"],
  &[data-type="feeding"],
  &[data-type="green"] {
    background: ${colors.status.positive};
    padding: 1rem;
  } 
  
  .icon {
    height: 30px;
    margin: 0;
  }
  
  .warning {
    fill: ${colors.status.warning};
  }

  .success {
    height: 18px;
  }

  .free {
    & path {
      fill: ${colors.card.darkGreen};
    }
  }
`,
);

const StyledArrowLink = NotificationStyle.withComponent(ArrowLink);

const resolveTypeIntoStatus = (type: NotificationType): string =>
  ({
    error: 'error',
    warning: 'warning',
    success: 'success',
    'free-shipping': 'free',
    'referral-order': 'free',
    'shipping-headsup': 'positive',
    'missing-payment': 'blocker',
    'session-expired': 'blocker',
    'feeding-heads-up': 'feeding',
    green: 'green',
  }[type]);

const icons: Record<string, JSX.Element | null> = {
  blocker: <WarningTriangle className="icon warning" />,
  success: <CheckMark className="icon success" />,
  free: <Heart className="icon free" />,
  positive: <Gift className="icon " />,
  feeding: <Bowl className="icon" />,
  green: null,
};

const Notification = ({
  type,
  href,
  children,
  onRemove,
  linkLike,
  ...rest
}: Props) => {
  const Component: StyledComponent<
    'p' | ((p: any) => JSX.Element),
    any,
    any,
    any
  > = href || linkLike ? StyledArrowLink : NotificationStyle;

  const icon = icons[resolveTypeIntoStatus(type)] || null;

  return (
    <Component
      href={href || (linkLike ? '' : undefined)}
      data-type={resolveTypeIntoStatus(type)}
      {...rest}
    >
      {icon}
      <SmallText>{children}</SmallText>
      {onRemove && !(href || linkLike) ? (
        <RemoveButton onClick={onRemove} />
      ) : null}
    </Component>
  );
};

export default Notification;
