// taken from https://gist.github.com/anubhavshrimal/75f6183458db8c453306f93521e93d37 and renamed properties to follow postal-code naming

export default [
  {
    country: 'Afghanistan',
    prefix: '+93',
    ISO: 'AF',
  },
  {
    country: 'Aland Islands',
    prefix: '+358',
    ISO: 'AX',
  },
  {
    country: 'Albania',
    prefix: '+355',
    ISO: 'AL',
  },
  {
    country: 'Algeria',
    prefix: '+213',
    ISO: 'DZ',
  },
  {
    country: 'AmericanSamoa',
    prefix: '+1684',
    ISO: 'AS',
  },
  {
    country: 'Andorra',
    prefix: '+376',
    ISO: 'AD',
  },
  {
    country: 'Angola',
    prefix: '+244',
    ISO: 'AO',
  },
  {
    country: 'Anguilla',
    prefix: '+1264',
    ISO: 'AI',
  },
  {
    country: 'Antarctica',
    prefix: '+672',
    ISO: 'AQ',
  },
  {
    country: 'Antigua and Barbuda',
    prefix: '+1268',
    ISO: 'AG',
  },
  {
    country: 'Argentina',
    prefix: '+54',
    ISO: 'AR',
  },
  {
    country: 'Armenia',
    prefix: '+374',
    ISO: 'AM',
  },
  {
    country: 'Aruba',
    prefix: '+297',
    ISO: 'AW',
  },
  {
    country: 'Australia',
    prefix: '+61',
    ISO: 'AU',
  },
  {
    country: 'Austria',
    prefix: '+43',
    ISO: 'AT',
  },
  {
    country: 'Azerbaijan',
    prefix: '+994',
    ISO: 'AZ',
  },
  {
    country: 'Bahamas',
    prefix: '+1242',
    ISO: 'BS',
  },
  {
    country: 'Bahrain',
    prefix: '+973',
    ISO: 'BH',
  },
  {
    country: 'Bangladesh',
    prefix: '+880',
    ISO: 'BD',
  },
  {
    country: 'Barbados',
    prefix: '+1246',
    ISO: 'BB',
  },
  {
    country: 'Belarus',
    prefix: '+375',
    ISO: 'BY',
  },
  {
    country: 'Belgium',
    prefix: '+32',
    ISO: 'BE',
  },
  {
    country: 'Belize',
    prefix: '+501',
    ISO: 'BZ',
  },
  {
    country: 'Benin',
    prefix: '+229',
    ISO: 'BJ',
  },
  {
    country: 'Bermuda',
    prefix: '+1441',
    ISO: 'BM',
  },
  {
    country: 'Bhutan',
    prefix: '+975',
    ISO: 'BT',
  },
  {
    country: 'Bolivia, Plurinational State of',
    prefix: '+591',
    ISO: 'BO',
  },
  {
    country: 'Bosnia and Herzegovina',
    prefix: '+387',
    ISO: 'BA',
  },
  {
    country: 'Botswana',
    prefix: '+267',
    ISO: 'BW',
  },
  {
    country: 'Brazil',
    prefix: '+55',
    ISO: 'BR',
  },
  {
    country: 'British Indian Ocean Territory',
    prefix: '+246',
    ISO: 'IO',
  },
  {
    country: 'Brunei Darussalam',
    prefix: '+673',
    ISO: 'BN',
  },
  {
    country: 'Bulgaria',
    prefix: '+359',
    ISO: 'BG',
  },
  {
    country: 'Burkina Faso',
    prefix: '+226',
    ISO: 'BF',
  },
  {
    country: 'Burundi',
    prefix: '+257',
    ISO: 'BI',
  },
  {
    country: 'Cambodia',
    prefix: '+855',
    ISO: 'KH',
  },
  {
    country: 'Cameroon',
    prefix: '+237',
    ISO: 'CM',
  },
  {
    country: 'Canada',
    prefix: '+1',
    ISO: 'CA',
  },
  {
    country: 'Cape Verde',
    prefix: '+238',
    ISO: 'CV',
  },
  {
    country: 'Cayman Islands',
    prefix: '+ 345',
    ISO: 'KY',
  },
  {
    country: 'Central African Republic',
    prefix: '+236',
    ISO: 'CF',
  },
  {
    country: 'Chad',
    prefix: '+235',
    ISO: 'TD',
  },
  {
    country: 'Chile',
    prefix: '+56',
    ISO: 'CL',
  },
  {
    country: 'China',
    prefix: '+86',
    ISO: 'CN',
  },
  {
    country: 'Christmas Island',
    prefix: '+61',
    ISO: 'CX',
  },
  {
    country: 'Cocos (Keeling) Islands',
    prefix: '+61',
    ISO: 'CC',
  },
  {
    country: 'Colombia',
    prefix: '+57',
    ISO: 'CO',
  },
  {
    country: 'Comoros',
    prefix: '+269',
    ISO: 'KM',
  },
  {
    country: 'Congo',
    prefix: '+242',
    ISO: 'CG',
  },
  {
    country: 'Congo, The Democratic Republic of the Congo',
    prefix: '+243',
    ISO: 'CD',
  },
  {
    country: 'Cook Islands',
    prefix: '+682',
    ISO: 'CK',
  },
  {
    country: 'Costa Rica',
    prefix: '+506',
    ISO: 'CR',
  },
  {
    country: "Cote d'Ivoire",
    prefix: '+225',
    ISO: 'CI',
  },
  {
    country: 'Croatia',
    prefix: '+385',
    ISO: 'HR',
  },
  {
    country: 'Cuba',
    prefix: '+53',
    ISO: 'CU',
  },
  {
    country: 'Cyprus',
    prefix: '+357',
    ISO: 'CY',
  },
  {
    country: 'Czech Republic',
    prefix: '+420',
    ISO: 'CZ',
  },
  {
    country: 'Denmark',
    prefix: '+45',
    ISO: 'DK',
  },
  {
    country: 'Djibouti',
    prefix: '+253',
    ISO: 'DJ',
  },
  {
    country: 'Dominica',
    prefix: '+1767',
    ISO: 'DM',
  },
  {
    country: 'Dominican Republic',
    prefix: '+1849',
    ISO: 'DO',
  },
  {
    country: 'Ecuador',
    prefix: '+593',
    ISO: 'EC',
  },
  {
    country: 'Egypt',
    prefix: '+20',
    ISO: 'EG',
  },
  {
    country: 'El Salvador',
    prefix: '+503',
    ISO: 'SV',
  },
  {
    country: 'Equatorial Guinea',
    prefix: '+240',
    ISO: 'GQ',
  },
  {
    country: 'Eritrea',
    prefix: '+291',
    ISO: 'ER',
  },
  {
    country: 'Estonia',
    prefix: '+372',
    ISO: 'EE',
  },
  {
    country: 'Ethiopia',
    prefix: '+251',
    ISO: 'ET',
  },
  {
    country: 'Falkland Islands (Malvinas)',
    prefix: '+500',
    ISO: 'FK',
  },
  {
    country: 'Faroe Islands',
    prefix: '+298',
    ISO: 'FO',
  },
  {
    country: 'Fiji',
    prefix: '+679',
    ISO: 'FJ',
  },
  {
    country: 'Finland',
    prefix: '+358',
    ISO: 'FI',
  },
  {
    country: 'France',
    prefix: '+33',
    ISO: 'FR',
  },
  {
    country: 'French Guiana',
    prefix: '+594',
    ISO: 'GF',
  },
  {
    country: 'French Polynesia',
    prefix: '+689',
    ISO: 'PF',
  },
  {
    country: 'Gabon',
    prefix: '+241',
    ISO: 'GA',
  },
  {
    country: 'Gambia',
    prefix: '+220',
    ISO: 'GM',
  },
  {
    country: 'Georgia',
    prefix: '+995',
    ISO: 'GE',
  },
  {
    country: 'Germany',
    prefix: '+49',
    ISO: 'DE',
  },
  {
    country: 'Ghana',
    prefix: '+233',
    ISO: 'GH',
  },
  {
    country: 'Gibraltar',
    prefix: '+350',
    ISO: 'GI',
  },
  {
    country: 'Greece',
    prefix: '+30',
    ISO: 'GR',
  },
  {
    country: 'Greenland',
    prefix: '+299',
    ISO: 'GL',
  },
  {
    country: 'Grenada',
    prefix: '+1473',
    ISO: 'GD',
  },
  {
    country: 'Guadeloupe',
    prefix: '+590',
    ISO: 'GP',
  },
  {
    country: 'Guam',
    prefix: '+1671',
    ISO: 'GU',
  },
  {
    country: 'Guatemala',
    prefix: '+502',
    ISO: 'GT',
  },
  {
    country: 'Guernsey',
    prefix: '+44',
    ISO: 'GG',
  },
  {
    country: 'Guinea',
    prefix: '+224',
    ISO: 'GN',
  },
  {
    country: 'Guinea-Bissau',
    prefix: '+245',
    ISO: 'GW',
  },
  {
    country: 'Guyana',
    prefix: '+595',
    ISO: 'GY',
  },
  {
    country: 'Haiti',
    prefix: '+509',
    ISO: 'HT',
  },
  {
    country: 'Holy See (Vatican City State)',
    prefix: '+379',
    ISO: 'VA',
  },
  {
    country: 'Honduras',
    prefix: '+504',
    ISO: 'HN',
  },
  {
    country: 'Hong Kong',
    prefix: '+852',
    ISO: 'HK',
  },
  {
    country: 'Hungary',
    prefix: '+36',
    ISO: 'HU',
  },
  {
    country: 'Iceland',
    prefix: '+354',
    ISO: 'IS',
  },
  {
    country: 'India',
    prefix: '+91',
    ISO: 'IN',
  },
  {
    country: 'Indonesia',
    prefix: '+62',
    ISO: 'ID',
  },
  {
    country: 'Iran, Islamic Republic of Persian Gulf',
    prefix: '+98',
    ISO: 'IR',
  },
  {
    country: 'Iraq',
    prefix: '+964',
    ISO: 'IQ',
  },
  {
    country: 'Ireland',
    prefix: '+353',
    ISO: 'IE',
  },
  {
    country: 'Isle of Man',
    prefix: '+44',
    ISO: 'IM',
  },
  {
    country: 'Israel',
    prefix: '+972',
    ISO: 'IL',
  },
  {
    country: 'Italy',
    prefix: '+39',
    ISO: 'IT',
  },
  {
    country: 'Jamaica',
    prefix: '+1876',
    ISO: 'JM',
  },
  {
    country: 'Japan',
    prefix: '+81',
    ISO: 'JP',
  },
  {
    country: 'Jersey',
    prefix: '+44',
    ISO: 'JE',
  },
  {
    country: 'Jordan',
    prefix: '+962',
    ISO: 'JO',
  },
  {
    country: 'Kazakhstan',
    prefix: '+77',
    ISO: 'KZ',
  },
  {
    country: 'Kenya',
    prefix: '+254',
    ISO: 'KE',
  },
  {
    country: 'Kiribati',
    prefix: '+686',
    ISO: 'KI',
  },
  {
    country: "Korea, Democratic People's Republic of Korea",
    prefix: '+850',
    ISO: 'KP',
  },
  {
    country: 'Korea, Republic of South Korea',
    prefix: '+82',
    ISO: 'KR',
  },
  {
    country: 'Kuwait',
    prefix: '+965',
    ISO: 'KW',
  },
  {
    country: 'Kyrgyzstan',
    prefix: '+996',
    ISO: 'KG',
  },
  {
    country: 'Laos',
    prefix: '+856',
    ISO: 'LA',
  },
  {
    country: 'Latvia',
    prefix: '+371',
    ISO: 'LV',
  },
  {
    country: 'Lebanon',
    prefix: '+961',
    ISO: 'LB',
  },
  {
    country: 'Lesotho',
    prefix: '+266',
    ISO: 'LS',
  },
  {
    country: 'Liberia',
    prefix: '+231',
    ISO: 'LR',
  },
  {
    country: 'Libyan Arab Jamahiriya',
    prefix: '+218',
    ISO: 'LY',
  },
  {
    country: 'Liechtenstein',
    prefix: '+423',
    ISO: 'LI',
  },
  {
    country: 'Lithuania',
    prefix: '+370',
    ISO: 'LT',
  },
  {
    country: 'Luxembourg',
    prefix: '+352',
    ISO: 'LU',
  },
  {
    country: 'Macao',
    prefix: '+853',
    ISO: 'MO',
  },
  {
    country: 'Macedonia',
    prefix: '+389',
    ISO: 'MK',
  },
  {
    country: 'Madagascar',
    prefix: '+261',
    ISO: 'MG',
  },
  {
    country: 'Malawi',
    prefix: '+265',
    ISO: 'MW',
  },
  {
    country: 'Malaysia',
    prefix: '+60',
    ISO: 'MY',
  },
  {
    country: 'Maldives',
    prefix: '+960',
    ISO: 'MV',
  },
  {
    country: 'Mali',
    prefix: '+223',
    ISO: 'ML',
  },
  {
    country: 'Malta',
    prefix: '+356',
    ISO: 'MT',
  },
  {
    country: 'Marshall Islands',
    prefix: '+692',
    ISO: 'MH',
  },
  {
    country: 'Martinique',
    prefix: '+596',
    ISO: 'MQ',
  },
  {
    country: 'Mauritania',
    prefix: '+222',
    ISO: 'MR',
  },
  {
    country: 'Mauritius',
    prefix: '+230',
    ISO: 'MU',
  },
  {
    country: 'Mayotte',
    prefix: '+262',
    ISO: 'YT',
  },
  {
    country: 'Mexico',
    prefix: '+52',
    ISO: 'MX',
  },
  {
    country: 'Micronesia, Federated States of Micronesia',
    prefix: '+691',
    ISO: 'FM',
  },
  {
    country: 'Moldova',
    prefix: '+373',
    ISO: 'MD',
  },
  {
    country: 'Monaco',
    prefix: '+377',
    ISO: 'MC',
  },
  {
    country: 'Mongolia',
    prefix: '+976',
    ISO: 'MN',
  },
  {
    country: 'Montenegro',
    prefix: '+382',
    ISO: 'ME',
  },
  {
    country: 'Montserrat',
    prefix: '+1664',
    ISO: 'MS',
  },
  {
    country: 'Morocco',
    prefix: '+212',
    ISO: 'MA',
  },
  {
    country: 'Mozambique',
    prefix: '+258',
    ISO: 'MZ',
  },
  {
    country: 'Myanmar',
    prefix: '+95',
    ISO: 'MM',
  },
  {
    country: 'Namibia',
    prefix: '+264',
    ISO: 'NA',
  },
  {
    country: 'Nauru',
    prefix: '+674',
    ISO: 'NR',
  },
  {
    country: 'Nepal',
    prefix: '+977',
    ISO: 'NP',
  },
  {
    country: 'Netherlands',
    prefix: '+31',
    ISO: 'NL',
  },
  {
    country: 'Netherlands Antilles',
    prefix: '+599',
    ISO: 'AN',
  },
  {
    country: 'New Caledonia',
    prefix: '+687',
    ISO: 'NC',
  },
  {
    country: 'New Zealand',
    prefix: '+64',
    ISO: 'NZ',
  },
  {
    country: 'Nicaragua',
    prefix: '+505',
    ISO: 'NI',
  },
  {
    country: 'Niger',
    prefix: '+227',
    ISO: 'NE',
  },
  {
    country: 'Nigeria',
    prefix: '+234',
    ISO: 'NG',
  },
  {
    country: 'Niue',
    prefix: '+683',
    ISO: 'NU',
  },
  {
    country: 'Norfolk Island',
    prefix: '+672',
    ISO: 'NF',
  },
  {
    country: 'Northern Mariana Islands',
    prefix: '+1670',
    ISO: 'MP',
  },
  {
    country: 'Norway',
    prefix: '+47',
    ISO: 'NO',
  },
  {
    country: 'Oman',
    prefix: '+968',
    ISO: 'OM',
  },
  {
    country: 'Pakistan',
    prefix: '+92',
    ISO: 'PK',
  },
  {
    country: 'Palau',
    prefix: '+680',
    ISO: 'PW',
  },
  {
    country: 'Palestinian Territory, Occupied',
    prefix: '+970',
    ISO: 'PS',
  },
  {
    country: 'Panama',
    prefix: '+507',
    ISO: 'PA',
  },
  {
    country: 'Papua New Guinea',
    prefix: '+675',
    ISO: 'PG',
  },
  {
    country: 'Paraguay',
    prefix: '+595',
    ISO: 'PY',
  },
  {
    country: 'Peru',
    prefix: '+51',
    ISO: 'PE',
  },
  {
    country: 'Philippines',
    prefix: '+63',
    ISO: 'PH',
  },
  {
    country: 'Pitcairn',
    prefix: '+872',
    ISO: 'PN',
  },
  {
    country: 'Poland',
    prefix: '+48',
    ISO: 'PL',
  },
  {
    country: 'Portugal',
    prefix: '+351',
    ISO: 'PT',
  },
  {
    country: 'Puerto Rico',
    prefix: '+1939',
    ISO: 'PR',
  },
  {
    country: 'Qatar',
    prefix: '+974',
    ISO: 'QA',
  },
  {
    country: 'Romania',
    prefix: '+40',
    ISO: 'RO',
  },
  {
    country: 'Russia',
    prefix: '+7',
    ISO: 'RU',
  },
  {
    country: 'Rwanda',
    prefix: '+250',
    ISO: 'RW',
  },
  {
    country: 'Reunion',
    prefix: '+262',
    ISO: 'RE',
  },
  {
    country: 'Saint Barthelemy',
    prefix: '+590',
    ISO: 'BL',
  },
  {
    country: 'Saint Helena, Ascension and Tristan Da Cunha',
    prefix: '+290',
    ISO: 'SH',
  },
  {
    country: 'Saint Kitts and Nevis',
    prefix: '+1869',
    ISO: 'KN',
  },
  {
    country: 'Saint Lucia',
    prefix: '+1758',
    ISO: 'LC',
  },
  {
    country: 'Saint Martin',
    prefix: '+590',
    ISO: 'MF',
  },
  {
    country: 'Saint Pierre and Miquelon',
    prefix: '+508',
    ISO: 'PM',
  },
  {
    country: 'Saint Vincent and the Grenadines',
    prefix: '+1784',
    ISO: 'VC',
  },
  {
    country: 'Samoa',
    prefix: '+685',
    ISO: 'WS',
  },
  {
    country: 'San Marino',
    prefix: '+378',
    ISO: 'SM',
  },
  {
    country: 'Sao Tome and Principe',
    prefix: '+239',
    ISO: 'ST',
  },
  {
    country: 'Saudi Arabia',
    prefix: '+966',
    ISO: 'SA',
  },
  {
    country: 'Senegal',
    prefix: '+221',
    ISO: 'SN',
  },
  {
    country: 'Serbia',
    prefix: '+381',
    ISO: 'RS',
  },
  {
    country: 'Seychelles',
    prefix: '+248',
    ISO: 'SC',
  },
  {
    country: 'Sierra Leone',
    prefix: '+232',
    ISO: 'SL',
  },
  {
    country: 'Singapore',
    prefix: '+65',
    ISO: 'SG',
  },
  {
    country: 'Slovakia',
    prefix: '+421',
    ISO: 'SK',
  },
  {
    country: 'Slovenia',
    prefix: '+386',
    ISO: 'SI',
  },
  {
    country: 'Solomon Islands',
    prefix: '+677',
    ISO: 'SB',
  },
  {
    country: 'Somalia',
    prefix: '+252',
    ISO: 'SO',
  },
  {
    country: 'South Africa',
    prefix: '+27',
    ISO: 'ZA',
  },
  {
    country: 'South Sudan',
    prefix: '+211',
    ISO: 'SS',
  },
  {
    country: 'South Georgia and the South Sandwich Islands',
    prefix: '+500',
    ISO: 'GS',
  },
  {
    country: 'Spain',
    prefix: '+34',
    ISO: 'ES',
  },
  {
    country: 'Sri Lanka',
    prefix: '+94',
    ISO: 'LK',
  },
  {
    country: 'Sudan',
    prefix: '+249',
    ISO: 'SD',
  },
  {
    country: 'Suricountry',
    prefix: '+597',
    ISO: 'SR',
  },
  {
    country: 'Svalbard and Jan Mayen',
    prefix: '+47',
    ISO: 'SJ',
  },
  {
    country: 'Swaziland',
    prefix: '+268',
    ISO: 'SZ',
  },
  {
    country: 'Sweden',
    prefix: '+46',
    ISO: 'SE',
  },
  {
    country: 'Switzerland',
    prefix: '+41',
    ISO: 'CH',
  },
  {
    country: 'Syrian Arab Republic',
    prefix: '+963',
    ISO: 'SY',
  },
  {
    country: 'Taiwan',
    prefix: '+886',
    ISO: 'TW',
  },
  {
    country: 'Tajikistan',
    prefix: '+992',
    ISO: 'TJ',
  },
  {
    country: 'Tanzania, United Republic of Tanzania',
    prefix: '+255',
    ISO: 'TZ',
  },
  {
    country: 'Thailand',
    prefix: '+66',
    ISO: 'TH',
  },
  {
    country: 'Timor-Leste',
    prefix: '+670',
    ISO: 'TL',
  },
  {
    country: 'Togo',
    prefix: '+228',
    ISO: 'TG',
  },
  {
    country: 'Tokelau',
    prefix: '+690',
    ISO: 'TK',
  },
  {
    country: 'Tonga',
    prefix: '+676',
    ISO: 'TO',
  },
  {
    country: 'Trinidad and Tobago',
    prefix: '+1868',
    ISO: 'TT',
  },
  {
    country: 'Tunisia',
    prefix: '+216',
    ISO: 'TN',
  },
  {
    country: 'Turkey',
    prefix: '+90',
    ISO: 'TR',
  },
  {
    country: 'Turkmenistan',
    prefix: '+993',
    ISO: 'TM',
  },
  {
    country: 'Turks and Caicos Islands',
    prefix: '+1649',
    ISO: 'TC',
  },
  {
    country: 'Tuvalu',
    prefix: '+688',
    ISO: 'TV',
  },
  {
    country: 'Uganda',
    prefix: '+256',
    ISO: 'UG',
  },
  {
    country: 'Ukraine',
    prefix: '+380',
    ISO: 'UA',
  },
  {
    country: 'United Arab Emirates',
    prefix: '+971',
    ISO: 'AE',
  },
  {
    country: 'United Kingdom',
    prefix: '+44',
    ISO: 'GB',
  },
  {
    country: 'United States',
    prefix: '+1',
    ISO: 'US',
  },
  {
    country: 'Uruguay',
    prefix: '+598',
    ISO: 'UY',
  },
  {
    country: 'Uzbekistan',
    prefix: '+998',
    ISO: 'UZ',
  },
  {
    country: 'Vanuatu',
    prefix: '+678',
    ISO: 'VU',
  },
  {
    country: 'Venezuela, Bolivarian Republic of Venezuela',
    prefix: '+58',
    ISO: 'VE',
  },
  {
    country: 'Vietnam',
    prefix: '+84',
    ISO: 'VN',
  },
  {
    country: 'Virgin Islands, British',
    prefix: '+1284',
    ISO: 'VG',
  },
  {
    country: 'Virgin Islands, U.S.',
    prefix: '+1340',
    ISO: 'VI',
  },
  {
    country: 'Wallis and Futuna',
    prefix: '+681',
    ISO: 'WF',
  },
  {
    country: 'Yemen',
    prefix: '+967',
    ISO: 'YE',
  },
  {
    country: 'Zambia',
    prefix: '+260',
    ISO: 'ZM',
  },
  {
    country: 'Zimbabwe',
    prefix: '+263',
    ISO: 'ZW',
  },
];
