import { ThemeProps } from '@lib/theme';
import styled from 'styled-components';

export default styled.div.attrs({
  'aria-hidden': true,
  className: 'loader',
})(
  ({ theme: { spacing } }) => `
  display: block;
  position: relative;
  width: 5em;
  height: 5em;
  margin: ${spacing(2)} auto;

  &:after {
    content: '';
    display: block;
    border-radius: 50%;
    width: 0;
    height: 0;
    margin: .5em;
    box-sizing: border-box;
    color: inherit;
    border: 2em solid currentColor;
    border-color: currentColor transparent currentColor transparent;
    animation: lds-hourglass 1.2s infinite;
  }

  @keyframes lds-hourglass {
    0% {
      transform: rotate(0);
      animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    }
    50% {
      transform: rotate(900deg);
      animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    }
    100% {
      transform: rotate(1800deg);
    }
  }

`,
);

export const CircleLoader = styled.div
  .attrs({
    'aria-hidden': true,
    className: 'loader',
  })
  .withConfig({
    shouldForwardProp: (p) => !['size'].includes(p),
  })<ThemeProps & { size?: number }>(
  ({ theme: { colors }, size = 21 }) => `
  height: ${size}px;
  width: ${size}px;
  border-radius: 50%;
  border: ${size / 7}px solid  ${colors.circleLoader.light}; 
  animation: rotate 2s ease 0s infinite normal forwards;

  &:after {
    content: '';
    position: absolute;
    border-radius: 50%;
    border: ${size / 7}px solid  ${colors.circleLoader.dark}; 
    opacity: 0.6;
    inset: -${size / 7}px;
    transform: rotate(45deg);
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 0, 100% 0, 100% 0);
  }

  @keyframes rotate {
    0% {
      transform: rotate(0);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`,
);
