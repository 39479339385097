import { AccountProps, OrderStatus, Subscription } from '../types';
import { MappedSubscriptions } from './hooks';
import { getDay } from './utils.dates';

export const isSubscriptionStatusActive = (
  subscriptionStatus: string,
): boolean =>
  subscriptionStatus === 'active' || subscriptionStatus === 'on-hold';

export const isSubscriptionActive = (subscription: Subscription): boolean =>
  isSubscriptionStatusActive(subscription.subscription_status);

export const isSubscriptionInActive = (subscription: Subscription): boolean =>
  !isSubscriptionActive(subscription);

export const isSubscriptionInTrial = (subscription: Subscription): boolean =>
  subscription?.subscription_status === 'trial';

export const hasActiveSubscriptions = (
  subscriptions: MappedSubscriptions,
): boolean =>
  Object.values(subscriptions || {}).filter(isSubscriptionActive).length > 0;

export const isSubscriptionOnHold = (subscription: Subscription): boolean =>
  subscription.subscription_status === 'on-hold';

export const subscriptionRequiresAction = ({
  status,
  needsPayment,
}: {
  status: Subscription['subscription_status'];
  needsPayment: Subscription['needs_payment'];
}): boolean => isSubscriptionStatusActive(status) && needsPayment;

export const orderIsCompleted = (orderStatus?: OrderStatus): boolean =>
  orderStatus === 'completed';

export const orderIsProcessing = (orderStatus?: OrderStatus): boolean =>
  orderStatus === 'processing';

export const isParentOrderCompleted = (
  history: AccountProps['history'],
  parentOrderId?: Subscription['parent_order_id'],
): boolean =>
  orderIsCompleted(
    Object.values(history || {}).find(
      ({ order_id }) => order_id === parentOrderId,
    )?.status,
  );

export const isVerifiedBreeder = (account: AccountProps): boolean =>
  account.breeder === 'verified_breeder';

export const hasCompletedRenewals = (
  account: AccountProps,
  subscriptions: MappedSubscriptions,
  target = 1,
): boolean => {
  const parentOrderIds = Object.values(subscriptions).reduce<number[]>(
    (acc, e) => (e.parent_order_id ? [...acc, e.parent_order_id] : acc),
    [],
  );

  const completedRenewals = Object.values(account.history || {}).filter(
    (e) => !parentOrderIds.includes(e.order_id) && orderIsCompleted(e.status),
  );

  return completedRenewals.length > target;
};

export const getRecentlyCompletedOrders = (
  history: AccountProps['history'],
  lastXDays = 5,
) =>
  Object.values(history || {}).filter(({ date_created, status }) => {
    const createdAt = getDay(date_created);
    const diff = createdAt.diff(getDay(), 'days', true);
    return orderIsCompleted(status) && diff > -1 * lastXDays;
  });

export const getRecentlyProcessedOrders = (
  history: AccountProps['history'],
  lastXDays = 5,
) =>
  Object.values(history || {}).filter(({ date_created, status }) => {
    const diff = getDay(date_created).diff(getDay(), 'days', true);
    const processingOrCompleted =
      orderIsProcessing(status) || orderIsCompleted(status);
    return processingOrCompleted && diff > -1 * lastXDays;
  });
