import { ReactNode } from 'react';
import styled from 'styled-components';

interface Props {
  className?: string;
  renderItems: (props: any) => ReactNode;
}

const MenuContainer = styled.ul(
  ({ theme: { breakpoints } }) => `
  display: flex;
  align-items: center;

  & > li {
    white-space: nowrap;  

    &:not(:first-of-type) {
     margin-left: 1rem;
    }
  }
  
  @media (max-width: ${breakpoints.md}) {
    display: none;
  }
  `,
);

const DesktopWrapper = ({ renderItems, ...rest }: Props) => (
  <MenuContainer role="region" aria-labelledby="main-menu-trigger" {...rest}>
    {renderItems({})}
  </MenuContainer>
);

export default DesktopWrapper;
