import { ShippingCountry, SiteLocale, WpLocale } from '../types';

const localeMappings: { [key in SiteLocale]: WpLocale } = {
  en: 'en_US',
  fi: 'fi',
  da: 'da_DK',
  sv: 'sv_SE',
};

export const mapLocaleFromSiteToWp = (locale?: SiteLocale): WpLocale =>
  localeMappings[locale || SiteLocale.FI];

export const mapWpLocaleToSiteLocale = (wpLocale?: WpLocale): SiteLocale | '' =>
  (Object.entries(localeMappings).find(([, wpLoc]) => wpLoc === wpLocale) || [
    '',
  ])[0] as SiteLocale | '';

export const languageNativeNameMap: Record<SiteLocale, string> = {
  da: 'Dansk',
  en: 'English',
  fi: 'Suomi',
  sv: 'Svenska',
};

const localeCountryMap: Record<SiteLocale, ShippingCountry | undefined> = {
  [SiteLocale.FI]: ShippingCountry.FI,
  [SiteLocale.EN]: ShippingCountry.FI,
  [SiteLocale.DA]: ShippingCountry.DK,
  [SiteLocale.SV]: ShippingCountry.SE,
};

export const mapLocaleToShippingCountry = (
  locale: SiteLocale,
): ShippingCountry => localeCountryMap[locale] || ShippingCountry.FI;

const countryLocaleMap: Record<ShippingCountry, SiteLocale> = {
  [ShippingCountry.FI]: SiteLocale.FI,
  [ShippingCountry.DK]: SiteLocale.DA,
  [ShippingCountry.SE]: SiteLocale.SV,
};

export const mapShippingCountryToLocale = (
  country: ShippingCountry,
): SiteLocale => countryLocaleMap[country] || SiteLocale.EN;
