import { OptionProps } from '../components/Form/types';
import {
  AccountProps,
  Breed,
  DietItem,
  DogForRecommendation,
  Gender,
  Recommendation,
  ShippingCountry,
  SiteLocale,
  TokenData,
  WeightEstimate,
} from '../types';

import { createStorageKey, getStorage } from './utils.persist';
import { camelCase } from './utils';
import {
  SHIPPING_DATE_FORMAT,
  getDay,
  getFirstPossibleStartDate,
} from './utils.dates';
import {
  WizardFormValues,
  CreateParentOrderValues,
} from '../components/forms/Recommendation/types';
import { isPuppy } from './utils.dog';
import { mapLocaleToShippingCountry } from './utils.locale';
import {
  getPaymentDayOptions,
  getShipmentTransportNameFromDeliveryType,
  getShippingMethodFromDeliveryType,
  shippingCountries,
} from './utils.delivery';
import dayjs from 'dayjs';
import phonePrefixes from './phone-prefixes';
import { CreateOrderProps } from '@lib/api/subscriptions';
import { getDeliveryInfo } from '@lib/utils.delivery';
import { isDietItem, isDryFood } from '@lib/utils.products';
import { isCoupon } from './utils.coupon';

/*
 * This is what:
 * Recommendation API uses mostly same props/keys than are in WP db, but some keys/props simply dont match. Some of these
 * can be generated with plain camelCasing, but not all. Also in case of some values like dates, the format is probably wrong. These utility functions help
 * resolving/transforming WP provided data to be used in recommendation API's queries.
 * Also the API throws if there are extra attributes, so only correct ones are allowed.
 */

type ResolveValue = string | number | string[];
type ResolvedValue = string | { [key: string]: string };

const stringOrArrayToArray = (s?: string | string[]): string[] =>
  Array.isArray(s) ? s : s ? s.split(',') : [];

// Resolve all types regardless of whether they need resolving. This way resolvable keys are all in one place.
const typeResolvers: { [key: string]: (value: any) => ResolvedValue } = {
  activityLevel: (value: string | number) => `${value}`,
  birthday: (value: string | number) =>
    getDay(value.toString()).format('YYYY-MM-DD'),
  bodyCondition: (value: string | number) => `${value}`,
  breed: (value: string | number) => `${value}`,
  dietShareDry: (value: string | number) => `${value}`,
  dietShareWet: (value: string | number) => `${value}`,
  dietShareTreats: (value: string | number) => `${value}`,
  dietShareOther: (value: string | number) => `${value}`,
  gender: (value: string) => value.toLowerCase(),
  preferredIngredients: (value?: string | string[]) => {
    const preferredIngredientsValue = stringOrArrayToArray(value);
    return `[${preferredIngredientsValue.map((ing) => `"${ing}"`).join(',')}]`;
  },
  shippingCountry: (value: null | ShippingCountry): ShippingCountry => {
    return (
      Object.values(ShippingCountry).find(
        (c) => c.toLowerCase() === value?.toLowerCase(),
      ) || ShippingCountry.FI
    );
  },
  specialNeeds: (value?: string | string[]) => {
    const specialNeedsValue = stringOrArrayToArray(value);
    return {
      specialNeeds: `[${specialNeedsValue.map((a) => `"${a}"`).join(',')}]`,
      neutered: specialNeedsValue.includes('neutered') ? '1' : '0',
    };
  },
  weight: (value: string | number) => `${value}`,
};
const supportedRecommendationApiParams = Object.keys(typeResolvers);

const resolveValue = (value: ResolveValue, type: string): ResolvedValue => {
  const resolver = typeResolvers[type as keyof typeof typeResolvers];
  // Following should always be true
  if (typeof resolver === 'function') {
    return resolver(value);
  }
  throw TypeError(
    `Resolver for type(${type}) not found in typeResolvers. Please define all type resolvers as function`,
  );
};

export const canGenerateRecommendation = (
  data: any,
): data is DogForRecommendation =>
  Object.values(Gender).includes(data?.gender?.toLowerCase()) &&
  typeof data?.birthday === 'string' &&
  [
    data?.activity_level,
    data?.body_condition,
    data?.weight,
    data?.dietShareDry,
    data?.dietShareWet,
    data?.dietShareTreats,
    data?.dietShareOther,
  ].every((i) => ['string', 'number'].includes(typeof i)) &&
  (data?.special_needs || []).every((i: any) => typeof i === 'string') &&
  (data?.preferred_ingredients || []).every(
    (i: any) => typeof i === 'string',
  ) &&
  Object.values(ShippingCountry).includes(data?.shipping_country);

/*
 * Take dogs available attributes and convert keys to camelcase because wp uses keys_like_this and recommendation API uses keysLikeThis, but they're mostly the same.
 * Filter out unsupported keys. Then resolve the value, which can be a single string or multiple keys, because some keys in WP can resolve to many in Rec API
 *
 * @return example breed=airedalenterrieri&gender=male&birthday=2016-07-11&activityLevel=2&weight=10&bodyCondition=4&specialNeeds=[%22grainFree%22]&neutered=0
 */
export const makeDogRecommendationQueryString = (
  dog: DogForRecommendation,
): string => {
  const initialState = [];
  if (!dog.body_condition) {
    initialState.push('bodyCondition=3');
  }
  if (!dog.special_needs) {
    initialState.push('specialNeeds=[]&neutered=0');
  }
  if (!dog.preferred_ingredients) {
    initialState.push('preferredIngredients=[]');
  }

  if (isPuppy(dog)) {
    initialState.push(`onlySuitable=1`);
  }

  return Object.entries(dog).reduce<string>((acc, [key, value]) => {
    const rKey = camelCase(key);
    if (supportedRecommendationApiParams.includes(rKey)) {
      const resolvedValue = resolveValue(value, rKey);
      if (typeof resolvedValue === 'object' && resolvedValue) {
        return Object.entries(resolvedValue).reduce(
          (a, [b, c]) => `${a}${a && '&'}${b}=${`${c}`}`,
          acc,
        );
      }
      return `${acc}${acc && '&'}${rKey}=${`${resolvedValue}`}`;
    }
    return acc;
  }, initialState.join('&')); // TODO: remove bodyCondition from here once it comes from data
};

const bodyWeightIndex = {
  3: 'minWeight',
  5: 'avgWeight',
  7: 'maxWeight',
};

interface SufficientDog {
  breed: string;
  gender: Gender;
  body_condition: number;
}

export const calculateDogWeight = (
  dog: SufficientDog,
  breeds?: Breed[],
): null | number => {
  if (!breeds) {
    return null;
  }

  const breed = breeds.find(({ name, id, names }) =>
    [
      name,
      id,
      ...Object.values(names || {}).reduce<string[]>(
        (a, n) => (n ? [...a, n.toLowerCase()] : a),
        [],
      ),
    ].includes(dog.breed),
  );

  if (!breed) {
    return null;
  }

  const weightEstimate = breed[
    dog.gender.toLowerCase() as keyof Breed
  ] as WeightEstimate;

  if (!weightEstimate) {
    return null;
  }
  const bodyIndexKey = bodyWeightIndex[
    dog.body_condition as keyof typeof bodyWeightIndex
  ] as keyof WeightEstimate;

  return weightEstimate[bodyIndexKey];
};

/*
 * Recommendation flow persists data to storage. Form data is persisted, but also other things like results based on form entries are saved.
 * Use this to save those results, such as recommended product, generated user_id etc.
 */
export const saveOnRecommendationState = (
  payload: Partial<WizardFormValues>,
) => {
  const state = JSON.parse(
    getStorage().getItem(createStorageKey('recommendation')) || '{}',
  );
  getStorage().setItem(
    createStorageKey('recommendation'),
    JSON.stringify({
      ...state,
      ...payload,
    }),
  );
};

export const getRecommendationState = (): WizardFormValues & TokenData =>
  JSON.parse(getStorage().getItem(createStorageKey('recommendation')) || '{}');

export const getStartingDayOptions = (locale: SiteLocale): OptionProps =>
  getPaymentDayOptions(false, locale).filter(
    ({ value }) => dayjs(value).diff(dayjs(), 'days') > 5,
  );

const splitPhone = (
  phone: AccountProps['phone'],
): { countryCode?: string; phone?: string } => {
  const prefix =
    !!phone &&
    phonePrefixes.find(({ prefix }) => phone.startsWith(prefix))?.prefix;

  if (!prefix) {
    return {};
  }

  return {
    countryCode: prefix,
    phone: phone.slice(prefix.length),
  };
};

const staticInitialValues: Pick<
  WizardFormValues,
  | 'dog_name'
  | 'gender'
  | 'starting_date'
  | 'weight'
  | 'body_condition'
  | 'activity_level'
  | 'dietShareDry'
  | 'dietShareWet'
  | 'dietShareTreats'
  | 'dietShareOther'
  | 'feedsDryFood'
  | 'coupons'
  | 'opt_in'
  | 'day'
  | 'month'
  | 'year'
  | 'account_action'
  | 'order_subscription'
  | 'cart'
  | 'interval_weeks'
  | 'shipping_method'
> = {
  weight: '',
  body_condition: '',
  activity_level: '',
  dog_name: '',
  gender: Gender.Male,
  feedsDryFood: true,
  dietShareDry: 100,
  dietShareWet: 0,
  dietShareTreats: 0,
  dietShareOther: 0,
  coupons: [],
  opt_in: false,
  day: '',
  month: '',
  year: '',
  account_action: 'register',
  order_subscription: 1,
  cart: [],
  interval_weeks: '4',
  shipping_method: 'post',
};

export const getRecommendationInitialValues = ({
  locale,
  account,
  ...otherFormValues
}: Record<string, any> & {
  locale: SiteLocale;
  account?: AccountProps;
}): typeof staticInitialValues &
  Pick<WizardFormValues, 'shipping_country'> &
  Partial<WizardFormValues> => {
  const initialValues = {
    ...staticInitialValues,
    shipping_country:
      shippingCountries.find((c) => c === account?.shipping?.country) ||
      getRecommendationState().shipping_country ||
      mapLocaleToShippingCountry(locale),
  };

  // Add or overwrite default values if these keys have values
  // This can get called multiple times during recommendation flow, so add otherFormValues after to avoid overwriting user changes
  if (account && account.shipping) {
    Object.entries({
      first_name: account?.shipping?.first_name,
      last_name: account?.shipping?.last_name,
      countryCode: splitPhone(account?.phone).countryCode,
      phone: splitPhone(account?.phone).phone,
      delivery_drop_point:
        otherFormValues.delivery_drop_point ||
        account?.shipping?.delivery_drop_point_id ||
        '',
      shipping_address_1: account?.shipping?.address_1,
      shipping_city: account?.shipping?.city,
      shipping_postcode: account?.shipping?.postcode,
      shipment_transport_name:
        otherFormValues.shipment_transport_name ||
        getShipmentTransportNameFromDeliveryType(
          account.shipping.delivery_type,
        ) ||
        null,
      shipping_method:
        otherFormValues.shipping_method ||
        account?.shipping?.delivery_method ||
        getShippingMethodFromDeliveryType(account?.shipping?.delivery_type) ||
        'post',
    }).forEach(([key, value]) => {
      if (!!value && !otherFormValues[key]) {
        (initialValues as Record<string, any>)[key] = value;
      }
    });
  }

  return {
    ...initialValues,
    ...otherFormValues,
  };
};

export const getSortedDryFoodsFromRecommendation = ({
  recommendedDryFood,
  alternativeDryFoods = [],
  uncategorizedDryFoods = [],
  unsuitableDryFoods = [],
  onlySuitable = false,
}: Recommendation): DietItem[] => [
  recommendedDryFood,
  ...alternativeDryFoods,
  ...uncategorizedDryFoods,
  ...(onlySuitable ? [] : unsuitableDryFoods),
];

export const getRecommendedSecondaries = (recommendation?: Recommendation) =>
  [
    recommendation?.recommendedWetFood,
    recommendation?.recommendedTreat,
    recommendation?.recommendedSupplement,
  ].reduce<DietItem[]>((acc, p) => (p ? [...acc, p] : acc), []);

export const getAllSecondariesFromRecommendation = (
  recommendation?: Recommendation,
) =>
  [
    recommendation?.recommendedWetFood,
    recommendation?.recommendedTreat,
    recommendation?.recommendedSupplement,
    ...(recommendation?.alternativeWetFoods || []),
    ...(recommendation?.alternativeTreats || []),
    ...(recommendation?.alternativeSupplements || []),
  ].reduce<DietItem[]>((acc, p) => (p ? [...acc, p] : acc), []);

export const resolveCreateOrderPayload = async (
  values: CreateParentOrderValues,
): Promise<CreateOrderProps['payload'] | string> => {
  const deliveryInfo = await getDeliveryInfo(values);
  const { coupons = [], cart = [], shipping_country } = values;

  if (!deliveryInfo) {
    reportError(
      'failed to get delivery info ' +
        JSON.stringify({
          address1: values.shipping_address_1,
          country: values.shipping_country,
          city: values.shipping_city,
          postcode: values.shipping_postcode,
          transportName: values.shipment_transport_name,
          method: values.shipping_method,
          dropPoint: values.delivery_drop_point,
          phone: values.phone,
        }),
    );
    return 'couldnt_validate_delivery_info';
  }

  const mainKibble = cart.find((i) => isDietItem(i) && isDryFood(i.type));

  // main kibble can be removed from cart
  // in that case, use daily grams from recommended dry food, adjust to be 100% of diet
  const fallBackGrams =
    values.recommendation &&
    values.recommendation?.recommendedDryFood.dailyGrams /
      values.recommendation?.recommendedDryFood.shareOfDiet;

  const gram_need =
    (mainKibble as DietItem)?.dailyGrams || fallBackGrams || undefined;

  const subscription = {
    dog_name: values.dog_name,
    dog_id: values.dog_id,
    gram_need,
    interval_weeks: values.interval_weeks,
    next_payment_date: values.starting_date,
    items: cart.map(({ wordpressId, quantity }) => ({
      product_id: wordpressId,
      quantity,
      recipe_item: wordpressId === (mainKibble?.wordpressId || -1),
    })),
  };

  const demo_coupon_codes = [values.parent_order_coupon, ...coupons].reduce<
    string[]
  >((acc, i) => (isCoupon(i) ? [...acc, i.code] : acc), []);

  return {
    ...deliveryInfo,
    aw_user_is_referral: values.aw_user_is_referral,
    shipping_method: values.shipping_method,
    order_subscription: values.order_subscription,
    starting_date: values.starting_date,
    interval_weeks: values.interval_weeks,
    first_name: values.first_name,
    last_name: values.last_name,
    shipping_address_1: values.shipping_address_1,
    shipping_postcode: values.shipping_postcode,
    shipping_city: values.shipping_city,
    shipment_transport_name: values.shipment_transport_name,
    delivery_drop_point: values.delivery_drop_point,
    dog_id: values.dog_id,
    locale: values.locale,
    coupons: coupons.filter((i) => !i.parentOrderCoupon),
    dog_name: values.dog_name,
    breeder: values.breeder,
    kennel_name: values.kennel_name,
    shipping_country,
    subscriptions: [subscription],
    demo_coupon_codes,
  };
};
