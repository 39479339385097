import { ThemeProps } from '@lib/theme';
import { ReactNode } from 'react';
import styled from 'styled-components';

import Link from '../Link';

export interface Props {
  children: ReactNode;
  href?: string;
  onClick?: () => void;
}

const NavLink = styled(Link)(
  ({ theme }: ThemeProps) => `
  ${theme.fontPresets.heading3};
  color: ${theme.colors.navigation.color};
  
  @media (min-width: ${theme.breakpoints.md}) {    
    ${theme.fontPresets.paragraph};
  }

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      transition: ${theme.transitionTime.snappy} ease-in;
      text-decoration: underline;
    }
  }
`,
);

const NavItem = ({ href, ...props }: Props) =>
  href ? (
    <NavLink {...props} href={href} />
  ) : (
    <NavLink as="button" {...props} />
  );

export default NavItem;
