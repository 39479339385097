import dynamic from 'next/dynamic';
import { createContext, useCallback, useState, ReactNode } from 'react';
import { ModalOptions } from '../types/components';

const Modal = dynamic(() => import('../components/Modal'));

interface ModalProviderProps {
  children: React.ReactNode;
}

export interface ModalState {
  content?: ReactNode;
  isOpen: boolean;
  options?: ModalOptions;
  modalId?: string;
}

const initialState = {
  content: undefined,
  isOpen: false,
  options: undefined,
  modalId: undefined,
};

export const ModalContext = createContext({});

const ModalProvider = ({ children }: ModalProviderProps) => {
  const [{ content, isOpen, options, modalId }, setModal] =
    useState<ModalState>(initialState);

  const resetModal = useCallback(() => {
    setModal(initialState);
  }, [setModal]);

  const openModal = useCallback(
    (modalContent: ReactNode, modalOptions?: ModalOptions) => {
      setModal({
        isOpen: true,
        content: modalContent,
        options: modalOptions,
        modalId: modalOptions?.modalId,
      });
    },
    [setModal],
  );

  const closeModal = useCallback(() => {
    setModal((s) => ({
      ...s,
      isOpen: false,
    }));
  }, [setModal]);

  return (
    <ModalContext.Provider
      value={{
        resetModal,
        setModal,
        openModal,
        closeModal,
        isOpen,
        modalId,
      }}
    >
      {children}
      <Modal
        isOpen={isOpen}
        onRequestClose={options?.onRequestClose || closeModal}
        displayXClose={
          options?.displayXClose || options?.displayXClose === undefined
        }
        from={options?.from}
        options={options}
      >
        {content}
      </Modal>
    </ModalContext.Provider>
  );
};

export { ModalProvider };
