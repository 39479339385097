import { voidFn } from './utils';

const dummyStorage: Storage = {
  getItem: () => null,
  setItem: voidFn,
  removeItem: voidFn,
  clear: voidFn,
  key: () => null,
  length: 0,
};

const BASE_KEY = 'alvar';
const PERSIST_TOKEN_KEY = 'token';
const PERSIST_STICKY_REMEMBER_ME_KEY = 'alvar_remember_me';

export const createStorageKey = (key: string) => `${BASE_KEY}.${key}`;

export const getStorageKey = (key: string) =>
  key.split('.')[0] === BASE_KEY ? key : createStorageKey(key);

export const getStorage = () =>
  typeof sessionStorage !== 'undefined' ? sessionStorage : dummyStorage;

export const clearSessionStorage = (rule?: (key: string) => boolean): void => {
  const storage = getStorage();
  Object.keys(storage).forEach((key) => {
    if (key.split('.')[0] === BASE_KEY && (!rule || rule(key))) {
      storage.removeItem(key);
    }
  });
};

export const getPersistedUserToken = (): string => {
  if (typeof localStorage === 'undefined') {
    return '';
  }
  return localStorage.getItem(getStorageKey(PERSIST_TOKEN_KEY)) || '';
};

export const clearRememberMe = (): void => {
  if (typeof localStorage !== 'undefined') {
    localStorage.removeItem(PERSIST_STICKY_REMEMBER_ME_KEY);
  }
};

export const isRememberMeSet = (): boolean => {
  if (typeof localStorage !== 'undefined') {
    return localStorage.getItem(PERSIST_STICKY_REMEMBER_ME_KEY) === 'true';
  }
  return false;
};

export const setPersistedUserToken = (token: string): void => {
  if (typeof localStorage !== 'undefined') {
    localStorage.setItem(getStorageKey(PERSIST_TOKEN_KEY), token);
    // Set remember me as well
    localStorage.setItem(PERSIST_STICKY_REMEMBER_ME_KEY, 'true');
  }
};

export const clearPersistedUser = (): void => {
  if (typeof localStorage !== 'undefined') {
    localStorage.removeItem(getStorageKey(PERSIST_TOKEN_KEY));
  }
};
