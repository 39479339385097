import NextImage, { ImageProps } from 'next/legacy/image';
import { ReactNode } from 'react';
import styled from 'styled-components';
import { IngredientHighlight } from '@types';
import FlexWrapper from './wrappers/FlexWrapper';
import { ThemeProps } from '@lib/theme';

const blobMask = `
  mask-image: url('/icons/blob-2.svg');
  mask-size: 96%;
  mask-repeat: no-repeat;
  mask-position: center;
`;

const placeHolderStyling = (
  width: string | number,
  height: string | number,
) => `
  width: ${typeof width === 'string' ? width : `${width}px`};
  height: ${typeof height === 'string' ? height : `${height}px`};
  position: relative;
  background: #f1f1f1;
  background: #0000001a;
  border-radius: 4px;
  overflow: hidden;

  &:before {
    content: '';
    width: ${typeof width === 'string' ? width : `${width}px`};
    height: ${typeof height === 'string' ? height : `${height}px`};
    position: absolute;
    right: 120%;
    background: linear-gradient(
      110deg,
      rgba(255, 255, 255, 0) 35%,
      rgba(255, 255, 255, 0.3) 50%,
      rgba(255, 255, 255, 0) 65%
    );
    animation: 1.8s loading ease-in-out infinite forwards;
  }

  @keyframes loading {
    to {
      right: -120%;
    }
  }
  }
`;

interface ContainerProps {
  minWidth?: number;
  mask?: boolean;
  height?: number | string;
  width?: number | string;
}

// next image uses inline-block, which takes line height and then default v-align messes up stuff. This causes images to have 6px more height with 1.4 line-height. Following styles negate this.
const ImageContainer = styled.div.withConfig<ContainerProps>({
  shouldForwardProp: (p) =>
    !['minWidth', 'mask', 'height', 'width'].includes(p),
})(
  ({
    theme,
    minWidth,
    mask = false,
    width = '60px',
    height = '75px',
    ...rest
  }: ThemeProps & ContainerProps) => `
  line-height: 1;

  > div {
    vertical-align: middle;
  }

  img:not([aria-hidden='true']) {
    object-fit: cover;
  }

  // jpgs are quite harsh as small images, but pngs work as such so
  &:not([data-hero='true']) img:not([aria-hidden='true'])[src*='.jpg'] {
    border-radius: 4px;
  }

  ${minWidth ? `min-width: ${minWidth}px` : ''};
  ${mask ? blobMask : ''};

  ${
    !!(rest as any).children?.[0]?.props?.onClick
      ? `&:hover{ ${theme.stylingPresets.pointerCursor}; }`
      : ''
  }

  &[data-loading="true"] {
    ${placeHolderStyling(width, height)}
  }
`,
);

type AlvarImageProps = ImageProps & {
  isHero?: boolean;
  minWidth?: number;
  mask?: boolean;
  alvarLoading?: boolean;
  children?: ReactNode;
};

const Image = ({
  className,
  isHero,
  children,
  minWidth,
  mask = false,
  alvarLoading = false,
  style, // do not forward as long as legacy image is used. This clashes with width, height, and layout.
  ...props
}: AlvarImageProps) => (
  <ImageContainer
    className={[className, 'image'].filter(Boolean).join(' ')}
    data-hero={isHero}
    minWidth={minWidth}
    mask={mask}
    data-loading={alvarLoading}
    height={props.height}
    width={props.width}
  >
    {alvarLoading ? null : <NextImage {...props} quality="80" />}
    {children}
  </ImageContainer>
);

export default Image;

type IngredientImageProps = AlvarImageProps & {
  ingredients: IngredientHighlight[];
  ingredientSize: number;
  ingredientSpacing?: number;
};

export const ImageWithIngredients = ({
  ingredients,
  ingredientSize,
  ingredientSpacing = 0.5,
  ...rest
}: IngredientImageProps) => (
  <FlexWrapper className="ingredient-image" width="fit-content" margin="0 auto">
    <FlexWrapper
      direction="column"
      width={`${ingredientSize}px`}
      gap={`${ingredientSpacing}rem`}
      margin={`auto -${ingredientSize}px auto 0`}
    >
      {ingredients.map(({ image }, i) => (
        <Image
          key={`ingredient-${i}`}
          {...image}
          width={ingredientSize}
          height={ingredientSize}
        />
      ))}
    </FlexWrapper>
    <Image {...rest} />
  </FlexWrapper>
);
