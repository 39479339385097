import da from '@lib/static-translations/da';
import en from '@lib/static-translations/en';
import fi from '@lib/static-translations/fi';
import sv from '@lib/static-translations/sv';
import links from 'constants/links';
import { SiteLocale } from '@types';

export const LOCALES = Object.values(SiteLocale);
export const EXTERNAL_LINKS = links;

export const STATIC_TRANSLATIONS: Record<
  SiteLocale,
  typeof da | typeof en | typeof fi | typeof sv
> = {
  [SiteLocale.DA]: da,
  [SiteLocale.EN]: en,
  [SiteLocale.FI]: fi,
  [SiteLocale.SV]: sv,
};
