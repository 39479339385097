import { ThemeProps as StyledThemeProps } from 'styled-components';

const palette = {
  pitchBlack: '#000',
  black: '#333',
  coal: '#424242',
  greyDark: '#666',
  grey: '#AAA',
  greyLight: '#b7b7b7',
  white: '#FFF',
  faint: '#ece4d4',
  barley50percent: '#FBF7ED',
  lightSalmon: '#FCDCC3',
  chickenSkin: '#FFCAAC',
  nettle: '#1E5139',
  grass: '#08A16A',
  sprout: '#D9E9BD',
  dirtySprout: '#B4BEA0',
  sprout50percent: '#EAEED6',
  pink: '#FFB7AB',
  salmon: '#E29F85',
  kindofred: '#e9583e',
  danger: '#c52400',
  black10percent: '#0000001a',
  barley: '#F9EEDA',
  dirtyBarley: '#D7C7A8',
  oat: '#CA953A',
};

const breakpoints = {
  sm: 600,
  md: 920,
  lg: 1160,
};

const safeFloat = (n: number): number => parseFloat(n.toFixed(3));

const hideScrollbar =
  '-ms-overflow-style:none;scrollbar-width:none;&::-webkit-scrollbar{display:none;}';
const pointerCursor = 'cursor: pointer;cursor: hand;';

const baseTheme = {
  colors: {
    white: palette.white,
    faded: palette.greyLight,
    primaryColor: palette.black,
    primaryColorInverted: palette.barley50percent,
    backgroundColor: palette.barley50percent,
    backgroundColorInverted: palette.black,
    secondaryBackground: palette.barley50percent,
    borderColor: palette.black,
    borderColorLight: palette.grey,
    borderColorLightest: palette.faint,
    borderColorReadOnly: palette.greyLight,
    disabledColor: palette.greyDark,
    highlightColor: palette.nettle,
    highlightColorInverted: palette.barley50percent,
    highlightColorAlt: palette.chickenSkin,
    highlightBackground: palette.lightSalmon,
    mellowHighlightColor: palette.sprout,
    mellowInactiveColor: palette.dirtySprout,
    tableBackground: palette.faint,
    highlightBackgroundInverted: palette.black,
    loaderColor: palette.black,
    placeholderColor: palette.grey,
    textColorThin: palette.coal,
    lightOverlay: palette.black10percent,
    lowerFooterBackground: palette.pitchBlack,
    inputs: {
      neutralBackground: palette.barley,
    },
    status: {
      done: palette.grass,
      positive: palette.sprout,
      warning: palette.kindofred,
      nonBlockingWarning: palette.oat,
      alarm: palette.salmon,
      error: palette.kindofred,
      danger: palette.danger,
      neutral: palette.dirtyBarley,
    },
    link: {
      color: palette.grass,
      hover: palette.grass,
      visited: palette.grass,
    },
    navigation: {
      color: palette.barley,
    },
    languageSelector: {
      hover: palette.greyDark,
    },
    editLink: {
      color: palette.nettle,
      hover: palette.nettle,
      visited: palette.nettle,
    },
    button: {
      primary: palette.nettle,
      primaryText: palette.barley50percent,
      primaryHover: palette.grass,
      secondary: 'none',
      secondaryText: palette.black,
      secondaryHover: palette.nettle,
      secondaryHoverText: palette.barley50percent,
      alt: palette.black,
      altText: palette.barley50percent,
      altHover: palette.grass,
      none: 'none',
      noneText: palette.black,
      danger: palette.danger,
      dangerText: palette.white,
      disabled: palette.greyDark,
      disabledText: palette.white,
      secondaryIconBackground: palette.sprout50percent,
      secondaryIcon: palette.nettle,
      disabledIconBackground: palette.dirtySprout,
      white: palette.white,
      whiteText: palette.black,
      ctaHighlight: '#cc5804',
      ctaHighlightHover: '#fa8938',
    },
    campaignTags: palette.grass,
    card: {
      brown: palette.barley,
      lightBrown: palette.barley50percent,
      green: palette.sprout,
      darkGreen: palette.dirtySprout,
      lightGreen: palette.sprout50percent,
      red: palette.lightSalmon,
      blackOverlay: palette.black10percent,
      footer: {
        lightGreen: palette.sprout,
        brown: palette.dirtyBarley,
        lightBrown: palette.barley,
        green: palette.dirtySprout,
        red: palette.salmon,
      },
    },
    productAssociations: {
      dryFood: { background: palette.lightSalmon, text: palette.black },
      wetFood: { background: palette.salmon, text: palette.black },
      treats: { background: palette.dirtySprout, text: palette.black },
      supplements: { background: palette.oat, text: palette.black },
      chew: { background: palette.sprout },
      accessory: { background: palette.dirtyBarley },
    },
    recommendationLevel: {
      recommended: { background: palette.sprout, text: palette.nettle },
      good: { background: palette.sprout50percent, text: palette.black },
      notSuitable: { background: palette.lightSalmon, text: palette.black },
    },
    circleLoader: {
      dark: palette.nettle,
      light: palette.sprout50percent,
    },
  },
  maxWidth: '925px',
  spacing: (multiplier = 1): string => `${safeFloat(1 * multiplier)}rem`,
  breakpoints: {
    smMax: `${breakpoints.sm - 1}px`,
    md: `${breakpoints.md}px`,
    mdMax: `${breakpoints.md - 1}px`,
    lg: `${breakpoints.lg}px`,
  },
  transitionTime: {
    veryFast: '100ms',
    snappy: '250ms',
    normal: '350ms',
    slow: '400ms',
    verySlow: '500ms',
  },
  fontSizes: {
    small: '14px',
    paragraph: '16px',
  },
  fontPresets: {
    headingXl: "font: 400 4rem/1.2 'ivyjournal'",
    heading1: "font: 400 2.5rem/1.2 'ivyjournal'",
    heading2: "font: 400 2rem/1.2 'ivyjournal'",
    heading3: "font: 400 1.5rem/1.2 'ivyjournal'",
    heading4: "font: 600 1rem/1.4 'Montserrat', sans-serif",
    paragraph: "font: 400 1rem/1.4 'Montserrat', sans-serif",
    paragraphEmphasis: "font: 600 1rem/1.4 'Montserrat', sans-serif",
    link: "text-decoration: underline; font: 500 1rem/1.4 'Montserrat', sans-serif",
    linkOnDark:
      "text-decoration: underline; font: 600 1rem/1.4 'Montserrat', sans-serif",
    small: "font: 400 0.75rem/1.4 'Montserrat', sans-serif",
  },
  zIndex: {
    cookieNotice: 100,
    modal: 99,
    navBar: 25,
    stickyNotification: 20,
    checkoutCta: 10,
    formOverlay: 5,
    default: 1,
    below: -1,
  },
  stylingPresets: {
    hideScrollbar,
    pointerCursor,
  },
  constants: {
    navbarHeight: '56px',
    footerWavesHeight: '35px',
  },
};

export default baseTheme;

export type ThemeProps = StyledThemeProps<typeof baseTheme>;
