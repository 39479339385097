import { ErrorResponse, ShippingCountry } from '../types';
import postalCodes from './postal-codes';

/**
 * is_email function from WordPress
 *    written in Javascript
 *
 * by Louy Alakkad <me@l0uy.com>
 *
 * Verifies that an email is valid.
 *
 * Does not grok i18n domains. Not RFC compliant.
 *
 * @param string email Email address to verify.
 * @return string|bool Either false or the valid email address.
 */

function str_trim(str: string, regex: string): string {
  const chr = regex.replace(/[-[\]/{}()*+?.\\^$|:!,=]/g, '\\$&');
  return str
    .replace(new RegExp(`/^[${chr}]*/`), '')
    .replace(new RegExp(`/[${chr}]*$/`), '');
}

export const isEmail = (email: string): boolean | string => {
  // Test for the minimum length the email can be
  if (email.length < 3) {
    return false;
  }

  // Test for a single @ character after the first position
  if (
    email.indexOf('@') === -1 ||
    email.indexOf('@') !== email.lastIndexOf('@')
  ) {
    return false;
  }

  // Split out the local and domain parts
  const parts = email.split('@', 2);
  const local = parts[0];
  const domain = parts[1];

  // LOCAL PART
  // Test for invalid characters
  if (!/^[a-zA-Z0-9!#$%&'*+/=?^_`{|}~.-]+$/.test(local)) {
    return false;
  }

  // DOMAIN PART
  // Test for sequences of periods
  if (/\.{2,}/.test(domain)) {
    return false;
  }

  // Test for leading and trailing periods and whitespace
  if (str_trim(domain, ' \t\n\r\0\x0B.') !== domain) {
    return false;
  }

  // Split the domain into subs
  const subs = domain.split('.');

  // Assume the domain will have at least two subs
  if (subs.length < 2) {
    return false;
  }

  // Loop through each sub
  subs.forEach((sub) => {
    // Test for leading and trailing hyphens and whitespace
    if (str_trim(sub, ' \t\n\r\0\x0B-') !== sub) {
      return false;
    }

    // Test for invalid characters
    if (!/^[a-z0-9-]+$/i.test(sub)) {
      return false;
    }
  });

  // Congratulations your email made it!
  return !!email;
};

/*
 * Validates postcode. Regexps stolen from here: https://gist.github.com/jamesbar2/1c677c22df8f21e869cca7e439fc3f5b
 */
export const isPostcode = (
  maybePostcode?: string,
  countryCode?: ShippingCountry,
) => {
  if (!countryCode || !maybePostcode) {
    return false;
  }

  const postalCodesByCountry = postalCodes.filter(
    (postalCode) => postalCode.ISO === countryCode.toUpperCase(),
  );

  const isValid = postalCodesByCountry.some((postalCode) => {
    const validationRegexp = new RegExp(postalCode.Regex, 'g');
    return validationRegexp.test(maybePostcode);
  });
  return isValid;
};

export const isErrorResponse = (param: unknown): param is ErrorResponse =>
  !!param &&
  typeof param === 'object' &&
  !Array.isArray(param) &&
  !!(param as ErrorResponse).error &&
  typeof (param as ErrorResponse).error === 'string';

export default { isEmail, isPostcode };
