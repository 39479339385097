import flatten from 'flat';
import { SiteLocale } from '../types';

/*
Dynamic translations can be handled by translations.alvar.pet
*/

import Fi from './static-translations/fi';
import En from './static-translations/en';
import Da from './static-translations/da';
import Sv from './static-translations/sv';
import { reportError } from './bugsnag';

export interface TranslationType {
  [key: string]: { [lang: string]: string };
}

type StaticTranslationObject = typeof Fi | typeof En | typeof Da | typeof Sv;

const buildStaticTranslationKeys = (JSONs: {
  [lang: string]: StaticTranslationObject;
}): TranslationType => {
  const output: any = {};

  Object.entries(JSONs).forEach(([lang, translations]) => {
    const flatObj: StaticTranslationObject = flatten(translations);

    const tLang = lang.toLowerCase();
    Object.entries(flatObj).forEach(([key, translation]) => {
      if (translation) {
        if (!output[key]) {
          output[key] = { [tLang]: translation };
        } else {
          output[key] = {
            ...output[key],
            [tLang]: translation,
          };
        }
      }
    });
  });
  return output;
};

const staticTranslations: TranslationType = buildStaticTranslationKeys({
  Fi,
  En,
  Da,
  Sv,
});

interface InterpolateArgs {
  [key: string]: string | number;
}

const interpolate = (s: string, args?: InterpolateArgs) =>
  !args
    ? s
    : Object.entries(args).reduce(
        (acc, [key, value]) =>
          `${acc.replace(new RegExp(`{{s*${key}s*}}`, 'g'), `${value}`)}`,
        s,
      );

export const getTranslation = ({
  locale,
  defaultLocale,
  key,
  translations,
  args,
  options,
}: {
  locale: SiteLocale;
  defaultLocale?: SiteLocale;
  key: string;
  translations: TranslationType;
  args?: InterpolateArgs;
  options?: { fallback?: string | null; optional?: true };
}): string => {
  if (!locale && !defaultLocale) {
    return key;
  }

  // NOTE: once in production, we probably want the translation to fallback to defaultLocale version if none is found. Should probably be already implemented as env URL based.
  const translation = translations?.[key]?.[locale];
  if (translation) {
    return interpolate(translation, args);
  }
  if (options?.optional) {
    return '';
  }
  if (options?.fallback) {
    const fallbackTranslation = translations?.[options.fallback]?.[locale];
    return fallbackTranslation ? interpolate(fallbackTranslation, args) : key;
  }

  if (
    Object.values(translations).length >
      Object.values(staticTranslations).length &&
    !translation
  ) {
    if (process.env.NODE_ENV === 'production') {
      reportError(
        new Error(`missing translation for key: ${key} in locale ${locale}`),
      );
    }
  }
  return key;
};

export const getTranslations = ({
  fetchedTranslations,
  displayKey,
}: {
  fetchedTranslations: TranslationType | {};
  displayKey: boolean;
}): TranslationType => {
  const dynamicTranslations = Object.entries(fetchedTranslations).reduce(
    (acc, [key, translation]) => {
      if (Object.values(translation).length) {
        const parsedKey = key.split('__').join('.');
        return {
          ...acc,
          [parsedKey]: displayKey
            ? Object.fromEntries(
                Object.values(SiteLocale).map((k) => [k, parsedKey]),
              )
            : translation,
        };
      }
      return acc;
    },
    {} as TranslationType,
  );

  return {
    ...staticTranslations,
    ...dynamicTranslations,
  };
};

export const getStaticTranslation = (
  locale: SiteLocale,
  key: string,
  args: Record<string, string | number>,
) =>
  getTranslation({
    locale,
    defaultLocale: SiteLocale.EN,
    key,
    translations: staticTranslations,
    args,
  });

export default { getTranslations };
