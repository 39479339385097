import dayjs from 'dayjs';
import { SingleOptionProps } from '../components/Form/types';
import { WizardFormValues } from '../components/forms/Recommendation/types';
import {
  DietItem,
  Dog,
  DogForRecommendation,
  Gender,
  WPDogPost,
} from '../types';
import { getDay } from './utils.dates';
import { slugify } from './utils';
import { convertDogToLegacy } from './utils.dataConversion';
import { isDietItem, isDryFood } from './utils.products';

export const getGenderOptions = (): {
  value: string;
  label: string;
}[] =>
  Object.values(Gender).map((value) => ({
    value,
    label: `dog.gender.${value}`,
  }));

export const getActivityLevelOptions = (
  hourUnit: string,
): Array<SingleOptionProps & { value: string }> =>
  [['1'], ['2', '1-3'], ['3']].map(([value, text]) => ({
    value,
    node: `${text || value} ${hourUnit}`,
    label: `${text || value} ${hourUnit}`,
  }));

export const getBodyTypeOptions = (): Array<
  SingleOptionProps & { label: string }
> =>
  [3, 5, 7].map((value) => ({ value, label: `dog.body_condition.${value}` }));

export const parseWeight = (value: number | string): string =>
  (value &&
    `${
      parseFloat(
        (parseFloat(value.toString().replace(/,/, '.')) * 10).toFixed(),
      ) / 10
    }`) ||
  '';

export const isPuppy = ({ birthday }: { birthday: string }): boolean =>
  dayjs().diff(getDay(birthday), 'year') < 1;

export const isSenior = ({
  birthday,
}: Dog | DogForRecommendation | WizardFormValues): boolean =>
  dayjs().diff(getDay(birthday), 'year') >= 8;

export const getDogIdByName = (dogs: WPDogPost[], nameToFind: string) =>
  dogs.find(({ name }) => slugify(name) === slugify(nameToFind))?.dog_id;

export const createWpDog = ({
  dog_id,
  dog_name,
  birthday,
  breed,
  body_condition,
  gender,
  special_needs,
  activity_level,
  weight,
  preferred_ingredients,
  dietShareDry,
  dietShareWet,
  dietShareTreats,
  dietShareOther,
  recommendation,
  cart,
}: WizardFormValues): WPDogPost => {
  const mainProduct = cart.find(
    (i) => isDryFood(i) && isDietItem(i),
  ) as DietItem;

  return convertDogToLegacy({
    dog_id,
    dog_name,
    birthday,
    breed,
    body_condition,
    gender,
    special_needs,
    activity_level,
    weight,
    preferred_ingredients,
    dietShareDry,
    dietShareWet,
    dietShareTreats,
    dietShareOther,
    product: mainProduct,
    gram_need:
      mainProduct?.dailyGrams ||
      (recommendation?.recommendedDryFood?.dailyGrams || 0) /
        (recommendation?.recommendedDryFood.shareOfDiet || 1),
    initial_recommendation: recommendation?.recommendedDryFood?.shortTitle,
    initial_recommendation_id: recommendation?.recommendedDryFood?.wordpressId,
  });
};
