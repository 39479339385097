export enum DisabledLegacyShippingCountry {
  DE = 'DE',
  NO = 'NO',
}

export enum ShippingCountry {
  FI = 'FI',
  SE = 'SE',
  DK = 'DK',
}

export enum Currency {
  DKK = 'DKK',
  EUR = 'EUR',
  NOK = 'NOK',
  SEK = 'SEK',
}

export enum PaymentMethod {
  bambora = 'bambora_payform_embedded_card',
  creditCard = 'credit_card',
  manual = 'manual',
  missing = 'missing',
  paypal = 'ppec_paypal',
  stripe = 'stripe',
  sepa = 'stripe_sepa',
  klarna = 'kco',
}

export enum SiteLocale {
  FI = 'fi',
  EN = 'en',
  DA = 'da',
  SV = 'sv',
}

export enum Gender {
  Male = 'male',
  Female = 'female',
}

export enum SpecialNeed {
  GrainFree = 'grainFree',
  JointStiffness = 'jointStiffness',
  SensitiveSkin = 'sensitiveSkin',
  ItchyPaws = 'itchyPaws',
  DelicateStomach = 'delicateStomach',
  PickyEater = 'pickyEater',
  BrainWellness = 'brainWellness',
  GreedyEater = 'greedyEater',
  GrowingDog = 'growingDog',
  ActiveDog = 'activeDog',
  LoosePoop = 'loosePoop',
  Neutered = 'neutered',
  AgingDog = 'agingDog',
  TendencyToGainWeight = 'tendencyToGainWeight',
}

export enum Macro {
  Kcal = 'kcal',
  Fat = 'fat',
  Fiber = 'fiber',
  Protein = 'protein',
}

export enum ProductType {
  DryFood = 'dryFood',
  Treat = 'treat',
  Chew = 'chew',
  Accessory = 'accessory',
  PuppyBox = 'puppyBox',
  Supplement = 'supplement',
  WetFood = 'wetFood',
  Sauce = 'sauce',
  CouponOnly = 'couponOnly',
}

export enum MeasurementUnit {
  Tablespoon = 'tbsp',
  Deciliter = 'dl',
  Pieces = 'pcs',
  Milliliter = 'ml',
}

export enum Courier {
  bring = 'bring',
  budbee = 'budbee',
  instabox = 'instabox',
  matkahuolto = 'matkahuolto',
  postnord = 'postnord',
  posti = 'posti',
}
