import { Fragment, ReactNode, useContext } from 'react';
import dynamic from 'next/dynamic';
import { UserContext } from '../lib/context';
import Loader from './Loader';

const Login = dynamic(() => import('./Login'));

const AccessControl = ({ children }: { children: ReactNode }) => {
  const [{ user, loading: loading }] = useContext(UserContext);

  if (loading) {
    return <Loader />;
  }

  if (user?.token) {
    // use Fragment in order to set key. Key is important/nice-to-have becasue upon swapping users we dont mix user's data with another.
    return <Fragment key={user?.user_id}>{children || null}</Fragment>;
  }

  return <Login />;
};

export default AccessControl;
