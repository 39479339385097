'use client';

import { UserProvider, TranslationsProvider } from '../lib/context';
import MasterThemeProvider from './ThemeProvider';

import Cookies from 'js-cookie';
import { useEffect } from 'react';
import { AppProps } from 'next/app';
import TagManager, { TagManagerArgs } from 'react-gtm-module';

import Layout from '../components/Layout';
import { useLocale } from '../lib/hooks';
import { ModalProvider } from '../lib/context.modal';
import RouteListener from '../components/RouteListener';
import CookieConsent from './CookieConsent';
import dynamic from 'next/dynamic';

declare global {
  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace JSX {
    interface IntrinsicElements {
      'alvar-cookie-consent': any;
    }
  }
}

function AppWithProviders({ Component, pageProps, router }: AppProps) {
  const locale = useLocale();
  const allowCookies = Cookies.get('allowCookies');

  useEffect(() => {
    const consent = allowCookies === 'true' ? 'granted' : 'denied';

    window.dataLayer = window.dataLayer || [];

    function gtag(...someArgs: any) {
      // Won't work with rest params: https://stackoverflow.com/questions/69185534/google-tags-manager-dont-load-any-cookies
      // eslint-disable-next-line prefer-rest-params
      window.dataLayer.push(arguments);
    }

    gtag('consent', 'default', {
      ad_storage: consent,
      analytics_storage: consent,
      functionality_storage: consent,
      personalization_storage: consent,
      security_storage: consent,
      ad_user_data: consent,
      ad_personalization: consent,
    });

    window.dataLayer.push({
      event: 'default_consent',
      language: locale,
    });

    if (process.env.NEXT_PUBLIC_GTM_ID) {
      const args: TagManagerArgs = {
        gtmId: process.env.NEXT_PUBLIC_GTM_ID,
      };
      TagManager.initialize(args);
    }
  }, []);

  return (
    <TranslationsProvider>
      <UserProvider>
        <RouteListener />
        <CookieConsent
          lang={locale}
          preferenceSet={['true', 'false'].includes(allowCookies || '')}
        />
        <MasterThemeProvider>
          <ModalProvider>
            <Layout route={router.route}>
              <Component {...pageProps} />
            </Layout>
          </ModalProvider>
        </MasterThemeProvider>
      </UserProvider>
    </TranslationsProvider>
  );
}

export default dynamic(() => Promise.resolve(AppWithProviders), {
  ssr: false,
});
