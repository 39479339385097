import dayjs, { Dayjs } from 'dayjs';
import utc from 'dayjs/plugin/utc';
import isToday from 'dayjs/plugin/isToday';

import { ShippingCountry, SiteLocale } from '../types';

export const SHIPPING_DATE_FORMAT = 'YYYY-MM-DD';
export const NEXT_PAYMENT_DATE_WP_FORMAT = 'YYYY-MM-DD HH:mm:ss';

dayjs.extend(utc);
dayjs.extend(isToday);

// get next weekday if day is during weekend
const getWeekday = (day: Dayjs): Dayjs => {
  if (day.day() === 0) {
    return day.add(1, 'days');
  }
  if (day.day() === 6) {
    return day.add(2, 'days');
  }
  return day;
};

// dates from backend are UTC, convert them to local
export const getLocalDate = (dayOrString?: string | Dayjs): Dayjs =>
  dayjs.utc(dayOrString).local();

const dateFallback = '-';

/*
 * TODO: verify calcPaymentDay and calcDeliveryDay logics and outcomes.
 */

/*
 * See reason for casting to <any> https://github.com/microsoft/TypeScript/issues/24929
 * While any is used, this still completely benefits the functions using this fn
 * So the TS here says that if given false, returns false, otherwise Dayjs
 */
export const calcDeliveryDay = <T extends string | Dayjs | false>(
  shippingDayOrStringOrFalse: T,
): T extends false ? false : Dayjs => {
  if (!shippingDayOrStringOrFalse) {
    return false as any;
  }
  const shippingDayOrString = shippingDayOrStringOrFalse as string | Dayjs;
  const shippingDay = getLocalDate(shippingDayOrString);

  if (shippingDay.day() <= 2) {
    return shippingDay.add(3, 'days') as any;
  }

  // jump over the weekend
  return shippingDay.add(5, 'days') as any;
};

export const daysToDeliveryDay = <T extends string | false>(
  dayOrStringOrFalse: T,
): T extends false ? string : number | string => {
  if (!dayOrStringOrFalse) {
    return dateFallback as any;
  }
  const dayOrString = dayOrStringOrFalse as string;
  return calcDeliveryDay(dayOrString).diff(dayjs(), 'days') as any;
};

export const daysToPaymentDay = (stringOrFalse: string | false): string => {
  const days = getLocalDate(stringOrFalse || '').diff(dayjs(), 'days');

  if (!stringOrFalse || Number.isNaN(days)) {
    return dateFallback;
  }
  return days < 0 ? '0' : days.toString();
};

export const getDay = (dayOrString?: string | Dayjs): Dayjs => {
  if (!dayOrString) {
    return dayjs();
  }
  if (typeof dayOrString === 'string' && dayOrString.split('.').length === 3) {
    const [day, month, year] = dayOrString
      .split('.')
      .map((part) => parseInt(part, 10));

    if (!month || !day || !year) {
      return getLocalDate(dayOrString);
    }

    return dayjs(`${year}-${month}-${day}`);
  }

  if (dayjs.isDayjs(dayOrString)) {
    return dayOrString;
  }

  return getLocalDate(dayOrString);
};

export const formatDate = (
  timestamp: string | Dayjs | false,
  preferBlank?: boolean,
) => {
  if (!timestamp) {
    return preferBlank ? '' : dateFallback;
  }
  const d = new Date(
    dayjs.isDayjs(timestamp)
      ? timestamp.format()
      : getLocalDate(timestamp).format(),
  );
  return d.toLocaleDateString('fi-FI');
};

// Display today as time and other dates as dates
export const formatNiceDate = (
  dayOrString: string | Dayjs,
  locale: SiteLocale,
  todayAsTime = true,
): string => {
  const date = getLocalDate(dayOrString);

  if (date.isToday() && todayAsTime) {
    return date.format('HH:mm');
  }

  [];

  return date.format(
    [SiteLocale.FI, SiteLocale.DA].includes(locale) ? 'D.M.' : 'D.M',
  );
};

const firstPossibleDate = getWeekday(
  dayjs().utc().add(1, 'days').hour(0).minute(0).second(0),
);

interface DeliveryDate {
  delivery: Dayjs;
  shipping: Dayjs;
  payment: Dayjs;
}

const getDeliveryDateObject = (shipping: Dayjs): DeliveryDate => ({
  shipping,
  payment: shipping,
  delivery: getWeekday(calcDeliveryDay(shipping)),
});

const NEXT_SHIPPING_DAYS = Array(20).fill(null);
const NEXT_SHIPPING_WEEKS = Array(12).fill(null);

export const calcDeliveryDays = (): DeliveryDate[] => {
  // first loop days of day selection interval. after that add bunch of days that from following weeks so we dont end up with 100 item list of dates, but still can offer shipping to a further date.
  const datesOfDayInterval = NEXT_SHIPPING_DAYS.reduce((acc) => {
    const shipping = acc.length
      ? getWeekday(acc[acc.length - 1]?.shipping.add(1, 'days'))
      : firstPossibleDate;
    return [...acc, getDeliveryDateObject(shipping)];
  }, []);

  const lastShippingDay =
    datesOfDayInterval[datesOfDayInterval.length - 1].shipping;
  let daysToNextFriday = 5 - lastShippingDay.day();
  if (daysToNextFriday === 0) {
    daysToNextFriday = 7;
  }

  return [
    ...datesOfDayInterval,
    ...NEXT_SHIPPING_WEEKS.reduce(
      (acc) => {
        const shipping = getWeekday(acc.nextFriday);

        return {
          nextFriday: acc.nextFriday.add(7, 'days'),
          days: [...acc.days, getDeliveryDateObject(shipping)],
        };
      },
      { nextFriday: lastShippingDay.add(daysToNextFriday, 'days'), days: [] },
    ).days,
  ];
};

// date should not have hours/timestamp
export const getFirstPossibleStartDate = () =>
  getWeekday(dayjs(dayjs().utc().add(14, 'days').format('YYYY-MM-DD')));

export const isInFuture = (
  formattedDateString: typeof getDay.arguments,
): boolean => !getDay(formattedDateString).isBefore(dayjs());

// Get short format dates for locale for monday to sunday
export function getShortDaysForLocale(locale: SiteLocale) {
  const { format } = new Intl.DateTimeFormat(locale, { weekday: 'short' });

  return [...Array(7)].map((empty, index) =>
    format(new Date(Date.UTC(1970, 5, index + 1))),
  );
}

export const addCurrentTime = (addTo: string) =>
  `${addTo} ${dayjs().hour()}:${dayjs().minute()}:${dayjs().second()}`;

export const earliestPossibleShippingDate = getDay(
  calcDeliveryDays()[0].shipping.format(SHIPPING_DATE_FORMAT) as string,
);
