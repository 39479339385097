import dynamic from 'next/dynamic';
import { SiteLocale } from '@types';

interface Props {
  lang: SiteLocale;
  preferenceSet: boolean;
}

const CookieConsent = ({ lang, preferenceSet }: Props) => {
  if (preferenceSet) {
    return null;
  }

  return <alvar-cookie-consent lang={lang} />;
};

export default dynamic(() => Promise.resolve(CookieConsent), {
  ssr: false,
});
