import { NextRouter, useRouter } from 'next/router';
import { useContext, useEffect, useState } from 'react';
import { getLocation } from '../lib/api/location';
import { UserContext } from '../lib/context';
import { useAccount, useLocale, useModal } from '../lib/hooks';
import { AccountProps, SiteLocale, TokenData } from '../types';

interface SegmentPageData {
  url: string;
  language?: string;
  wp_user_id?: string;
  email?: string;
  shipping_country?: string;
  ip_country?: string;
}

const trackPageView = async (
  router: NextRouter,
  user: TokenData | null,
  account: AccountProps,
  locale: SiteLocale,
) => {
  const isBrowser = typeof window !== 'undefined';
  const url = router.route.replace('/[locale]', '') || '/';

  if (!isBrowser) {
    return null;
  }

  let data: SegmentPageData = {
    url,
    language: locale,
  };

  // Fetch from local storage first
  let countryCode = window.localStorage.getItem('ip_country');

  if (!countryCode) {
    // Fetch from API
    const location = await getLocation();
    if (location && location.countryCode) {
      countryCode = location.countryCode;
      if (countryCode) {
        window.localStorage.setItem('ip_country', countryCode);
      }
    }
  }

  if (user) {
    data = { ...data, wp_user_id: user.user_id, email: user.user_email };
  }

  if (account) {
    data = {
      ...data,
      shipping_country: account.shipping?.country ?? undefined,
    };
  }

  if (countryCode) {
    data = { ...data, ip_country: countryCode };
  }

  if (global.analytics && process.env.NEXT_PUBLIC_SEGMENT_WRITE_KEY) {
    global.analytics.page(url, { ...data });
  }

  return Promise.resolve();
};

const RouteListener = () => {
  const router = useRouter();
  const locale = useLocale();
  const [{ user, loading: userLoading }] = useContext(UserContext);
  const { account, loading: accountLoading } = useAccount();
  const [routeChanged, setRouteChanged] = useState(false);

  if (routeChanged && !userLoading && !accountLoading) {
    // Fire Segment API once per page view
    trackPageView(router, user, account, locale);
    setRouteChanged(false);
  }

  useEffect(() => {
    const handleRouteChange = async () => {
      setRouteChanged(true);
    };

    // Subscribe
    router.events.on('routeChangeComplete', handleRouteChange);

    // Unsubscribe
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, []);

  return null;
};

export default RouteListener;
