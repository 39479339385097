import {
  createContext,
  useState,
  Dispatch,
  SetStateAction,
  useContext,
  useCallback,
  ReactElement,
} from 'react';
import styled from 'styled-components';
import Container from '../components/Container';
import Notification, { NotificationType } from '../components/Notification';
import { useTranslation } from './hooks';

type NotificationProps = null | {
  [id: string]: {
    hidden?: boolean;
    type?: NotificationType;
    sticky?: boolean;
    permanent?: boolean;
    msg?: string | ReactElement;
  };
};

const notificationInitState: [
  NotificationProps,
  Dispatch<SetStateAction<NotificationProps>>?,
] = [null];

export const NotificationContext = createContext(notificationInitState);

const StyledNotification = styled(Notification)`
  margin: 0 auto ${(p) => p.theme.spacing()};
  transition:
    opacity 0.5s ease-in,
    transform 0.4s ease-in 0.1s,
    margin 0.25s ease-in 0.2s;
  white-space: pre-wrap;

  &[data-hide='true'] {
    opacity: 0;
    margin-top: calc(
      -3.5rem - 2em
    ); // based on Container margin size of it and this
    transform: translateY(-100%);
    transition-timing-function: ease-out;
  }
`;

const StyledContainer = styled(Container)`
  &:first-child {
    margin-top: 2em;
  }

  &[data-sticky='true'] {
    position: fixed;
    width: 100%;
    max-width: ${({ theme }) => theme.maxWidth};
    margin-left: auto;
    margin-right: auto;
    top: 2.5rem;
    left: 0;
    right: 0;
    /* z-index: hide under header when no modal is shown (nicer fadeaway), but when modal exists, just put it on top. */
    z-index: ${({ theme }) => theme.zIndex.stickyNotification};

    &:first-child {
      ${StyledNotification} {
        opacity: 0.95;
        box-shadow: 0 0 6px -3px rgba(0, 0, 0, 0.3);
      }

      &:hover,
      &:focus {
        ${StyledNotification} {
          opacity: 1;
        }
      }
    }
    + [data-sticky='true'] {
      margin-top: 1.5em;
      max-width: calc(${({ theme }) => theme.maxWidth} - 0.5em);
      width: calc(100% - 0.5em);
      z-index: ${({ theme }) => theme.zIndex.default};
      opacity: 0.4;
      pointer-events: none;
    }
  }
`;

export const NotificationProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [notifications, setNotification] = useState<NotificationProps>(null);
  const t = useTranslation();

  const removeNotification = (id: string) => {
    setNotification((state) =>
      Object.entries(state || {}).reduce(
        (acc, [nid, n]) => ({
          ...acc,
          ...(nid === id ? null : { [nid]: n }),
        }),
        {},
      ),
    );
  };

  return (
    <NotificationContext.Provider value={[notifications, setNotification]}>
      {notifications ? (
        <>
          {Object.entries(notifications).map(
            ([id, { hidden = false, msg, type, sticky, permanent }]) => (
              <StyledContainer data-sticky={sticky} key={id}>
                <StyledNotification
                  type={(type || id) as NotificationType}
                  data-hide={!!hidden}
                  {...(permanent
                    ? null
                    : {
                        onRemove: () => {
                          removeNotification(id);
                        },
                      })}
                >
                  {msg || t(id || `notification.${type}`)}
                </StyledNotification>
              </StyledContainer>
            ),
          )}
          {children}
        </>
      ) : (
        children
      )}
    </NotificationContext.Provider>
  );
};

interface NotificationConfig {
  timeout: false | number;
  type?: NotificationType;
  sticky: boolean;
  fadeOut: number;
  permanent: boolean;
}

const defaultOptions: {
  timeout?: false | number;
  type?: NotificationType;
  sticky?: boolean;
  fadeOut?: number;
  permanent?: boolean;
} = {
  timeout: false,
  fadeOut: 400,
  type: undefined,
  sticky: false,
  permanent: false,
};

const removeKey = (entries: Record<string, unknown>, key: string) =>
  Object.entries(entries).reduce(
    (acc, [id, n]) => ({
      ...acc,
      ...(id === key ? null : { [id]: n }),
    }),
    {},
  );

export const useNotification = (id: string, options = defaultOptions) => {
  const [notifications, setNotification] = useContext(NotificationContext);
  const { type, timeout, fadeOut, sticky, permanent } = {
    ...defaultOptions,
    ...options,
  } as NotificationConfig;

  const isNotificationShown = !!(notifications && notifications[id]);

  const removeNotification = useCallback(() => {
    if (setNotification) {
      if (fadeOut) {
        setNotification((state) =>
          state && state[id]
            ? { ...state, [id]: { ...state[id], hidden: true } }
            : state,
        );
        setTimeout(() => {
          setNotification((state) =>
            state && state[id] ? removeKey(state, id) : state,
          );
        }, fadeOut);
      } else {
        setNotification((state) =>
          state && state[id] ? removeKey(state, id) : state,
        );
      }
    }
  }, [id, setNotification, fadeOut]);

  const showNotification = useCallback(
    (
      msg?: string | ReactElement,
      cleanup?: () => void,
      settings?: typeof defaultOptions,
    ) => {
      if (setNotification) {
        setNotification((state) => {
          if (!state || (state && !state[id])) {
            return {
              ...state,
              [id]: {
                hidden: false,
                type,
                sticky,
                msg,
                permanent,
                ...settings,
              },
            };
          }
          return state;
        });

        if (timeout) {
          setTimeout(
            () => {
              removeNotification();
              if (cleanup) {
                cleanup();
              }
            },
            timeout - fadeOut / 2,
          );
        }
      }
    },
    [
      id,
      setNotification,
      type,
      timeout,
      sticky,
      fadeOut,
      permanent,
      removeNotification,
    ],
  );

  // see note below for this if
  if (setNotification) {
    return {
      isNotificationShown,
      showNotification,
      removeNotification,
    };
  }

  // remove once someone can mock setState to notitificationInitState. This is basically never called though
  return {
    isNotificationShown: false,
    showNotification: () => {
      /* hello darkness */
    },
    removeNotification: () => {
      /* hello darkness */
    },
  };
};
