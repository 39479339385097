import { useRouter } from 'next/router';
import styled from 'styled-components';

import Link from '../Link';
import Accordion from '../Accordion';
import { useAccount, useLocale } from '../../lib/hooks';
import { ThemeProps } from '../../lib/theme';
import { languageNativeNameMap } from '../../lib/utils.locale';
import { SiteLocale } from '../../types';

interface Props {
  onClick?: () => void;
  matchMarketingSite: boolean;
}

interface ThemeAndMatch extends ThemeProps {
  matchMarketingSite: boolean;
}

const Language = styled.li(
  ({
    theme: { colors, transitionTime },
    matchMarketingSite,
  }: ThemeAndMatch) => `
  list-style: none;
  text-align: left;
  padding: 0.5rem 1rem;
  background: ${
    matchMarketingSite ? colors.highlightColor : colors.backgroundColorInverted
  };
  transition: background ${transitionTime.snappy} ease-in;

  &:first-child {
    margin-top: 0.5rem;
  }

  @media (hover: hover) and (pointer: fine) {    
    &:hover {
       background: ${colors.languageSelector.hover};
    }
  }
`,
);

const Selector = styled(Accordion)`
  padding: 0 1rem;

  div {
    padding: 0;
    margin: 0 -1rem;
  }

  summary:after {
    background: currentColor;
    mask: url(/icons/arrow.svg) center no-repeat;
  }
`;

const LanguageSelector = ({ onClick, matchMarketingSite }: Props) => {
  const { asPath } = useRouter();
  const locale = useLocale();
  const { updateLang } = useAccount();

  function onChange(newLocale: SiteLocale) {
    // Optimistic approach to keep language changing fast, because failing is not big issue here.
    if (newLocale !== locale) {
      updateLang(newLocale);
      window.dataLayer.push({
        language: newLocale,
      });
    }
  }

  const navigationLinks = Object.values(SiteLocale).map(
    (loc) =>
      loc !== locale && (
        <Language key={loc} matchMarketingSite={matchMarketingSite}>
          <Link
            href={asPath}
            nextLocale={loc}
            onClick={() => {
              onChange(loc);
              if (onClick) {
                onClick();
              }
            }}
          >
            {languageNativeNameMap[loc]}
          </Link>
        </Language>
      ),
  );

  return (
    <Selector
      id="lang-selector"
      content={navigationLinks}
      displayArrow
      closeOnClickOutside
    >
      {languageNativeNameMap[locale]}
    </Selector>
  );
};

export default LanguageSelector;
