import {
  createContext,
  ReactNode,
  useEffect,
  useCallback,
  Dispatch,
  SetStateAction,
  useState,
} from 'react';

import useSWR from 'swr';
import { TokenData } from '../types';
import {
  getRefreshedToken,
  getUserAndValidateToken,
  validateToken,
} from './api/user';
import { reportError } from './bugsnag';
import { noAuthFetcher } from './fetcher';
import { usePersistedState } from './hooks.persist';
import { getTranslations, TranslationType } from './translations';
import { getPersistedUserToken, clearPersistedUser } from './utils.persist';

interface ProviderProps {
  children: ReactNode;
}

export interface UserContextProps {
  user: (TokenData & { nonce?: string }) | null;
  loading: boolean;
  revalidating: boolean;
}

const appContextInitialValue: [
  UserContextProps,
  Dispatch<SetStateAction<UserContextProps>>,
] = [
  {
    user: null,
    loading: true,
    revalidating: false,
  },
  (s) => s,
];

export const UserContext = createContext(appContextInitialValue);
const appInitialValue = appContextInitialValue[0];

/*
 * AppProvider is sort of user token management + app data provider
 * If token gets set by login, it persists things correctly. If token expires mid session, it refreshes it, if token breaks for some reason, it logs out user right away
 * Because token validation returns user by default, it also sets user. Token validation is safety and nice to have, so it happens on the background.
 */

export const UserProvider = ({ children }: ProviderProps) => {
  const [state, setState] = usePersistedState<UserContextProps>(
    'user',
    appInitialValue,
  );

  const updateState = useCallback(setState, [setState]);

  useEffect(() => {
    // listen to token. if someone manipulates it by hand, we need to force logouts and stuff.
    // if no token is set, look for a persisted one.

    // do nothing if revalidation is in process
    if (!state.revalidating) {
      // if no session persisted token is present
      if (!state.user?.token) {
        const savedToken = getPersistedUserToken();
        if (savedToken) {
          // validate savedToken on the background
          (async () => {
            try {
              updateState((s) => ({ ...s, revalidating: true }));
              const isTokenValid = await validateToken(savedToken);
              if (isTokenValid) {
                updateState((s) => ({
                  ...s,
                  loading: false,
                  user: s.user?.token
                    ? s.user
                    : {
                        token: savedToken,
                        user_display_name: '',
                        user_email: '',
                        user_id: '',
                        user_nicename: '',
                        token_expires: 0,
                      },
                }));
                // if token is valid, we can live with that. Let the refresh be done on the background. As a step that's kind of unnecessary even for persisted token.
                const userToken = await getRefreshedToken(savedToken);

                if (userToken) {
                  // set user unless logout's been done.
                  updateState((s) => ({
                    ...s,
                    user: s.user?.token_expires ? userToken : s.user,
                    revalidating: false,
                  }));
                }
              } else {
                // If token is not valid, basically do a logout
                updateState(appInitialValue);
                clearPersistedUser();
              }
            } catch (error) {
              reportError(error);
              // may fail if component unmounts, but should not. if does, it's fine.
            }
          })();
        } else if (state.loading) {
          updateState((s: UserContextProps) => ({
            ...s,
            loading: false,
          }));
        }
      } else {
        /* token of some sort must be set in session state */
        if (state.loading) {
          updateState((s) => ({
            ...s,
            loading: false,
          }));
        }

        /*
         * If token expires and revalidation is not already ongoing, revalidate the token. Note that if user has chosen 'remember me' in login
         * this does not update that token. If that token expires, the session should then expire. This is by design, though untested.
         * In this case, they session should seriously just expire, or, the user could be prompted for PW and then the session continues. Like Google does
         */
        /* TODO: this works, but doesn't get triggered unless user does a refresh */
        if (new Date((state.user?.token_expires || 0) * 1000) <= new Date()) {
          (async () => {
            updateState((s) => ({ ...s, revalidating: true }));
            // user could've done a logout here in theory, so check for token because this is async
            const user =
              state?.user?.token && !state?.user?.one_time
                ? await getUserAndValidateToken(state.user.token)
                : null;
            if (user) {
              // set user unless logout's been done.
              updateState((s) => ({
                ...s,
                user: s.user?.token ? user : s.user,
                revalidating: false,
              }));
            } else {
              // If token is not valid, basically do a logout
              updateState(appInitialValue);
              clearPersistedUser();
            }
          })();
        }
      }
    }
  }, [
    state.user,
    state.user?.token,
    state.user?.token_expires,
    updateState,
    state.revalidating,
    state.loading,
  ]);

  return (
    <UserContext.Provider value={[state, setState]}>
      {children}
    </UserContext.Provider>
  );
};

export const TranslationsContext = createContext({});

interface TranslationsProps {
  children: ReactNode;
}

/*
 * TranslationsProvider provides translations in all active languages. Translations are done in CMS, but also in local files under public/translations.
 * CMS translation is the dominant one, but it probably doesn't make sense to take all translations to CMS, such as accessibility labels, common words (in forms eg.) etc.
 * Access translations with useTranslation -hook and pass a key to it.
 */
export const TranslationsProvider = ({ children }: TranslationsProps) => {
  const {
    data: fetchedTranslations = {},
    isLoading,
    mutate,
  } = useSWR<TranslationType>('/api/translations', noAuthFetcher, {
    revalidateOnFocus: false,
  });

  // Trigger revalidation if for some reason no translations were fetched
  if (!isLoading && Object.entries(fetchedTranslations).length === 0) {
    mutate();
  }

  const [displayKey, setDisplayKey] = useState(false);
  const keyCombo: Record<string, boolean> = {};
  const handleKeyboard: EventListener = (e: Event) => {
    const { type, key, repeat } = e as KeyboardEvent;
    if (repeat) {
      return;
    }
    if (type === 'keydown') {
      keyCombo[key] = true;

      // Hold  "a"+"l"+"v" and tab "t" to toggle
      if (keyCombo.a && keyCombo.l && keyCombo.v && key === 't') {
        setDisplayKey(!displayKey);
      }
    }
    // Remove key from combo when released
    if (type === 'keyup') {
      delete keyCombo[key];
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', handleKeyboard);
    document.addEventListener('keyup', handleKeyboard);

    return () => {
      document.removeEventListener('keydown', handleKeyboard);
      document.removeEventListener('keyup', handleKeyboard);
    };
  });

  const resolvedTranslations = getTranslations({
    fetchedTranslations,
    displayKey,
  });

  return (
    <TranslationsContext.Provider value={resolvedTranslations}>
      {children}
    </TranslationsContext.Provider>
  );
};
