import styled from 'styled-components';

export interface FlexProps {
  justify?:
    | 'space-between'
    | 'space-around'
    | 'space-evenly'
    | 'flex-start'
    | 'flex-end'
    | 'center';
  alignItems?: 'flex-start' | 'center' | 'baseline' | 'flex-end';
  direction?: 'column' | 'row';
  width?: string;
  maxWidth?: string;
  height?: string;
  maxHeight?: string;
  wrap?: boolean;
  margin?: string;
  padding?: string;
  gap?: string;
  flow?: string;
  className?: string;
}

const FlexWrapper = styled.div.withConfig({
  shouldForwardProp: (p) =>
    ![
      'wrap',
      'alignItems',
      'justify',
      'direction',
      'width',
      'maxWidth',
      'height',
      'maxHeight',
      'margin',
      'padding',
      'gap',
      'flow',
    ].includes(p),
})(
  ({
    justify,
    direction,
    alignItems,
    width,
    maxWidth,
    height,
    maxHeight,
    wrap,
    margin,
    padding,
    gap,
    flow,
  }: FlexProps) => `
    display: flex;
    justify-content: ${justify || 'flex-start'};
    flex-direction: ${direction || 'row'};
    align-items: ${alignItems || 'inherit'};
    ${wrap ? 'flex-wrap: wrap;' : ''}
    ${margin ? `margin: ${margin};` : ''}
    ${padding ? `padding: ${padding};` : ''}
    ${gap ? `gap: ${gap};` : ''}
    ${flow ? `flex-flow: ${flow};` : ''}
    ${width ? `width: ${width};` : ''}
    ${maxWidth ? `max-width: ${maxWidth};` : ''}
    ${height ? `height: ${height};` : ''}
    ${maxHeight ? `max-height: ${maxHeight};` : ' '}
  `,
);

export default FlexWrapper;

export const Column = styled(FlexWrapper).attrs({ direction: 'column' })``;

export const Row = styled(FlexWrapper).attrs({ direction: 'row' })``;
