import couponOnlyProducts from 'constants/couponOnlyProducts';
import {
  Discount,
  Coupon,
  ShippingCountry,
  SiteLocale,
  ExtendedProduct,
  CartItem,
} from '../types';
import { makeMoney, safeSubtractFloats } from './utils';
import {
  findProduct,
  findVariant,
  isDryFood as productIsDryFood,
  getTotalsData,
} from './utils.products';

const getAddonTotalPrice = (price: CartItem['price'], quantity: number) =>
  quantity * price;

const percentage = (amount: string | number): number =>
  parseFloat(amount.toString()) / 100;

const getDiscount = (
  coupon: Coupon,
  product: CartItem,
  couponApplyTimesLeft: number,
): Discount => {
  const { amount, discount_type } = coupon;
  const { price, discount, quantity } = product;
  const isDryFood = productIsDryFood(product.type);

  // apply discount to as many as we can
  const applyDiscountTo = Math.min(couponApplyTimesLeft, quantity);

  // get total price for what is to be discounted
  const discountablePrice = isDryFood
    ? price
    : getAddonTotalPrice(price, applyDiscountTo);

  // if quantity is larger than item limit, add the remaining items to the total
  const fullPricePart =
    couponApplyTimesLeft < (isDryFood ? 1 : quantity)
      ? getAddonTotalPrice(
          price,
          isDryFood ? 1 : quantity - couponApplyTimesLeft,
        )
      : 0;

  // display discount amount per total price.
  const discountAmount =
    discount_type === 'recurring_percent' || discount_type === 'percent'
      ? discountablePrice * percentage(amount)
      : applyDiscountTo * parseFloat(amount);

  /* total could probably be clearer/better, but it seems to work. Addons can be discounted by another coupon earlier. */
  const discountObject = {
    total:
      Math.max(0, discountablePrice - discountAmount + fullPricePart) -
      (discount?.amount || 0),
    amount: discountAmount + (discount?.amount || 0),
    usesLeft: couponApplyTimesLeft - applyDiscountTo,
  };

  if (discountObject.total < 0) {
    discountObject.total = 0;
  }
  if (discountObject.amount > price) {
    discountObject.amount = price;
  }
  return discountObject;
};

export const removeDiscount = (addons: CartItem[]) =>
  addons.map(({ discount, ...addon }) => addon);

const sortByPrice = (a: CartItem, b: CartItem) => a.price - b.price;

export const couponAppliesToProduct = (
  coupon: Coupon,
  couponApplyTimesLeft: undefined | number,
  product: CartItem,
) => {
  const isBundle = (findVariant(product.wordpressId, [product])?.pcs || 1) > 1;

  if (isBundle) {
    return false;
  }

  const idNotExcluded = !coupon?.excluded_product_ids?.includes(
    product.wordpressId,
  );
  const categoryNotExcluded = !coupon?.excluded_product_categories?.some(
    (category) => product.wcCategories.includes(category),
  );

  const noIdLimit = (coupon?.product_ids || []).length === 0;
  const appliesToId = coupon?.product_ids?.includes(product.wordpressId);

  const noCategoryLimit = (coupon?.product_categories || []).length === 0;
  const appliesToCategory = coupon?.product_categories?.some((category) =>
    (product.wcCategories || []).includes(category),
  );

  const addonNotFree = product?.discount?.total !== 0;
  const hasUsesLeft =
    typeof couponApplyTimesLeft === 'undefined' || couponApplyTimesLeft > 0;

  return (
    idNotExcluded &&
    categoryNotExcluded &&
    (noIdLimit || appliesToId) &&
    (noCategoryLimit || appliesToCategory) &&
    addonNotFree &&
    hasUsesLeft
  );
};

export const applyCouponsToCartItems = (
  items: CartItem[],
  coupons: Coupon[],
): {
  items: CartItem[];
  discounts: (Coupon & { discount_given: number })[];
} => {
  // discounts apply to cheapest products first, so sort accordingly
  const sortedItems = items.sort(sortByPrice);

  if (!coupons.length) {
    return { items: removeDiscount(sortedItems), discounts: [] };
  }

  const sortedCoupons = coupons.sort((a, b) => {
    if (Number.parseInt(b.amount, 10) - Number.parseInt(a.amount, 10) === 0) {
      return b.individual_use && !a.individual_use ? 1 : -1;
    }
    return Number.parseInt(b.amount, 10) - Number.parseInt(a.amount, 10);
  });

  const normalCouponCount = sortedCoupons.filter(
    ({ individual_use }) => !individual_use,
  ).length;

  const summedDiscounts: Record<string, number> = Object.fromEntries(
    coupons.map((i) => [i.code, 0]),
  );

  // Important: don't early exit after coupon used, because there could be addons afterwards with already added discounts from previous coupons. those need to be reset.
  const result = sortedCoupons.reduce<CartItem[]>(
    (acc, coupon) => {
      if (coupon.individual_use && normalCouponCount) {
        return acc;
      }

      if (coupon.discount_type === 'fixed_cart') {
        return acc;
      }

      const { discountedItems } = acc.reduce<{
        discountedItems: CartItem[];
        couponApplyTimesLeft: number;
      }>(
        (bacc, item) => {
          const isCouponApplicable = couponAppliesToProduct(
            coupon,
            bacc.couponApplyTimesLeft,
            item,
          );

          if (!isCouponApplicable) {
            return {
              ...bacc,
              discountedItems: [...bacc.discountedItems, item],
            };
          }
          const applyTimesLeft = isCouponApplicable
            ? Math.max(0, bacc.couponApplyTimesLeft - (item.quantity || 1))
            : bacc.couponApplyTimesLeft;

          const discount = getDiscount(
            coupon,
            item,
            // pass applytimesleft, but pass the previous loop's one because after this it might be zero
            bacc.couponApplyTimesLeft,
          );

          const discountedItem = {
            ...item,
            discount: Number.isNaN(discount.total) ? undefined : discount,
          };
          summedDiscounts[coupon.code] += Number.isNaN(discount.amount)
            ? 0
            : discount.amount;

          return {
            couponApplyTimesLeft: applyTimesLeft,
            discountedItems: [...bacc.discountedItems, discountedItem],
          };
        },
        {
          couponApplyTimesLeft: coupon.limit_usage_to_x_items || Infinity,
          discountedItems: [],
        },
      );

      return discountedItems;
    },
    removeDiscount(sortedItems), // have to remove discount from all the items because coupons might have updated and apply order changes
  );

  return {
    items: result,
    discounts: sortedCoupons.map((i) => ({
      ...i,
      discount_given: summedDiscounts[i.code],
    })),
  };
};

type DiscountType = 'percent' | 'fixed';

export const getDiscountType = (coupon: Coupon): DiscountType =>
  ['fixed_cart', 'fixed_product', 'recurring_fee'].includes(
    coupon.discount_type,
  )
    ? 'fixed'
    : 'percent';

export function displayCouponAmount(
  coupon: Coupon,
  country: ShippingCountry,
  locale: SiteLocale,
) {
  if (getDiscountType(coupon) === 'percent') {
    return `${coupon.amount}%`;
  }
  return makeMoney(coupon.amount, country, locale);
}

export const getCouponProducts = ({
  coupon,
  products,
}: {
  coupon: Coupon;
  products: ExtendedProduct[];
}) =>
  products.reduce<ExtendedProduct[]>(
    (acc, { wordpressId, variants, ...prod }) => {
      // check for variants first, as some products use the WP id of one of the variants
      const includedVariants = (variants || []).filter(
        ({ wordpressId: id }) => id && coupon.product_ids.includes(id),
      );

      if (includedVariants.length) {
        return [
          ...acc,
          ...includedVariants.map((v) => ({
            ...prod,
            variants,
            wordpressId: v.wordpressId as number,
          })),
        ];
      }
      if (coupon?.product_ids.includes(wordpressId)) {
        return [
          ...acc,
          {
            ...prod,
            variants,
            wordpressId,
          },
        ];
      }
      return acc;
    },
    [],
  );

const parentOrderCoupons: Record<ShippingCountry, string | undefined> = {
  [ShippingCountry.DK]: process.env.NEXT_PUBLIC_FIRST_ORDER_COUPON_DK,
  [ShippingCountry.FI]: process.env.NEXT_PUBLIC_FIRST_ORDER_COUPON_FI,
  [ShippingCountry.SE]: process.env.NEXT_PUBLIC_FIRST_ORDER_COUPON_SE,
};

export const getParentOrderCoupon = (country: ShippingCountry) =>
  parentOrderCoupons[country];

type CouponType =
  | 'singleProduct'
  | 'selectedProducts'
  | 'singleCategory'
  | 'selectedCategories'
  | 'entireOrder'
  | 'couponOnlyProducts'
  | 'unknown';

export const getCouponType = (coupon: Coupon): CouponType => {
  const { product_ids = [], product_categories = [] } = coupon;
  if (product_ids.length === 0 && coupon.product_categories.length === 0) {
    return 'entireOrder';
  } else if (
    product_ids.length > 0 &&
    product_ids.every((id) => !!findProduct(id, couponOnlyProducts))
  ) {
    return 'couponOnlyProducts';
  } else if (product_ids.length === 1) {
    return 'singleProduct';
  } else if (product_ids.length > 1) {
    return 'selectedProducts';
  } else if (product_categories.length === 1) {
    return 'singleCategory';
  } else if (product_categories.length > 1) {
    return 'selectedCategories';
  }
  return 'unknown';
};

export const getDiscountDescription = (
  t: (key: string, props?: Record<string, string | number>) => string,
  coupon: Coupon,
  country: ShippingCountry,
  locale: SiteLocale,
  allProducts: Array<ExtendedProduct>,
): string | undefined => {
  if (coupon.description?.[locale]) {
    return coupon.description[locale];
  }

  const couponType = getCouponType(coupon);
  const discount = displayCouponAmount(coupon, country, locale);
  const productLimit = coupon.limit_usage_to_x_items || '';

  let translation: undefined | string;
  let product = '';
  let category = '';
  let categories = '';
  let productList = '';

  if (couponType === 'entireOrder') {
    translation = 'applies_to_all';
  } else if (couponType === 'singleProduct') {
    translation = 'applies_to_one_product';
    product = findProduct(coupon.product_ids[0], allProducts)?.title || '';
  } else if (couponType === 'selectedProducts') {
    translation = 'applies_to_multiple_product_names';
    productList = coupon.product_ids
      .map((id) => findProduct(id, allProducts)?.title || '')
      .join(', ');
  } else if (couponType === 'selectedCategories') {
    translation = 'applies_to_categories';
    categories = coupon.product_categories
      .map((c) => t(`discount.product_category.${c}`))
      .join(', ');
  } else if (couponType === 'singleCategory') {
    translation = 'applies_to_category';
    category = t(
      `discount.product_category.${coupon.product_categories[0] || ''}`,
    );
  } else if (couponType === 'couponOnlyProducts') {
    translation = `discount.description_${coupon.code}`;
  }

  return (translation &&= t(`discount.${translation}`, {
    product,
    productList,
    discount,
    productLimit,
    categories,
    category,
  }));
};

export const isCoupon = (coupon: any): coupon is Coupon => {
  return !!coupon && !coupon.error && typeof coupon.code === 'string';
};
