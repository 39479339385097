// import { PaymentIntent, PaymentMethod } from '@stripe/stripe-js';
import { WizardFormValues } from '@components/forms/Recommendation/types';
import {
  AccountInfoType,
  DeliveryTypeCode,
  Dog,
  TokenData,
  WpLocale,
  ShippingMethod,
  WPDogPost,
  ShippingCountry,
  SiteLocale,
} from '../../types';
import { mapLocaleFromSiteToWp } from '@lib/utils.locale';
import { createWpDog } from '@lib/utils.dog';

interface LoginProps {
  username: string;
  password: string;
}

export const dataIsTokenData = (data: any): data is TokenData =>
  // must be object
  typeof data === 'object' &&
  !Array.isArray(data) &&
  // these must be strings
  [
    data.token,
    data.user_id,
    data.user_email,
    data.user_nicename,
    data.user_display_name,
  ].every((i) => i && typeof i === 'string') &&
  // expiry must be number
  typeof data.token_expires === 'number' &&
  // one_time is optional but if set must be boolean
  ['undefined', 'boolean'].includes(typeof data.one_time);

export const loginUser = async ({
  username,
  password,
}: LoginProps): Promise<TokenData | null> => {
  const userResponse = await fetch(`${process.env.NEXT_PUBLIC_JWT_API_URL}`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ username, password }),
  });

  if (userResponse.ok) {
    const data = await userResponse.json();
    if (global.analytics && process.env.NEXT_PUBLIC_SEGMENT_WRITE_KEY) {
      global.analytics.identify(data.user_id, {
        email: data.user_email,
        wp_user_id: data.user_id,
        action: 'login',
      });
    }

    if (dataIsTokenData(data)) {
      return data;
    }
  }

  return null;
};

export const wpLoginUser = async ({
  token,
}: {
  token: string;
}): Promise<boolean> => {
  const loginResponse = await fetch(
    `${process.env.NEXT_PUBLIC_WP_ALVAR_API}/wp-login`,
    {
      credentials: 'include',
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    },
  );
  return !!loginResponse?.ok;
};

export const wpLogoutUser = async (): Promise<boolean> => {
  const logoutResponse = await fetch(
    `${process.env.NEXT_PUBLIC_WP_ALVAR_API}/wp-logout`,
    {
      credentials: 'include',
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    },
  );

  return !!logoutResponse?.ok;
};

export const getRefreshedToken = async (token: string) => {
  const userResponse = await fetch(
    `${process.env.NEXT_PUBLIC_JWT_API_URL}/refresh`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    },
  )
    .then(async (r) => (r.ok ? await r.json() : null))
    .catch((e) => {
      reportError(e);
      return null;
    });

  return userResponse;
};

export const validateToken = async (token: string): Promise<boolean> => {
  const tokenResponse = await fetch(
    `${process.env.NEXT_PUBLIC_JWT_API_URL}/validate`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    },
  )
    .then((r) => r.ok)
    .catch((e) => {
      reportError(e);
      return false;
    });

  return tokenResponse;
};

export const getUserAndValidateToken = async (token: string) => {
  const isValidToken = await validateToken(token);

  if (isValidToken) {
    const user = await getRefreshedToken(token);
    return user;
  }
  return null;
};

export const getAccount = async (token: string, options: any = {}) => {
  const accountResponse = await fetch(
    `${process.env.NEXT_PUBLIC_WP_ALVAR_API}/account`,
    {
      ...options,
      method: 'GET',
      cache: 'no-cache',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    },
  );
  if (accountResponse.ok) {
    const data = await accountResponse.json();
    return data;
  }

  return null;
};

interface UpdateProps {
  token: string;
  type: AccountInfoType;
  payload: {};
}

export interface AccountUpdateProps {
  email?: string;
  password?: string;
  first_name?: string;
  last_name?: string;
  phone?: string;
  locale: WpLocale;
  billing_postcode?: string;
  billing_city?: string;
  billing_address_1?: string;
  billing_country?: string;
  shipping_postcode?: string;
  shipping_city?: string;
  shipping_address_1?: string;
  shipping_country?: string;
  delivery_type?: DeliveryTypeCode;
  delivery_name?: string;
  delivery_drop_point?: string;
}

export const updateAccount = async ({
  token,
  type,
  payload = {},
}: UpdateProps) => {
  const accountResponse = await fetch(
    `${process.env.NEXT_PUBLIC_WP_ALVAR_API}/account`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ data_type: type, ...payload }),
    },
  );

  if (accountResponse.ok) {
    const data = await accountResponse.json();
    return data;
  }

  return { error: accountResponse.statusText };
};

// NOTE: Payment requests from the app are decommissioned for now.
//
// interface PaymentMethodProps {
//   token: string;
//   payload: PaymentMethod;
// }

// export const updatePaymentMethod = async ({
//   token,
//   payload,
// }: PaymentMethodProps) => {
//   const cardResponse = await fetch(
//     `${process.env.NEXT_PUBLIC_WP_ALVAR_API}/card`,
//     {
//       method: 'POST',
//       headers: {
//         'Content-Type': 'application/json',
//         Authorization: `Bearer ${token}`,
//       },
//       body: JSON.stringify(payload),
//     },
//   );

//   if (cardResponse.ok) {
//     const data = await cardResponse.json();
//     return data;
//   }

//   return null;
// };

// interface PaymentIntentProps {
//   token: string;
//   payload:
//     | {
//         order_id: number;
//         payment_method: PaymentMethod;
//       }
//     | { payment_intent: PaymentIntent };
// }

// export const getPaymentIntent = async ({
//   token,
//   payload,
// }: PaymentIntentProps) => {
//   const piResponse = await fetch(
//     `${process.env.NEXT_PUBLIC_WP_ALVAR_API}/payment`,
//     {
//       method: 'POST',
//       headers: {
//         'Content-Type': 'application/json',
//         Authorization: `Bearer ${token}`,
//       },
//       body: JSON.stringify(payload),
//     },
//   );

//   if (piResponse.ok) {
//     const data = await piResponse.json();
//     return data;
//   }

//   return null;
// };

// interface PaymentIntentUpdateProps {
//   token: string;
//   payload: { payment_intent: PaymentIntent };
// }

// export const updatePaymentIntent = async ({
//   token,
//   payload,
// }: PaymentIntentUpdateProps) => getPaymentIntent({ token, payload });

// interface UpdateOrderProps {
//   token: string;
//   payload: {
//     order_id: number;
//     product_id?: number;
//     product_quantity?: number;
//   }
// }

export interface InitiateUserPayload {
  email: string;
  shipping_country: string;
  locale: WpLocale;
  aw_user_is_referral?: string;
  opt_in: boolean;
}

interface InitiateResponse {
  token?: TokenData;
  dog?: null | Dog;
  error?: string;
  message?: string;
}

export const initiateUser = async (
  payload: InitiateUserPayload,
): Promise<InitiateResponse> => {
  const response = await fetch(
    `${process.env.NEXT_PUBLIC_WP_ALVAR_API}/initiate-user`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    },
  );

  if (response.ok) {
    const success = await response.json();
    return { token: success.token.data, dog: success.dog };
  }
  const errorData = await response.json();

  return { error: errorData.code, message: errorData.message };
};

export const checkEmail = async (email: string): Promise<boolean> => {
  const payload = { email };
  const response = await fetch(
    `${process.env.NEXT_PUBLIC_WP_ALVAR_API}/check-email`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    },
  );

  if (response.ok) {
    const isInUse = await response.json();
    return Boolean(isInUse);
  }
  return true;
};

export interface AccountCreationProps {
  email: string;
  password: string;
  first_name: string;
  last_name: string;
  phone: string;
  locale: WpLocale;
  billing_postcode: string;
  billing_city: string;
  billing_address_1: string;
  billing_country?: string;
  shipping_postcode?: string;
  shipping_city?: string;
  shipping_address_1?: string;
  shipping_country?: string;
  delivery_type?: DeliveryTypeCode;
  delivery_name?: string;
  delivery_drop_point_id?: string;
  delivery_method?: ShippingMethod;
  breeder?: boolean;
  kennel_name?: string;
}

export const createAccount = async (
  token: string,
  payload: AccountCreationProps,
): Promise<{ token?: TokenData; error?: string; message?: string }> => {
  const response = await fetch(
    `${process.env.NEXT_PUBLIC_WP_ALVAR_API}/finalize-user`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(payload),
    },
  );

  if (response.ok) {
    const success = await response.json();
    return { token: success.token.data };
  }
  const errorData = await response.json();

  return { error: errorData.code, message: errorData.message };
};

export interface SubscriptionUserPayload
  extends Pick<
    WizardFormValues,
    'aw_user_is_referral' | 'email' | 'opt_in' | 'password' | 'shipping_country'
  > {
  locale: WpLocale;
  dog: WPDogPost;
}

export const resolveCreateSubscriptionUserPayload = (
  values: WizardFormValues & { locale: SiteLocale },
): SubscriptionUserPayload => {
  const dog = createWpDog(values);
  const locale = mapLocaleFromSiteToWp(values.locale);
  const { aw_user_is_referral, email, opt_in, password, shipping_country } =
    values;

  return {
    aw_user_is_referral,
    email,
    opt_in,
    password,
    locale,
    shipping_country,
    dog,
  };
};

interface UserCreationError {
  error: string;
  message: string;
}

export const createSubscriptionUser = async (
  payload: SubscriptionUserPayload,
): Promise<
  (TokenData & { dog_id: WPDogPost['dog_id'] }) | UserCreationError
> => {
  const response = await fetch(
    `${process.env.NEXT_PUBLIC_WP_ALVAR_API}/register-subscription-user`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    },
  );

  const responseData = await response.json();

  if (response.ok) {
    return {
      ...responseData.token.data,
      dog_id: responseData.dog_id,
    };
  }

  return { error: responseData.code, message: responseData.message };
};

export const resetPassword = async (
  email: string,
): Promise<{ success: boolean | string; error: boolean | string }> => {
  const response = await fetch(
    `${process.env.NEXT_PUBLIC_WP_ALVAR_API}/forgot-password`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email }),
    },
  );

  if (response.ok) {
    const success = await response.json();

    return { success, error: false };
  }
  const errorData = await response.json();

  return { error: errorData.message, success: false };
};

// export const updatePassword = async ({
//   token,
//   password,
//   newPassword,
// }: {
//   token: string;
//   password: string;
//   newPassword: string;
// }) => {
//   const passwordResponse = await fetch(
//     `${process.env.NEXT_PUBLIC_WP_ALVAR_API}/password`,
//     {
//       method: 'POST',
//       headers: {
//         'Content-Type': 'application/json',
//         Authorization: `Bearer ${token}`,
//       },
//       body: JSON.stringify({ password, new_password: newPassword }),
//     },
//   );

//   if (passwordResponse.ok) {
//     return true;
//   }

//   return null;
// };

interface OptInPayload extends WPDogPost {
  email: string;
  shipping_country: ShippingCountry;
}

export const optInForMarketing = async (
  payload: OptInPayload,
): Promise<boolean> => {
  try {
    const response = await fetch(
      `${process.env.NEXT_PUBLIC_WP_ALVAR_API}/recommendation-opt-in`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      },
    );

    if (response.ok) {
      const responseData = await response.json();
      return responseData === 'OK';
    } else {
      return false;
    }
  } catch (e) {
    reportError(e);
    return false;
  }
};
