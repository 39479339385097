import { reportError } from './bugsnag';

interface FetcherOptions {
  token?: string;
  headers?: Record<string, string>;
}

export const fetcher = (
  url: string,
  { headers, token: givenToken }: FetcherOptions = { headers: {} },
  noAuth = false,
) => {
  const token = noAuth ? '' : givenToken;

  return fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      ...headers,
      ...(noAuth
        ? null
        : {
            Authorization: `Bearer ${token}`,
          }),
    },
  })
    .then((response) => response.json())
    .catch((err) => {
      /* do nothing for now. */
      console.error('Failed to fetch: ', err);
      return { error: 'Failed to fetch', description: err.message };
    });
};

export const noAuthFetcher = <T extends unknown>(
  url: string,
  options?: FetcherOptions,
): Promise<T> => fetcher(url, options, true);

export default fetcher;
