import { DietItem, RecommendationProduct } from './api.recommendation';
import {
  DisabledLegacyShippingCountry,
  Gender,
  PaymentMethod,
  ShippingCountry,
  SiteLocale,
} from './enums';

export interface TokenData {
  token: string;
  token_expires: number;
  user_id: string;
  user_display_name: string;
  user_email: string;
  user_nicename: string;
  one_time?: boolean;
}

export type ShippingMethod = 'home' | 'post' | null;

export type TransportName =
  | 'bring'
  | 'budbee'
  | 'instabox'
  | 'matkahuolto'
  | 'posti'
  | 'postnord'
  | null;

export type DeliveryTypeCode =
  | '101'
  | '102'
  | '201'
  | '202'
  | '301'
  | '302'
  | '501'
  | '502'
  | '601'
  | '602'
  | '701'
  | '702'
  | '801'
  | '802'
  | undefined;

export interface ShippingProps {
  shipping_method?: ShippingMethod;
  shipping_first_name?: null | string;
  shipping_last_name?: null | string;
  shipping_address_1?: null | string;
  shipping_city?: null | string;
  shipping_postcode?: null | string;
  shipping_country?: ShippingCountry | null;
  delivery_drop_point?: null | string;
  shipment_transport_name?: TransportName;
}

export interface AccountShipping {
  address_1?: null | string;
  city?: null | string;
  country?: null | ShippingCountry | DisabledLegacyShippingCountry;
  delivery_drop_point_id?: null | string;
  delivery_method?: ShippingMethod;
  delivery_name?: string;
  delivery_type?: DeliveryTypeCode;
  first_name?: null | string;
  last_name?: null | string;
  postcode?: null | string;
}

export type AccountBilling = {
  billing_method?: null | PaymentMethod;
  billing_first_name?: null | string;
  billing_last_name?: null | string;
  address_1?: null | string;
  city?: null | string;
  postcode?: null | string;
  country?: null | ShippingCountry;
};

export interface PaymentProps {
  billing_details?: {
    name: string | null;
  };
  card?: {
    brand: string | 'visa' | 'mastercard';
    country: string;
    exp_month: number;
    exp_year: number;
    last4: string;
  };
  created?: number;
  customer?: string;
  loading?: boolean;
}

export type OrderStatus =
  | 'pending'
  | 'processing'
  | 'completed'
  | 'refunded'
  | 'failed'
  | 'returned';

export interface HistoryEntry {
  dog_id: string;
  dog_name: string;
  order_total: string;
  item_count: number;
  date_created: string;
  status: OrderStatus;
  receipt_url: string;
  order_id: number;
  payment_url: string;
  coupon_codes: string[];
  items: {
    hidden: '1' | '0';
    product_id: number;
    quantity: number;
    recipe_item: boolean;
  }[];
}

export type BreederStatus = false | 'non_verified_breeder' | 'verified_breeder';
export interface AccountProps {
  billing?: AccountBilling;
  shipping?: AccountShipping;
  history?: {
    [order_id: number]: HistoryEntry;
  };
  reward: {
    saldo: number;
    code: string;
  } | null;
  phone?: string;
  email?: string;
  first_name?: string;
  last_name?: string;
  loading?: boolean;
  has_subscribed?: boolean;
  errors?: string[];
  locale: SiteLocale | '';
  breeder: BreederStatus;
  kennel_name?: string;
}

export type AccountInfoType = 'shipping' | 'billing' | 'user';

export interface Dog {
  activity_level: string;
  birthday: string;
  breed: string;
  dog_id: number;
  body_condition?: number;
  gender: Gender;
  name: string;
  recipe?: string;
  product_id?: number;
  special_needs: string[];
  preferred_ingredients: string[];
  weight: string;
  gram_need?: string;
  dietShareDry: number;
  dietShareWet: number;
  dietShareTreats: number;
  dietShareOther: number;
}
export interface WPDogPost {
  activity_level: string;
  birthday: string;
  breed: string;
  dog_id: number;
  body_condition?: string;
  body_type?: string;
  gender: Gender;
  name: string;
  recipe?: string;
  product_id?: number;
  special?: string[];
  special_needs?: string[];
  preferred_ingredients?: string;
  weight: string;
  gram_need?: string;
  diet_share_dry?: string | number;
  diet_share_wet?: string | number;
  diet_share_treats?: string | number;
  diet_share_other?: string | number;
  initial_recommendation?: string;
  initial_recommendation_id?: number;
}

interface WPDiscountFields {
  discounted_price?: false | string;
  discount?: false | string;
}

interface WPPriceFields extends WPDiscountFields {
  total_price: string;
  shipping_total: string;
  subtotal_price: string;
  discounted_subtotal_price: string;
  fees_total: string;
}

export interface WpProduct extends WPDiscountFields {
  product_name?: string;
  product_id: number;
  product_permalink?: string;
  quantity?: number;
  one_off_item?: boolean;
  recipe_item?: boolean;
  unit?: string;
  unitPrice?: number | string;
  unsaved?: true;
  removedProductPriceFetched?: boolean;
  campaignProduct?: true | 'trial_incentive';
  oneOff?: boolean;
}

export type SubscriptionStatus = 'cancelled' | 'on-hold' | 'active' | 'trial';

export interface Subscription extends WPPriceFields {
  dog_name: string;
  dog_id: number;
  gram_need?: number /* this is only for updating the dog's gram_need, the value is returned in dog */;
  id: number;
  trial?: boolean;
  interval_weeks: string;
  next_payment_date: string | false;
  items: WpProduct[];
  subscription_status: SubscriptionStatus;
  latest_order_status:
    | 'processing'
    | 'on-hold'
    | 'completed'
    | 'cancelled'
    | 'refunded'
    | 'failed'
    | 'returned'
    | 'pending';
  needs_payment: boolean;
  parent_order_id: number | false;
  coupon_codes?: string[];
  cancelled_date: string | false;
}

export interface PricesData extends WPPriceFields {
  items: WpProduct[];
}

export interface Delivery {
  delivery_drop_point_id?: null | string;
  delivery_method?: ShippingMethod;
  delivery_name?: string;
  delivery_type?: DeliveryTypeCode;
}

export enum CampaignLocation {
  checkout = 'recommendation-checkout',
  home = 'homepage',
}
export interface CampaignSettings {
  location: CampaignLocation;
}

export interface Coupon {
  user_id: number;
  code: string;
  amount: string;
  discount_type:
    | 'percent'
    | 'recurring_percent'
    | 'fixed_cart'
    | 'fixed_product'
    | 'recurring_fee'; // recurring product (fixed)
  product_ids: number[];
  excluded_product_ids: number[];
  excluded_product_categories: number[];
  usage_count: number;
  active_for_payments_limit: number;
  limit_usage_to_x_items?: number;
  individual_use: boolean;
  free_shipping: boolean;
  usesLeft?: number;
  parentOrderCoupon?: boolean;
  product_categories: number[];
  hide?: boolean | string;
  description?: {
    en?: string;
    fi?: string;
    sv?: string;
    da?: string;
  };
}

type CampaignFilters = 'puppiesOnly';

export enum CampaignType {
  AddOns = 'addons',
  Generic = 'generic',
  PuppyBox = 'puppyBox',
  Samples = 'samples',
  Referral = 'referral',
  ProductLaunch = 'productLaunch',
}

interface ImageObject {
  src: string;
  alt?: string;
  title?: string;
}

export interface Campaign {
  couponCode: string;
  filter?: CampaignFilters;
  id: string;
  image: string;
  location: CampaignLocation;
  tags: string[];
  type: CampaignType;
  priority: string;
}

export interface Reward {
  id: string;
  step: number;
  available: boolean;
  name: string;
  coupon_codes: string[];
  used?: boolean;
  dateUsed?: string;
  image?: string;
}

export interface RewardProps {
  loading: boolean;
  user_reward_step?: number;
  dog_rewards?: {
    [dog_id: number]: {
      dog_reward_step: number;
      rewards: Reward[];
    };
  };
}

export type WpLocale =
  | 'fi'
  | 'en_US'
  | 'de_DE'
  | 'da_DK'
  | 'nb_NO'
  | 'sv_SE'
  | '';

interface InterpolateArgs {
  [key: string]: string | number;
}

export type Translate = (
  key: string,
  args?: InterpolateArgs,
  options?: { fallback?: string | null; optional?: true },
) => string;

export type TranslateHTML = (
  key: string,
  args?: InterpolateArgs,
  options?: { fallback?: string | null; optional?: true },
) => React.ReactNode;

export interface OpeningTimes {
  fromDayIndex: number;
  toDayIndex?: number;
  times: string;
}

export interface ExtendedProduct extends RecommendationProduct {
  isFreebie?: boolean;
  tags?: Array<'senior' | 'puppy' | 'grain-free'>;
  kibbleSize?: string;
}

export interface Discount {
  total: number;
  amount: number;
  usesLeft: number;
}

type CartItemFields = {
  quantity: number;
  discount?: Discount;
  price: number;
  subscriptionItems?: ExtendedProduct[];
  quantityLimitPerOrder?: number;
  minimumQuantityPerOrder?: number;
};

export type CartItem =
  | (ExtendedProduct & CartItemFields)
  | (DietItem & CartItemFields);

export type { PostiLocation, PostiLocale } from './api.posti';
export type { BudbeeProps } from './api.budbee';
export * from './enums';
export * from './api.recommendation';
