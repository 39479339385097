import styled from 'styled-components';
import baseTheme, { ThemeProps } from '../lib/theme';
import Heading from './Heading';
import checkIconUrl from '../public/icons/check.svg';
import { visuallyHidden } from './AccessibilityText';

interface AlignmentProps {
  align?:
    | 'left'
    | 'right'
    | 'center'
    | 'justify'
    | 'justify-all'
    | 'start'
    | 'end'
    | 'match-parent';
}

export const SmallText = styled.span`
  font-size: 0.75rem;
  font-weight: 500;
  line-height: 1.25;
  color: ${(p) => p.theme.colors.primaryColor};
`;

const clickArea = '1rem';
const getRect = (area: string) =>
  ['left', 'right', 'top', 'bottom', ''].join(`:${area};`);

export const SmallLink = styled.span`
  font-size: 0.75rem;
  font-weight: 500;
  line-height: 1.25;
  color: ${(p) => p.theme.colors.editLink.color};
  position: relative;

  &:before {
    /* Create click area like this, so it doesn't disturb other elements. Also click areas are important. 44px should me minimum */
    content: '';
    position: absolute;
    ${getRect(`-${clickArea}`)}
  }

  &:hover,
  &:focus {
    text-decoration: underline;
  }
`;

export const Subtitle = styled.p(
  ({
    alignCenter = '',
  }: {
    alignCenter?: string | boolean;
    theme: ThemeProps['theme'];
    children: React.ReactNode;
  }) => `
  font-size: 87.5%;
  font-weight: 600;
  margin-bottom: 0.125em;
  ${alignCenter && 'text-align: center;'}
`,
);

interface ListProps {
  title?: string;
  styleLi?: boolean;
  children: React.ReactNode | React.ReactNode[];
  inset?: boolean;
  checklist?: boolean;
}

const listStyling = `
  &:not(:last-child) {
   margin-bottom: 2rem;
}`;

const liStyling = `& li {
  text-align: left;
  &:not(:last-child){
  margin-bottom: 0.5rem}
}`;

const checklistStyling = `
  display: flex;

  :before {
    content: '';
    background: transparent url('${checkIconUrl}') 50% / contain no-repeat;
    height: 16px;
    width: 16px;
    margin: 2px 0.5em;
    flex: 0 0.2 auto;
  }
`;

export const StyledUl = styled.ul(
  ({
    styleLi = false,
    inset = false,
  }: Pick<ListProps, 'styleLi' | 'inset'>) => `
   ${styleLi ? 'list-style: outside; margin-inline-start: 2rem;' : ''}
   ${inset ? 'margin-inline-start: 1.1rem;' : ''}
   ${listStyling}
   ${liStyling}
  `,
);

export const StyledOl = styled.ol(
  ({
    styleLi = false,
    inset = false,
    checklist = false,
  }: Pick<ListProps, 'styleLi' | 'inset' | 'checklist'>) => `
  text-align: start;
  & li {
    ${styleLi ? 'list-style-type: outside; margin-inline-start: 2rem;' : ''}
    ${inset ? 'margin-inline-start: 1.1rem;' : ''}
    ${checklist ? checklistStyling : ''}
  }
  ${listStyling}
  ${liStyling}
  `,
);

export const UnorderedList = ({
  title,
  styleLi = false,
  inset = false,
  children,
}: ListProps) => (
  <>
    {title && <Heading headingSize={4}>{title}</Heading>}
    <StyledUl {...{ styleLi, inset }}>
      {children && Array.isArray(children)
        ? children.map((c) => (
            <li key={`li-${Math.random().toString()}`}>{c}</li>
          ))
        : children}
    </StyledUl>
  </>
);

export const OrderedList = ({
  title,
  styleLi = false,
  inset = false,
  children,
  checklist = false,
}: ListProps) => (
  <>
    {title && <Heading headingSize={4}>{title}</Heading>}
    <StyledOl {...{ styleLi, inset, checklist }}>
      {children && Array.isArray(children)
        ? children.map((c, i) => <li key={i}>{c}</li>)
        : children}
    </StyledOl>
  </>
);

export const StyledLi = styled.li``;

type WhiteSpaceOptions =
  | 'normal'
  | 'nowrap'
  | 'pre'
  | 'pre-wrap'
  | 'pre-line'
  | 'break-spaces';

export const textColors = {
  green: baseTheme.colors.highlightColor,
  white: baseTheme.colors.white,
  red: baseTheme.colors.status.warning,
  link: baseTheme.colors.link.color,
  default: 'inherit',
};

export interface TextProps extends AlignmentProps {
  ignoreMargin?: boolean;
  maxWidth?: string;
  whiteSpace?: WhiteSpaceOptions;
  transform?: 'none' | 'lowercase' | 'capitalize' | 'uppercase';
  emphasis?: boolean;
  underline?: boolean;
  color?: keyof typeof textColors;
  margin?: string;
  size?: string;
  fontStyle?: 'italic' | 'normal';
  display?: 'block';
  lineHeight?: number;
}

export const Text = styled.p<ThemeProps & TextProps>(
  ({
    theme,
    align = 'match-parent',
    ignoreMargin = false,
    maxWidth,
    whiteSpace = 'normal',
    transform = 'none',
    emphasis = false,
    underline = false,
    color = 'default',
    margin,
    size,
    lineHeight,
    fontStyle = 'normal',
    display,
  }: ThemeProps & TextProps) => `
    ${emphasis ? `${theme.fontPresets.paragraphEmphasis};` : ''}
    ${size ? `font-size: ${size};` : ''}
    text-align: ${align};
    max-width: ${maxWidth || 'inherit'};
    white-space: ${whiteSpace};
    text-transform: ${transform};
    color: ${textColors[color]};
    ${underline ? 'text-decoration: underline;' : ''} 
    ${margin ? `margin: ${margin};` : ''}
    ${display ? `display: ${display};` : ''}
    ${lineHeight ? `line-height: ${lineHeight};` : ''}
    font-style: ${fontStyle};

    &:not(:last-child) {
      ${margin ? '' : `margin-bottom: ${ignoreMargin ? '0' : '1rem'};`}
    }

    & a {
      color: ${theme.colors.link.color};
      text-decoration: underline !important;
    }

    .drop-letter {
      margin-bottom: 0.5rem;

      &:first-letter {
        initial-letter: 1.6;
        margin: 4px 0;
      }
    }
`,
);

export const Small = styled(Text).attrs({ as: 'small' })(
  ({ theme, emphasis = false }: ThemeProps & TextProps) => `
    ${theme.fontPresets.small};
    font-weight: ${emphasis ? '600' : '300'};
    line-height: 1;
  `,
);

interface SkeletonStringProps {
  length: number | string;
  height?: number;
  margin?: string;
}

export const SkeletonString = styled.p<SkeletonStringProps>(
  ({ length, height, margin }) => `
  width: ${typeof length === 'string' ? length : `${length}ch`};
  ${height ? `height: ${height}rem;` : ''}
  margin:${margin ? margin : (height || 1) / 8}rem 0;
  position: relative;
  background: #f1f1f1;
  background: #0000001a;
  color: #f1f1f1;
  color: transparent;
  overflow: hidden;

  &:before {
    content: '';
    width: 40px;
    height: calc(100% + 2rem);
    position: absolute;
    top: -1rem;
    right: 120%;
    background: linear-gradient(90deg, rgba(255, 255, 255, 0) 2%, rgba(255, 255, 255, 0.3) 50%, rgba(255, 255, 255, 0) 98%);
    transform: rotate(30deg);
    animation: 1.8s loading ease-in-out infinite forwards;
  }

  @keyframes loading {
    to {
      right: -120%;
    }
  }
`,
);

interface LoadableTextProps extends TextProps {
  loading: boolean;
  children?: React.ReactNode;
}

export const LoadableText = ({
  loading,
  length = 16,
  height = 1.4,
  ...rest
}: LoadableTextProps & Partial<SkeletonStringProps>) =>
  loading ? (
    <SkeletonString length={length} height={height} margin={rest.margin} />
  ) : (
    <Text {...rest} />
  );

export const LabelText = styled(Small)<{
  isRequired?: boolean;
  isReadOnly?: boolean;
  hide?: boolean;
}>(
  ({ isRequired, isReadOnly, hide }) => `
  margin: 0 0 .5em;
  
  ${
    isRequired && !isReadOnly
      ? `
    &:after {
      content: ' *';
    }
    `
      : ''
  }
    ${hide ? visuallyHidden : ''};
`,
);

export const Legend = styled(LabelText).attrs({ as: 'legend' })``;

interface LabelProps {
  hasError?: boolean;
}

export const Label = styled.label<ThemeProps & LabelProps>(
  ({ hasError = false, theme: { colors, transitionTime } }) => `
  display: flex;
  flex-direction: column;
  margin: 0;
  margin-bottom: 2em;
  transition: margin-bottom ease-in ${transitionTime.veryFast};
  position: relative;

  ${
    hasError
      ? `
    > * {
      border-color: ${colors.status.error} !important;
    }
  `
      : ''
  }
`,
);
