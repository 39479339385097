import styled from 'styled-components';
import baseTheme from '../lib/theme';
import { visuallyHidden } from './AccessibilityText';
import { TextProps, textColors } from './Typography';

const fontStyles: { [key: number]: string } = {
  0: baseTheme.fontPresets.headingXl,
  1: baseTheme.fontPresets.heading1,
  2: baseTheme.fontPresets.heading2,
  3: baseTheme.fontPresets.heading3,
  4: baseTheme.fontPresets.heading4,
};

interface HeadingProps {
  headingSize?: number;
  align?:
    | 'left'
    | 'right'
    | 'center'
    | 'justify'
    | 'justify-all'
    | 'start'
    | 'end'
    | 'match-parent';
  section?: boolean;
  ignoreMargin?: boolean;
  hidden?: boolean;
  tiered?: boolean;
  color?: TextProps['color'];
  margin?: string;
}

const optionalStyles: Record<string, string> = {
  section: 'padding-top: 2rem;',
  ignoreMargin: `&:not(:last-child) {
    margin-bottom: 0;
  }`,
  hidden: visuallyHidden,
  onClick: `&:hover{
    ${baseTheme.stylingPresets.pointerCursor}};
  `,
  tiered: `
  ${baseTheme.fontPresets.heading4};
  line-height: 100%;
  * > b {
    ${baseTheme.fontPresets.heading2};
    line-height: 100%;
    display: block;
  }
  `,
};

export default styled.h2.withConfig({
  shouldForwardProp: (p) =>
    ![
      'headingSize',
      'align',
      'section',
      'ignoreMargin',
      'hidden',
      'tiered',
      'color',
    ].includes(p),
})<HeadingProps>(
  ({ color = 'default', margin, ...props }) => `
  ${fontStyles[props.headingSize || 2]};
  color: ${textColors[color]};
  text-align: ${props.align || ' left'};
  word-break: break-word;
  margin-top: 0;
  
  &:not(:last-child) {
    margin-bottom: 1rem;
  }
  
  ${margin ? `margin: ${margin};` : ''}
  
  ${Object.keys(props)
    .map((p) => optionalStyles[p])
    .join('')}
  `,
);
