import {
  DeliveryResponse,
  DeliveryRequestParams,
} from '../../pages/api/delivery.types';

export const getDeliveryDropPoints = async ({
  postcode,
  country,
  city,
  address1,
  transportName,
  locale,
}: DeliveryRequestParams): Promise<DeliveryResponse> => {
  const params: Record<string, string> = {
    country,
    postcode: postcode.toString(),
    city,
    transportName: transportName || '',
  };
  if (address1) {
    params.address_1 = address1;
  }
  if (locale) {
    params.locale = locale;
  }

  const res = await fetch(
    `/api/delivery?${new URLSearchParams(params).toString()}`,
  );
  const data = await res.json();
  return data;
};
