// eslint-disable-next-line import/prefer-default-export
export const getLocation = async () =>
  await fetch(`https://extreme-ip-lookup.com/json/?key=jVM2BdIOFo4nMNALRBgw`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then(async (r) => (r.ok ? await r.json() : null))
    .catch((e) => {
      // This occasionally fails or sends broken json, which isn't really a problem, so no need to report, just return null
      return null;
    });
