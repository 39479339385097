import React, { Fragment } from 'react';
import Bugsnag, { NotifiableError } from '@bugsnag/js';
import BugsnagPluginReact, {
  BugsnagErrorBoundary,
} from '@bugsnag/plugin-react';
import getConfig from 'next/config';

interface ClassError {
  errorClass: string;
  errorMessage: string;
}

const serverKey =
  getConfig()?.serverRuntimeConfig?.BUGSNAG_API_KEY ||
  process.env.BUGSNAG_SERVER_KEY;

const browserKey =
  getConfig()?.publicRuntimeConfig?.BUGSNAG_API_KEY ||
  process.env.NEXT_PUBLIC_BUGSNAG_BROWSER_KEY;

const apiKey = serverKey || browserKey;

let Boundary: BugsnagErrorBoundary | typeof Fragment = Fragment;

if (apiKey && !(Bugsnag as unknown as { _client?: any })._client) {
  Bugsnag.start({
    apiKey,
    plugins: [new BugsnagPluginReact()],
  });

  try {
    Boundary = Bugsnag.getPlugin('react')!.createErrorBoundary(React);
  } catch {
    Boundary = Fragment;
  }
}

export default Bugsnag;

export const ErrorBoundary = Boundary;

export const reportError = (error: unknown): void => {
  const canBeIgnored =
    typeof error === 'string' &&
    error.includes('routeChange aborted. This can be safely ignored.');

  const isNotifiableError =
    ((error as ClassError).errorClass && (error as ClassError).errorMessage) ||
    ((error as Error).name && (error as Error).message) ||
    typeof error === 'string';

  if (isNotifiableError && !canBeIgnored) {
    Bugsnag.notify(error as NotifiableError);
  }
};
