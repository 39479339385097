import '../styles/fonts.css';
import '../styles/reset.css';
import '../styles/globals.css';

import Head from 'next/head';
import { AppProps } from 'next/app';

import Providers from '@components/Providers';
import { useLocale } from '@lib/hooks';
import { getStaticTranslation } from '@lib/translations';

export default function AlvarCustomerApp(props: AppProps) {
  const locale = useLocale();
  const t = (key: string) => getStaticTranslation(locale, key, {});

  const pageTitle =
    !!props.pageProps.title && t(`page_title.${props.pageProps.title}`);

  const shouldIndexPage = ['/'].includes(props.router.route);

  return (
    <>
      <Head>
        <meta name="viewport" content="initial-scale=1.0, width=device-width" />
        <meta
          name="robots"
          content={shouldIndexPage ? 'index, follow' : 'noindex, follow'}
        />
        {pageTitle ? <title>{`${pageTitle} | Alvar Pet`}</title> : null}
        {props.pageProps?.metadata
          ? Object.entries(props.pageProps.metadata).map(([meta, value]) => (
              <meta
                name={meta}
                content={t(value as string)}
                key={`meta-key-${meta}`}
              />
            ))
          : null}
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon-16x16.png"
        />
        <link rel="manifest" href="/site.webmanifest" />
        <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#333333" />
        <meta name="theme-color" content="#ffffff" />
      </Head>
      <Providers {...props} />
    </>
  );
}
