import Link from 'next/link';
import Image from 'next/image';

import { EXTERNAL_LINKS, STATIC_TRANSLATIONS } from 'app/constants';
import { SiteLocale } from '@types';
import styles from './contact-channels.module.css';

interface Props {
  locale: SiteLocale;
}

const getChannels = (locale: SiteLocale) => [
  {
    icon: '/icons/envelope.svg',
    href: EXTERNAL_LINKS.CONTACT[locale],
    translation: STATIC_TRANSLATIONS[locale].contact_info.take_contact,
  },
  {
    icon: '/icons/instagram.svg',
    href: EXTERNAL_LINKS.INSTAGRAM[locale],
    translation: STATIC_TRANSLATIONS[locale].channel.instagram,
  },
  {
    icon: '/icons/facebook.svg',
    href: EXTERNAL_LINKS.FACEBOOK[locale],
    translation: STATIC_TRANSLATIONS[locale].channel.facebook,
  },
];

const ContactChannels = ({ locale }: Props) => {
  return (
    <div className={styles.wrapper}>
      {getChannels(locale).map(({ icon, translation, href }) => (
        <Link key={href} href={href} className={styles.channel}>
          <Image src={icon} width={32} height={32} alt={translation} />
          {translation}
        </Link>
      ))}
    </div>
  );
};

export default ContactChannels;
