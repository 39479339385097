import { ExtendedProduct, ProductType } from '../types';

const fiSamplePrice = 2;
const seSamplePrice = 25;
const dkSamplePrice = 20;

const sampleVariant = { size: 0.2, unit: 'kg' };

const sampleBag = {
  type: ProductType.CouponOnly,
  quantityLimitPerOrder: 1,
  isFreebie: false,
  oneOff: true,
  id: 'kibble_sample',
};

const totebag = {
  title: 'puppy_box.item.tote_bag',
  shortTitle: 'puppy_box.item.tote_bag',
  type: ProductType.CouponOnly,
  isFreebie: true,
  oneOff: true,
  variants: [],
};

const bugSample = {
  ...sampleBag,
  title: 'product.bug_sample',
  shortTitle: 'product.bug_sample',
  tags: ['product_tag.grain_free'],
  kibbleSize: 1.5,
};

const sereneSample = {
  ...sampleBag,
  title: 'product.serene_sample',
  shortTitle: 'product.serene_sample',
  tags: ['product_tag.puppy'],
  kibbleSize: 1.0,
};

const slenderSample = {
  ...sampleBag,
  title: 'product.slender_sample',
  shortTitle: 'product.slender_sample',
  tags: ['product_tag.grain_free', 'product_tag.senior'],
  kibbleSize: 2.0,
};

const zippySample = {
  ...sampleBag,
  title: 'product.zippy_sample',
  shortTitle: 'product.zippy_sample',
  tags: ['product_tag.grain_free', 'product_tag.puppy'],
  kibbleSize: 0.5,
};

const rootSample = {
  ...sampleBag,
  title: 'product.root_sample',
  shortTitle: 'product.root_sample',
  tags: [],
  kibbleSize: 1.2,
};

const steadySample = {
  ...sampleBag,
  title: 'product.steady_sample',
  shortTitle: 'product.steady_sample',
  tags: ['product_tag.grain_free', 'product_tag.puppy'],
  kibbleSize: 1.5,
};

const clearanceFishAndChips = {
  type: ProductType.Treat,
  quantityLimitPerOrder: 10,
  isFreebie: false,
  oneOff: true,
  id: 'ALV-203',
  title: 'product.german_fish_and_chips.long',
  shortTitle: 'product.german_fish_and_chips.short',
};

const clearanceNuggets = {
  type: ProductType.Treat,
  quantityLimitPerOrder: 10,
  isFreebie: false,
  oneOff: true,
  id: 'ALV-202',
  title: 'product.clearance_nuggets.long',
  shortTitle: 'product.clearance_nuggets.short',
};

const clearanceFishOil = {
  type: ProductType.Supplement,
  quantityLimitPerOrder: 1,
  isFreebie: false,
  oneOff: true,
  id: 'ALV-501',
  title: 'product.clearance_fish_oil.long',
  shortTitle: 'product.clearance_fish_oil.short',
};

const finnishProducts = [
  { ...totebag, id: 'ALV-801-FI', wordpressId: 329732 },
  {
    ...bugSample,
    id: 'ALV-110-FI-Sample',
    wordpressId: 436596,
    variants: [{ ...sampleVariant, wordpressId: 436596 }],
    price: fiSamplePrice,
  },
  {
    ...sereneSample,
    id: 'ALV-111-FI-100G',
    wordpressId: 372166,
    price: fiSamplePrice,
    variants: [{ ...sampleVariant, wordpressId: 372166 }],
  },
  {
    ...slenderSample,
    id: 'ALV-109-FI-100G',
    wordpressId: 371794,
    price: fiSamplePrice,
    variants: [{ ...sampleVariant, wordpressId: 371794 }],
  },
  {
    ...zippySample,
    id: 'ALV-107-FI-Sample',
    wordpressId: 436606,
    price: 0,
    variants: [{ ...sampleVariant, wordpressId: 436606 }],
  },
  {
    ...rootSample,
    id: 'ALV-104-FI-Sample',
    wordpressId: 436608,
    price: fiSamplePrice,
    variants: [{ ...sampleVariant, wordpressId: 436608 }],
  },
  {
    ...steadySample,
    id: 'ALV-108-FI-Sample',
    wordpressId: 436613,
    price: fiSamplePrice,
    variants: [{ ...sampleVariant, wordpressId: 436613 }],
  },
  {
    ...clearanceFishAndChips,
    wordpressId: 459798,
    variants: [{ size: 0.12, unit: 'kg', wordpressId: 459798 }],
    price: 1.9,
  },
  {
    ...clearanceNuggets,
    wordpressId: 459185,
    variants: [{ size: 0.12, unit: 'kg', wordpressId: 459185 }],
    price: 1.9,
  },
  {
    ...clearanceFishOil,
    wordpressId: 493895,
    variants: [{ size: 0.1, unit: 'l', wordpressId: 493895 }],
    price: 1.9,
  },
];

const swedishProducts = [
  {
    ...zippySample,
    id: 'ALV-107-SE-Sample',
    wordpressId: 436619,
    price: 0,
    variants: [{ ...sampleVariant, wordpressId: 436619 }],
  },
  {
    ...rootSample,
    id: 'ALV-104-SE-Sample',
    wordpressId: 436615,
    price: seSamplePrice,
    variants: [{ ...sampleVariant, wordpressId: 436615 }],
  },
  {
    ...steadySample,
    id: 'ALV-108-SE-Sample',
    wordpressId: 436626,
    price: seSamplePrice,
    variants: [{ ...sampleVariant, wordpressId: 436626 }],
  },
  {
    ...bugSample,
    id: 'ALV-110-SE-Sample',
    wordpressId: 436629,
    price: seSamplePrice,
    variants: [{ ...sampleVariant, wordpressId: 436629 }],
  },
  {
    ...clearanceFishAndChips,
    wordpressId: 459803,
    variants: [{ size: 0.12, unit: 'kg', wordpressId: 459803 }],
    price: 22.5,
  },
  {
    ...clearanceNuggets,
    wordpressId: 427441,
    variants: [{ size: 0.12, unit: 'kg', wordpressId: 429996 }],
    price: 22.5,
  },
  {
    ...clearanceNuggets,
    wordpressId: 461438,
    variants: [{ size: 0.12, unit: 'kg', wordpressId: 461438 }],
    price: 22.5,
  },
];

const danishProducts = [
  {
    ...zippySample,
    id: 'ALV-107-SE-Sample',
    wordpressId: 436624,
    price: 0,
    variants: [{ ...sampleVariant, wordpressId: 436624 }],
  },
  {
    ...rootSample,
    id: 'ALV-104-SE-Sample',
    wordpressId: 436616,
    price: dkSamplePrice,
    variants: [{ ...sampleVariant, wordpressId: 436616 }],
  },
  {
    ...steadySample,
    id: 'ALV-108-SE-Sample',
    wordpressId: 436627,
    price: dkSamplePrice,
    variants: [{ ...sampleVariant, wordpressId: 436627 }],
  },
  {
    ...bugSample,
    id: 'ALV-110-SE-Sample',
    wordpressId: 436631,
    price: dkSamplePrice,
    variants: [{ ...sampleVariant, wordpressId: 436631 }],
  },
  {
    ...clearanceFishAndChips,
    wordpressId: 459804,
    variants: [{ size: 0.12, unit: 'kg', wordpressId: 459804 }],
    price: 17.5,
  },
  {
    ...clearanceNuggets,
    wordpressId: 427449,
    variants: [{ size: 0.12, unit: 'kg', wordpressId: 429995 }],
    price: 17.5,
  },
  {
    ...clearanceNuggets,
    wordpressId: 461440,
    variants: [{ size: 0.12, unit: 'kg', wordpressId: 461440 }],
    price: 17.5,
  },
];

export default [
  ...finnishProducts.map((p) => ({ ...p, wcCategories: [] })),
  ...swedishProducts.map((p) => ({ ...p, wcCategories: [] })),
  ...danishProducts.map((p) => ({ ...p, wcCategories: [] })),
] as unknown as ExtendedProduct[];
