import styled from 'styled-components';

interface Props {
  inherit?: boolean;
  noFlow?: boolean;
  section?: boolean;
}

const Container = styled.div.withConfig({
  shouldForwardProp: (prop) =>
    !['inherit', 'noFlow', 'section', 'maxWidth'].includes(prop),
})<Props>(
  ({ theme: { spacing }, noFlow, inherit, section }) => `
padding-left: ${spacing()};
padding-right: ${spacing()};
padding: ${section ? spacing() : `inherit ${spacing()}`};

${
  noFlow
    ? ''
    : `
    &:not(:only-child) {
      margin-bottom: ${spacing(0.5)};
    }
  `
}

${
  inherit
    ? `
    display: inherit;
    justify-content: inherit;
    justify-items: inherit;
    align-items: inherit;
  `
    : ''
}
`,
);

export default Container;
