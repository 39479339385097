import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { getStorage, getStorageKey } from './utils.persist';

export function usePersistedState<T>(
  givenKey: string,
  defaultValue: T,
): [T, Dispatch<SetStateAction<T>>] {
  const key = getStorageKey(givenKey);
  const [state, setState] = useState<T>(
    (): T => {
      const item = getStorage().getItem(key);

      if (item) {
        if (item === 'undefined') {
          return defaultValue;
        }
        return JSON.parse(item);
      }
      return defaultValue;
    },
  );
  useEffect(() => {
    if (typeof state !== 'undefined' || getStorage().getItem(key) !== null) {
      getStorage().setItem(key, JSON.stringify(state));
    }
  }, [key, state]);
  return [state, setState];
}

export function readPersistedStoreValue(givenKey: string) {
  const key = getStorageKey(givenKey);
  const item = getStorage().getItem(key);
  if (item && item !== 'undefined') {
    return JSON.parse(item);
  }
  return undefined;
}

export default { usePersistedState };
