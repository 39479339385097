import {
  AccountProps,
  ShippingCountry,
  SiteLocale,
  Subscription,
} from '../types';
import { OptionProps } from '../components/Form/types';
import phonePrefixes from './phone-prefixes';

export const capitalize = (str: string): string =>
  `${str?.charAt(0).toUpperCase()}${str?.substr(1)}`;

export const camelCase = (str = ''): string => {
  const text = `${str}`.replace(/[-_\s.]+(.)?/g, (_, c) =>
    c ? c.toUpperCase() : '',
  );
  return text.substr(0, 1).toLowerCase() + text.substr(1);
};

export const slugify = (str: string): string =>
  `${(str || '').trim().replace(/\s/g, '-').toLowerCase()}`;

export const removeSpaces = (str: string): string =>
  `${(str || '').replace(/\s/g, '')}`;

export const basePath = (str: string): string => {
  if (!str) {
    return '';
  }
  const parts = str.split('/');
  if (parts[parts.length - 1] === '') {
    parts.pop();
  }
  parts.pop();
  if (!parts.filter(Boolean).length) {
    return '';
  }

  return parts.join('/');
};

export const removePTagsFromString = (string: string) =>
  string.replace('<p>', '').replace('</p>', '');

export const allowDataAttributes = (props?: {}): Record<string, unknown> =>
  props
    ? Object.keys(props).reduce(
        (acc: any, curr: string) => ({
          ...acc,
          ...(/^data-+\w/.test(curr)
            ? { [curr]: props[curr as keyof typeof props] }
            : null),
        }),
        {},
      )
    : null;

export const getCurrency = (shippingCountry: ShippingCountry): string => {
  switch (shippingCountry) {
    case ShippingCountry.DK:
      return 'DKK';
    case ShippingCountry.SE:
      return 'SEK';
    case ShippingCountry.FI:
      return 'EUR';
    default:
      return 'EUR';
  }
};

export const makeMoney = (
  float: number | string,
  shippingCountry: ShippingCountry,
  locale: SiteLocale,
): string => {
  if ((typeof float !== 'number' && !float) || Number.isNaN(float as number)) {
    return '';
  }
  return new Intl.NumberFormat(locale || SiteLocale.FI, {
    style: 'currency',
    currency: getCurrency(shippingCountry),
    maximumSignificantDigits: undefined,
  }).format(
    typeof float === 'number' ? float : parseFloat(float.replace(',', '.')),
  );
};

export const onCopyToClipboard = (text: string, onSuccess?: () => void) => {
  const textArea = document.createElement('textarea');
  textArea.value = text;

  // Avoid scrolling to bottom
  textArea.style.top = '0';
  textArea.style.left = '0';
  textArea.style.position = 'fixed';

  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();
  const successful = document.execCommand('copy');
  if (successful && onSuccess) {
    onSuccess();
  }

  document.body.removeChild(textArea);
};

export const makeNameOptions = (subs: Subscription[]): OptionProps =>
  subs.map(({ dog_name, dog_id }) => ({ value: dog_id, node: dog_name }));

export const makeSubscriptionNameOptions = (
  subs: Subscription[],
): OptionProps =>
  subs.map(({ id, dog_name }) => ({ value: id, node: dog_name }));

export const voidFn = () => {
  /* default */
};

export const getPaymentUrlWithSuccess = (
  currentPath: string,
  url?: string,
  successExtension?: string,
): string | undefined =>
  url &&
  `${url}${url.includes('?') ? '&' : '?'}success_url=${encodeURIComponent(
    `${process.env.NEXT_PUBLIC_SITE_URL}${currentPath}${
      currentPath.includes('?') ? '&' : '?'
    }wc_res=success${successExtension || ''}`,
  )}`;

export const getDefaultPhonePrefix = (country: ShippingCountry): string =>
  phonePrefixes.find(({ ISO }) => ISO === country)?.prefix || '+';

enum PaymentMethod {
  Klarna = 'klarna',
  Google = 'google-pay',
  Apple = 'apple-pay',
  PayPal = 'paypal',
  Visa = 'visa',
  Mastercard = 'mastercard',
  Amex = 'amex',
}

const allPaymentMethods = Object.values(PaymentMethod);

const excludePaymentMethods = (exclude: PaymentMethod[]): PaymentMethod[] =>
  allPaymentMethods.filter((pm) => !exclude.includes(pm));

export const getPaymentMethods = (country: ShippingCountry): PaymentMethod[] =>
  ({
    [ShippingCountry.DK]: excludePaymentMethods([PaymentMethod.Klarna]),
    [ShippingCountry.FI]: excludePaymentMethods([PaymentMethod.PayPal]),
    [ShippingCountry.SE]: allPaymentMethods,
  })[country];

const baseReferralUrl: Record<ShippingCountry, string> = {
  [ShippingCountry.SE]: `${process.env.NEXT_PUBLIC_WP_HOME}/sv/referrallank`,
  [ShippingCountry.DK]: `${process.env.NEXT_PUBLIC_WP_HOME}/da/henvisningslink`,
  [ShippingCountry.FI]: `${process.env.NEXT_PUBLIC_WP_HOME}/koirakaverin-suositus`,
};

export const getReferralLinkFromAccount = (account: AccountProps) =>
  `${baseReferralUrl[account.billing?.country || ShippingCountry.FI]}${
    account?.reward?.code ? `?alvarref=${account.reward.code}` : ''
  }`;

export const scrollTo = (
  props: ScrollToOptions & { behavior?: 'auto' | 'instant' | 'smooth' },
) => {
  return window?.scrollTo
    ? window.scrollTo({
        ...props,
        behavior: (props.behavior || 'auto') as ScrollBehavior,
      })
    : voidFn;
};

interface ImageSize {
  width: number;
  height: number;
}

export const getImageSize = ({
  width,
  height,
  ratio = 5 / 9,
}: Partial<ImageSize> & {
  ratio?: number;
}): ImageSize => ({
  width: width || (height ? height * ratio : 0),
  height: height || (width ? width * (1 / ratio) : 0),
});

export const safeSubtractFloats = (a: number, b: number, decimal = 2) =>
  (parseInt(parseFloat(`${a}`).toFixed(decimal).replace('.', '')) -
    parseInt(parseFloat(`${b}`).toFixed(decimal).replace('.', ''))) /
  100;
