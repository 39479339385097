import { ReactNode, useState } from 'react';
import { NextRouter, useRouter } from 'next/router';
import dynamic from 'next/dynamic';
import styled from 'styled-components';

import Link from '../Link';
import Image from '../Image';
import NavItem from './NavItem';
import MobileMenu from './MobileMenu';
import DesktopMenu from './DesktopMenu';
import LanguageSelector from './LanguageSelector';
import AccessibilityText from '@components/AccessibilityText';
import { ThemeProps } from '@lib/theme';
import { useIsLoggedIn } from '@lib/hooks';
import { useAccount, useLocale, useTranslation } from '@lib/hooks';
import links from 'constants/links';
import { SiteLocale } from '@types';
import LogoutButton from '@components/LogoutButton';

const NavConfirmationItem = dynamic(() => import('./NavConfirmationItem'));

const MenuTrigger = styled.button.attrs({
  type: 'button',
  'aria-controls': 'main-menu-container',
  id: 'main-menu-trigger',
})(
  ({ theme: { breakpoints } }) => `
  width: 1.5rem;
  height: 1.5rem;
  margin: auto;
  margin-left: 0;
  position: relative;

  &:after{
    content: "";
    width: 1.5rem;
    height: 1.5rem;
    background: url(/icons/menu.svg) center no-repeat;
    position: absolute;
    top: 0;
    left: 0;
  }

  &[aria-expanded='true'] {
    &:after {
      background: currentColor;
      mask: url(/icons/close.svg) center no-repeat;
    }
  }

  @media (min-width: ${breakpoints.md}) {
    display: none;
  }
`,
);

interface NavBarProps extends ThemeProps {
  isOpen: boolean;
  matchMarketingSite: boolean;
}
const NavBar = styled.nav(
  ({
    theme: { colors, constants, zIndex, breakpoints },
    matchMarketingSite,
  }: NavBarProps) => `
  width: 100%;
  height: ${constants.navbarHeight};
  padding: 0 1rem;
  position: fixed;
  color: ${colors.primaryColorInverted};
  background: ${
    matchMarketingSite ? colors.highlightColor : colors.backgroundColorInverted
  };
  z-index: ${zIndex.navBar};
  display: grid;
  grid-template-columns: repeat(3,1fr);
  
  & a {
    text-align: center;
  }
  
  @media only screen and (min-width: ${breakpoints.mdMax}) {
    display: flex;
    justify-content: space-between;
  }
  `,
);

const LangWrapper = styled.div(
  ({ theme: { breakpoints } }) => `
  display: none;
  position: relative;

  @media only screen and (min-width: ${breakpoints.mdMax}) {
    display: flex;
    align-self: flex-start;
    padding-top: 1.2rem;
    position: relative;
    top: 0;
  }
`,
);
interface NavItemProps {
  href: string;
  title: string;
  confirmation?: ReactNode;
  confirmText?: string;
  isCurrent?: (params: Pick<NextRouter, 'route' | 'query'>) => boolean;
  hide?: (
    params: Record<'isBreeder' | 'isSubscriber' | 'isLoggedIn', boolean>,
  ) => boolean;
}

const navItems = {
  public: (t: (s: string) => string, locale: SiteLocale): NavItemProps[] => [
    {
      href: '/recommendation?reset',
      title: t('nav.recommendation_machine'),
      confirmation: t('nav.recommendation_machine_confirmation'),
      confirmText: 'nav.recommendation_machine_restart',
      isCurrent: ({ route, query }) =>
        !!(
          (route.startsWith('/recommendation') && Object.keys(query).length) ||
          route.startsWith('/recommendation/')
        ),
    },
    {
      href: links.MARKETING_SITE[locale],
      title: t('nav.back_to_marketing_site'),
      isCurrent: () => false,
    },
  ],
  authenticated: (t: (s: string) => string): NavItemProps[] => [
    {
      href: '/',
      hide: ({ isSubscriber }) => !isSubscriber,
      title: t('nav.frontpage'),
    },
    {
      href: '/profile',
      title: t('nav.profile'),
    },
    {
      href: '/orders',
      title: t('nav.orders'),
      hide: ({ isSubscriber }) => !isSubscriber,
      isCurrent: ({ route }) =>
        route.startsWith('/orders') &&
        route.split('/').filter(Boolean).length < 3,
    },
    {
      href: '/my-herd',
      title: t('nav.my_herd'),
      isCurrent: ({ route }) =>
        route.startsWith('/my-herd') &&
        route.split('/').filter(Boolean).length < 3,
    },
    {
      href: '/sustainability',
      title: t('nav.my_sustainability'),
      hide: ({ isSubscriber }) => !isSubscriber,
    },
    {
      href: '/benefits',
      title: t('nav.benefits'),
      hide: ({ isSubscriber }) => !isSubscriber,
      isCurrent: ({ route }) =>
        route.startsWith('/benefits') &&
        route.split('/').filter(Boolean).length < 3,
    },
    {
      href: '/breeders-club',
      title: t('nav.breeders_club'),
      hide: ({ isBreeder, isSubscriber }) => !isSubscriber || !isBreeder,
      isCurrent: ({ route }) =>
        route.startsWith('/breeders-club') &&
        route.split('/').filter(Boolean).length < 3,
    },
    {
      href: '/recommendation?reset',
      title: t('nav.recommendation_machine'),
      confirmation: t('nav.recommendation_machine_confirmation'),
      confirmText: 'nav.recommendation_machine_restart',
      hide: ({ isSubscriber }) => isSubscriber,
      isCurrent: ({ route, query }) =>
        !!(
          (route.startsWith('/recommendation') && Object.keys(query).length) ||
          route.startsWith('/recommendation/')
        ),
    },
  ],
};

const Navigation = () => {
  const t = useTranslation();
  const locale = useLocale();
  const { account } = useAccount();
  const { route, query } = useRouter();
  const { isLoggedIn } = useIsLoggedIn();

  const [isOpen, toggleMenu] = useState(false);

  const currentSubRoute = route.split('[locale]')[1] || '/';
  const isRecommendationFlow = currentSubRoute.startsWith('/recommendation');
  const isBreeder = account?.breeder === 'verified_breeder';
  const isSubscriber = isLoggedIn && !!account?.has_subscribed;
  const matchMarketingSite =
    !isLoggedIn && currentSubRoute.startsWith('/recommendation');

  const menuItems = ({ onClick }: { onClick?: () => void }) => (
    <>
      {navItems[isLoggedIn ? 'authenticated' : 'public'](t, locale).map(
        ({
          href,
          title,
          confirmation,
          confirmText,
          isCurrent: customIsCurrent = () =>
            currentSubRoute === href.split('?')[0],
          hide = () => false,
        }) => {
          const hideParams = {
            isBreeder,
            isSubscriber,
            isLoggedIn,
          };

          if (hide(hideParams)) {
            return null;
          }

          const isCurrent = customIsCurrent({ route: currentSubRoute, query });

          return (
            <li key={href}>
              {(isCurrent && confirmation) || isRecommendationFlow ? (
                <NavConfirmationItem
                  {...{
                    onClick,
                    title,
                    confirmation:
                      confirmation ||
                      t('nav.recommendation.confirm_navigation'),
                    href,
                  }}
                >
                  {t(confirmText || 'confirm')}
                </NavConfirmationItem>
              ) : (
                <NavItem
                  href={href}
                  onClick={onClick}
                  aria-current={isCurrent ? 'page' : 'false'}
                >
                  {title}
                </NavItem>
              )}
            </li>
          );
        },
      )}
      {isLoggedIn ? (
        <li key="logout" className="logout">
          <LogoutButton callback={onClick} />
        </li>
      ) : (
        <li onClick={() => toggleMenu(false)}>
          <NavItem
            href="/"
            aria-current={
              !isLoggedIn && currentSubRoute === '/' ? 'page' : 'false'
            }
          >
            {t('nav.login')}
          </NavItem>
        </li>
      )}
    </>
  );

  return (
    <>
      <NavBar {...{ isOpen, matchMarketingSite }} id="nav-bar">
        <MenuTrigger
          onClick={() => toggleMenu(!isOpen)}
          aria-label={t(`accessibility.${isOpen ? 'close_menu' : 'open_menu'}`)}
          aria-expanded={isOpen}
          className="menu-trigger"
        >
          <AccessibilityText>
            t(`accessibility.${isOpen ? 'close_menu' : 'open_menu'}`)
          </AccessibilityText>
        </MenuTrigger>
        <Link
          onClick={() => toggleMenu(false)}
          href={
            isRecommendationFlow && !isLoggedIn
              ? links.MARKETING_SITE[locale]
              : '/'
          }
        >
          <Image
            src="/logo.svg"
            width="56"
            height="56"
            alt="alvar pet logo"
            priority
          />
        </Link>

        <DesktopMenu renderItems={menuItems} />
        <LangWrapper>
          <LanguageSelector matchMarketingSite={matchMarketingSite} />
        </LangWrapper>
      </NavBar>
      <MobileMenu
        isOpen={isOpen}
        toggleMenu={toggleMenu}
        renderItems={menuItems}
        matchMarketingSite={matchMarketingSite}
      />
    </>
  );
};

export default Navigation;
