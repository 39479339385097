export default {
  addons: {
    add_to_order: 'Add',
    title: "Don't forget these!",
  },
  button_text: {
    browse_all_products: 'Browse all products',
    selected: 'Selected',
    select_product: 'Select',
    faq: 'FAQ',
    cancel: 'Cancel',
    continue: 'Continue',
    product_details: 'Details',
    sign_in: 'Sign in',
    sign_up: 'Sign up',
    view_alternatives: 'View alternatives',
    add_to_subscription: 'Add to subscription',
    updating_subscription: 'Updating subscription',
    change_delivery_schedule: 'Change delivery schedule',
    order_overview: 'Order overview',
    skip_next_order: 'Skip next order',
    click_to_add_coupon: 'Click to add a coupon',
    configure: 'Configure',
    edit_subscription: 'Edit subscription\n',
    read_more: 'Read more',
    confirm: 'Confirm',
    view_change_product: 'View / Change product',
    reactivate_subscription: 'Reactivate subscription',
  },
  campaign: {
    share_referral: {
      copy: 'Copy your link & share it',
      success: 'Copied! Ready to share',
    },
    action: {
      add_to_order: 'Add to order',
    },
  },
  campaigns: {
    referral: {
      body: 'Help us grow by recommending Alvar to your doggie friends. Share your personal referral link and you’ll both get a 10€ reward!\\nRead how it works <a href=\\"/benefits/referral\\">here</a>',
      title: 'Spread the love 💕 ',
    },
    valentine: {
      disclaimer:
        "A complimentary Valentine's Day treat bag included in your demo order.",
      title: "A complimentary Valentine's Day treat!",
    },
    tags: {
      limited_time: 'Only for a limited time',
      new: 'New release at Alvar',
      'puppybox-dk': {
        upgrade_for_price: 'Upgrade for 100 DKK',
      },
      'puppybox-se': {
        upgrade_for_price: 'Upgrade for 100 SEK\n',
      },
      puppybox: {
        upgrade_for_price: 'Upgrade for 10€',
      },
    },
    chews_promo: {
      disclaimer:
        'Try our Nordic-origin chews! All of our chews are made from natural and wild ingredients from northern forests and lakes.',
      title: 'Something to chew on',
    },
  },
  cancel_subscription: {
    allergic_reaction: {
      body_1:
        'Food allergy is typically caused by a specific protein in the food. When choosing a food for your sensitive dog, it is a good idea to look for food with innovative protein sources that the dog has not eaten before - how about insect protein? ',
      heading: 'No worries, we can fix this!',
    },
    condolence: {
      heading: 'We’re so sorry to hear. 💔',
      body: '"<p>The only bad thing about dogs is that their life is too short. We’ve cancelled the subscription and send our deepest condolences!\n</p>\n<p>- your friends at Alvar"',
    },
    consultation_link_didn_t_open:
      "<p>If the scheduler didn't open, click <a href='https://calendly.com/alvar-henna/ruokintaneuvonta'>here.</a></p>\\n</br>\\n<p>Remember that you can postpone your next delivery, if you want to await the results of the consultation.</p>",
    email_consultation_success: 'Thank you',
    i_want_still_cancel: 'I still want to cancel.',
    not_tasty: 'Not tasty for my dog',
    sorry_but_why: {
      body: 'We’re sorry to hear you want to cancel {{dogName}}’s subscription. Please tell us the reason, so that we can keep improving Alvar!',
      heading: 'NOOOO - but why?',
    },
    thanks_for_your_trust: 'Thanks for your trust',
    vet_advised_change: {
      body: 'We may have a similar solution for your dog. Can we offer our support?',
      heading: 'Hope you find a suitable diet for {{dogName}}',
    },
    we_will_send_your_consultation:
      '<p>We will send you a recommendation as soon as possible!</p><p>Remember that you can postpone your next delivery, if you want to await the results of the consultation.</p>',
    worse_poop: {
      body: 'Sometimes changing a diet, especially if the new food has a different fibre composition, can lead to temporary changes in poo quality, that will even out. Patience is key here - or, we have other types of recipes that may suit your dog better.',
      heading: 'No worries, we can fix this!',
    },
    you_can_always_make_changes:
      "You can at any time make changes to your order. Please let us know if there's anything you want help with!",
    consultation_awaits: 'Go ahead and book a session!',
    need_more_time: 'I need more time to test it',
    need_more_time_lead:
      "Still trying out the food and haven't decided yet? Let's push the next delivery date so you have more time. ",
    request_failed: {
      text: 'Please try again later or contact our customer support!',
      title: 'An error occurred.',
    },
    unhappy_with_service: 'I’m unhappy with the service',
  },
  delivery_prices: {
    price_over_x_money: '{{discounted}} for orders over {{spend}}\\n',
  },
  discount: {
    applies_to_all: '{{discount}} on all products',
    applies_to_categories: '{{discount}} off selected {{categories}}',
    product_category: {
      '2262': 'kibble',
      '3211': '',
      '4567': 'toppers',
      '4933': '',
      '5011': 'chews',
      '5013': 'chews',
      '5015': 'poop bags',
      '5021': 'treats',
      '5023': 'treats',
      '5029': 'kibble',
      '5031': 'kibble',
      '5035': 'oils',
      '5037': 'oils',
      '5041': 'toppers',
      '5043': 'toppers',
      '5183': 'accessories',
      '6540': 'Finnish kibble',
      '6705': 'kibble',
      '7029': '',
      '7671': 'toppers',
      '7677': 'treats',
      '7681': 'chews',
      '7687': 'accessories',
      '7693': 'kibble',
      '7697': 'supplements',
      '7814': 'accessories',
      '7818': 'supplements',
      '8262': 'combos',
      '8264': 'combos',
      '8266': 'combos',
      '8349': '',
      '8383': 'toys',
      '8386': 'toys',
      '8388': 'toys',
      '8747': '',
      '8749': '',
      wetFood: 'toppers',
      snack: 'treats',
    },
    applies_to_category: 'Discount applies to {{category}}',
    applies_to_multiple_product_names:
      '{{discount}} discount on {{productList}} for up to {{productLimit}} products for your next order.',
  },
  discounts: {
    dog_names_discounts: 'Offers applied & active',
  },
  free_shipping: {
    included: '<b>FREE SHIPPING INCLUDED</b>',
    over_x_money: '<b>FREE SHIPPING</b> on orders over {{money}}',
  },
  frontpage_notification: {
    shipping_price_changes: {
      banner:
        'Changes in delivery pricing! Adjust delivery method to keep free delivery. Read more',
    },
  },
  label: {
    choose_product: 'Choose product',
    delivery: 'Delivery',
    price: 'Price',
    quantity: 'Quantity',
    size: 'Size',
    variant: 'Model',
  },
  my_pack: {
    get_recommendation: 'See recommendation and subscribe',
    recommended_diet_plan: {
      explanation:
        "Nutritious and sustainable. 💚 Please remember to update any changes in {{dogName}}'s needs!",
      header: "{{dogName}}'s tailored meal plan",
    },
    recommended_products: 'Recommended products\n',
  },
  nutritions_daily_title: 'Daily intake: {{dailyGrams}} g / {{dailyDl}} dl',
  orders: {
    load_all_receipts: 'Show all',
  },
  page_not_found: 'Page not found',
  payment: {
    method: {
      kco: 'Invoice (Klarna)',
      missing: 'Payment method missing',
      stripe: 'Credit or debit card (Stripe)',
      manual: 'Payment method missing',
    },
  },
  price: {
    free: 'FREE',
    total: 'Total',
    from_per_kg: 'From {{price}}/kg',
    starting_from: 'starting from {{price}}',
    from: 'From {{price}}',
  },
  product_info: {
    benefits: 'Health benefits',
    carbon_pawprint: 'Carbon paw print',
    ingredients: 'Ingredients',
    nutrients: 'Nutritional information',
    complexes: 'Health Benefits',
    feeding_guide: 'Feeding guide',
  },
  recipe: {
    all_ingredients: {
      title: 'All ingredients',
    },
    nutritional_content: 'Full nutrient information',
    additional_benefits_title: 'This recipe is also good for:',
    needs_covered_by_benefits_title: 'This recipe is good for:',
  },
  recommendation_group: {
    good: 'Good fit',
    notSuitable: 'Not recommended',
    recommended: 'Recommended',
  },
  subscription_details: {
    included_products: 'Products',
    plus_shipping: '+ Delivery',
  },
  targeted_upsell: {
    section_heading: 'Our picks for {{dogName}}',
  },
  benefits: {
    rewards: {
      claim: 'Claim',
      claimed: 'Claimed',
      order: 'Order',
      order_0: 'Welcome Benefit',
      reward_information:
        'Faithful friends of Alvar get to try out our products for free. 💚\\n\\nRecurring orders unlock Alvaristi rewards for you to claim. The product will be added to your subscription. The discount will be taken account in the next delivery. ',
      reward_terms: 'Reward terms',
      rewards_for_dog_name: "{{dogName}}'s Alvaristi rewards",
      timeline_title: 'Your rewards journey',
      you_have_x_unclaimed_rewards: 'You have {{count}} unclaimed reward(s)',
      coming_up: 'Coming up',
      youre_here: "You're here:",
      next: 'Up next',
      previous: 'Previous',
      activation_callout:
        'You need to have an active subscription in order to receive and use benefits for your orders.',
      new_reward_in_order: 'order',
    },
    page_header: 'Alvaristi benefits',
    navigation_option: {
      coupon: 'Coupons',
      referral: 'Referral',
      rewards: 'Rewards',
    },
    coupon: {
      explainer:
        'Confirm your coupon in the field above to apply it to your next order. \nAs a loyal Alvaristi we send you great offers and coupons from time to time, so be sure to keep an eye on your mailbox!\n\nPlease note that if you use a coupon, but remove the product afterwards, you cannot use it again.',
      header: 'Have a coupon?',
    },
    referral: {
      activation_callout:
        'Hi there! 😍 You need to have an active subscription to use referral bonuses for your orders.',
    },
  },
  checkout: {
    account_info: 'Account info',
    add_payment_methods: 'Add payment details',
    add_shipping_methods: 'Add shipping info',
    delivery_method: 'Delivery method',
    delivery_method_text:
      'How and where would you like your Alvar order delivered?',
    do_order: 'Order',
    order: 'Order',
    payment_info: 'Payment details',
    shipping_info: 'Shipping info',
    add_shipping_method: 'Add shipping info\n',
    account: 'Create account',
    account_set_up: 'Create account',
    verify_shipping_info: 'Confirm address',
    confirm_subscription: 'Order now',
  },
  delivery: {
    free: 'FREE',
    checking_availability: 'Finding options',
    demo_shipping_price: 'Delivery',
    free_delivery_on_orders_over_spend:
      'Free delivery for orders above {{spend}}',
    option_not_available: 'Not available in the selected postcode',
    regular_price_for_subs_under_x:
      'Regular delivery price applied to subscriptions under {{minimumSpend}}',
  },
  edit_order: {
    quantity_higher_than_recommended:
      'Woof! Check if you want to increase the order interval. ',
    quantity_lower_than_recommended:
      'Woof! Check if you want to reduce the order interval.',
  },
  emission_card: {
    header: 'Climate impact of the diet',
    kg_saved_per_year_with_subscription: '{{dogName}} saves {{kg}} kg CO2e',
    percent_saved:
      'That is <b>{{percent}} % less</b> than the average premium dry food. <br/>And what’s left, we compensate.',
    savings_equal_to_times_sauna:
      'annually with the diet plan. That is the same as heating up an electric sauna {{timesSauna}} times!',
    by_joining_alvar_dog_name_saves_kg:
      'By joining Alvar, you and {{dogName}} will save about',
    kg_co2_per_year: '{{kg}} kg CO2 / year ',
    this_equals_times_sauna:
      'This equals heating up an electric sauna {{timesSauna}} times.',
  },
  forms: {
    errors: {
      invalid_phone_number: 'Phone number can only contain numbers',
    },
  },
  links: {
    edit: 'Edit',
    show_all: 'Show all',
    save: 'Save',
    view_products: 'View products',
  },
  macro: {
    fat: 'Fat',
    fiber: 'Raw fibre',
    protein: 'Protein',
    energy: 'kcal / 100g',
    kcal: 'kcal / 100g',
  },
  macros: {
    fat: 'Fat',
    fiber: 'Raw fibre',
    kcal: 'Kcal',
    protein: 'Protein',
  },
  order_hero: {
    button_text: {
      edit_order: 'Edit order',
    },
    status: {
      active: 'Active',
    },
  },
  product_options: {
    recommended: 'Recommended',
  },
  'puppy-box-423575': 'Fish box',
  'puppy-box-423589': 'Chicken box',
  'puppy-box-423651': 'Chicken box',
  'puppy-box-423652': 'Fish box',
  'puppy-box-423838': 'Fish box',
  'puppy-box-423857': 'Chicken box',
  starting_price: {
    from_per_kilo: 'From<br/>{{startingPrice}}<br/>/kg',
  },
  feedback: {
    labels: {
      message: 'My message',
      subject: 'The topic of my feedback',
    },
    subjects: {
      delivery: 'Delivery',
      feedback: 'Feedback',
      feeding: 'Nutrition',
      order: 'Subscription',
      other: 'Other',
      payment: 'Payment',
      products: 'Product-related',
      reclamation: 'Reclamation',
    },
  },
  subscription: {
    coupon_field: {
      disabled_help_text:
        'Activate your subscription first to activate the coupon field.',
    },
    next_order_comes: 'Next order to dog {{dogName}} arrives (estimate)',
    welcome_back: 'Welcome back',
    add_treats: 'Add treats and extras',
    cancel: 'Cancel your subscription',
    delivery_and_payment: 'Delivery & payment',
    dog_names_subscription: "{{dogName}}'s <b>Meal subscription</b>",
    next_order_comes_after:
      'Your next package to dog {{dogName}} is leaving in {{days}} days! You can edit the order until {{untilDate}}.',
    sub_total: 'Subtotal',
    summary: {
      delivery: 'Delivery',
      x_to_free_delivery: '<b>{{spend}}</b> to free delivery',
    },
    update: 'Update subscription',
    update_success: 'Changes saved',
    some_recent_order_in_completed_state:
      'You order has been shipped! The kibbles are on their way to {{dogName}}.',
    some_order_needs_payment:
      'There is an issue with your order.\nContinue to resolve.',
    some_recent_order_in_processing_state:
      'The order for {{dogName}} has been processed and is being packed!',
    welcome_back_name: 'Welcome back, {{name}}!',
    activate: 'Activate your subscription',
  },
  thank_you: 'Thank you',
  wrapped: {
    alvar_intro: {
      body: 'Thanks for minimising the carbon paw print with us this year. We proudly present to you: your pup’s CO2-savings.',
      heading: 'Furwell 2022 🐾',
    },
    dog_names_year_wrapped: '{{dogName}}’s 2022 wrapped',
    download_image: 'Download image for your use',
    home_page_notification: {
      body: "As 2022 comes to a close, see how much emissions you've reduced this year. 🐾",
      button: 'See your 2022 wrapped',
      heading: "It's a wrap!",
    },
    how_did_we_calculate: {
      body: '<li>Summed the amount of Alvar dry food {{dogName}} has ordered in 2022. The report only covers kibbles - for now. </li> <li>Calculated the recipe’s CO2 emissions </li> <li>Compared the emissions to that of average premium dry food (The global environmental paw print of pet food, Alexander et al. 2020) </li> <li>Compared the savings to the emissions of heating an electric sauna (OpenCO2.net) </li> <br/> <p>P.S. We won’t store your image anywhere. </p> <p>P.P.S. Happy New Year! </p>',
      heading: 'To calculate this number, we:',
    },
    intro:
      'Add a photo of your dog, save the image or take a screenshot, and share it on social media (please tag us @alvarpet)!',
    sorry_no_orders: "Sorry, we can't find any orders for your dog in 2022.",
    upload_image: 'Add a photo of your dog',
    upload_new_image: 'Change photo',
  },
  form: {
    contact: {
      submit: 'Submit',
      success: {
        heading: 'Thank you for your feedback!',
        message:
          "Thank you, we will handle your message as soon as possible. Please don't send an email to us, just in case, with the same topic - of course except if there is something you forgot to tell us in this message!\\n\\nWe have a great respect to all thoughts, questions, suggestions and wishes we get, since all of them help us to improve our service at Alvar. ",
      },
    },
    labels: {
      email: 'Email',
    },
    warning: {
      unsaved_changes:
        'You have unsaved changes. Are you sure you want to leave the page?',
    },
    delivery: {
      matkahuolto: 'Matkahuolto',
      post: 'Pick-up point',
      submitted_notification: 'Successfully saved!',
      home: 'Home delivery',
      errors: {
        dog_weight: {
          too_high: 'Dog weight cannot exceed 200 kg',
          too_low: 'Dog weight must be positive',
          email_already_in_use: "This email's already in use",
          email_already_in_use_please_login:
            "This email's already in use – please log in",
          invalid_date: 'Invalid date',
          invalid_day: 'Not a valid day',
          invalid_month: 'Not a valid month',
          month_only_has_x_days: 'Entered month only has {{days}} days',
          must_be_number: 'Must be a number',
          must_feed_dry_food:
            'An Alvar diet must contain dry food, please add it ☺️',
          no_negative_allowed: 'Value is not allowed to be negative.',
          no_options_available: 'We are sorry, no suitable options found.',
          not_valid_email: 'Check the email',
          not_valid_postcode: 'Postcode invalid',
          passwords_not_match: "The passwords don't match",
          postal_code_needed: 'Postal code required',
          postcode_needed: 'Postcode required',
          required: 'Mandatory',
          sum_too_high: 'Sum cannot exceed 100 %.',
          too_short_password: 'The password is too short',
        },
      },
      bring: 'Bring',
      budbee: 'Budbee',
      postnord: 'PostNord',
      instabox: 'Instabox\n',
    },
    placeholder: {
      autocomplete: 'Start typing',
      autocomplete_disabled: 'Breed not found',
      birthday: 'dd.mm.yyyy',
      custom_breed_name: 'Missing breed',
      day_format: 'dd',
      email: 'example@email.com',
      month_format: 'mm',
      select: 'Select...',
      year_format: 'yyyy',
      coupon_field: 'Add code here',
    },
    errors: {
      required: 'Mandatory',
      invalid_phone_code: 'Invalid country code',
      dog_weight: {
        too_high: 'Dog weight cannot exceed 200 kg',
        too_low: 'Dog weight must be positive',
      },
      email_already_in_use: 'This email is already in use',
      email_already_in_use_please_login:
        'This email is already in use – please log in',
      invalid_date: 'Invalid date',
      invalid_day: 'Not a valid day',
      invalid_month: 'Not a valid month',
      month_only_has_x_days: 'Entered month only has {{days}} days',
      must_be_number: 'Must be a number',
      no_negative_allowed: 'Value is not allowed to be negative.',
      must_feed_dry_food:
        'An Alvar diet must contain dry food, please add it ☺️',
      no_options_available: 'We are sorry, no suitable options found.',
      not_valid_email: 'Check the email',
      not_valid_postcode: 'Postcode invalid',
      passwords_not_match: 'The passwords do not match',
      postal_code_needed: 'Postal code required',
      postcode_needed: 'Postcode required',
      recommendation_engine_create_account_token_invalid:
        'Oh no – the session has expired. Please',
      recommendation_engine_create_account_token_invalid_link_text:
        'start again',
      sum_too_high: 'Sum cannot exceed 100 %.',
      sum_too_low: 'Sum of diet must be 100 %',
      too_short_password: 'The password is too short',
      must_include_diet_item:
        "{{dogName}}'s gotta eat something, right? Please select at least one category of food!",
    },
    submission_failed:
      'Something went wrong! Please try again or contact customer support if stuck.',
    submit_new_password: 'Send a new password',
    loading: 'Loading options...',
    confirm: 'Confirm',
  },
  dates: {
    date_no_future: 'Future dates not allowed',
    date_not_valid: 'Date not valid',
  },
  dog: {
    diet_composition: {
      intro:
        'Tell us about your current feeding routine. We will use this as the base for our recommendation. <br/>Default is 100% dry food – adjust the split below.',
    },
    gender: {
      male: 'Male',
      female: 'Female',
    },
    intro_text:
      'Based on the info, we’ll create them a tailored meal plan for your demo box.',
    labels: {
      birthday_field: {
        day: 'Day',
        hint: 'For example, 19 07 2019 (Like Alvar!)',
        legend_with_name: "What is {{name}}'s birthday?",
        legend_without_name: "What is your dog's birthday?",
        month: 'Month',
        year: 'Year',
      },
      preferred_ingredients: 'Preferred ingredients',
      activity: 'Daily activity',
      breed: 'Breed (a blend? Type in Mongrel.)',
      body_condition: 'Body type',
      birthday:
        'Birthday (If you don’t know your dog’s exact birth date, a rough estimate is fine!)',
      dog_name: 'Name of the dog',
      diet_composition: {
        dry: 'Kibble (%)',
        other: 'Other / homemade (%)',
        treats: 'Treats (%)',
        wet: 'Wet food (%)',
      },
      title: 'Hi, I’m Alvar! Tell us about your dog?',
      titles: {
        activity: 'How fit is {{dogName}}?',
        body_type: 'Body condition',
        diet_composition: "What is {{dogName}}'s current diet like?",
        preferred_ingredients: 'What would {{name}} like in their bowl?',
      },
      update_success: 'Changes saved!',
      labels: {
        gender: 'Gender',
        special_needs: 'Special needs',
        weight: 'Adult weight',
      },
      placeholder: {
        weight_estimate: 'Our estimate',
      },
      birthday_too_old: 'Haha, doubt your dog is {{age}} years old?',
      add_new: 'Add new dog',
      add_another: 'Add another dog',
      add: 'Add dog',
      carbon_paw_print_title: "{{dogName}}'s carbon paw print",
      body_type_modal: {
        header: "How to assess your dog's body condition?",
        body: 'A dog with normal weight/body condition should have clearly visible waist line and his ribs should be easily palpable. Give your dog a hug!',
      },
      gender: 'Gender',
      special_needs: 'Special needs',
      weight: 'Adult weight',
      email_opt_in: "Yes, please! Let me know about Alvar's great offers",
      email: 'Email',
      country: 'Shipping to',
      account_action: 'Log in or create account',
    },
    title: 'Hi, I’m Alvar! Tell us about your dog?',
    label: {
      preferred_ingredients: 'Preferred ingredients',
      diet_share_treat: 'Treats\n',
    },
    no_special_needs: 'No special needs',
    placeholder: {
      weight_estimate: 'Our estimate',
      enter_weight: 'Enter adult weight (estimate is fine)',
      our_weight_estimate: 'Enter adult weight (we estimate {{estimate}} kg)',
    },
    body_condition: {
      '3': 'Skinny',
      '5': 'Ideal',
      '7': 'Chubby',
      choose: 'Choose',
    },
    titles: {
      activity: 'Activity',
      diet_composition: 'What is your dogs current diet like?',
      preferred_ingredients: 'What would {{name}} like in their bowl?',
    },
    add_another: 'Add another dog',
  },
  labels: {
    billing_method: 'Payment method',
    drop_point: 'Drop point',
    email_user_account: 'Email (user account)',
    last_name: 'Last name',
    name: 'Name',
    password: 'Password',
    password_repeat: 'Repeat password',
    phone_prefix: 'Country code',
    shipping_address: 'Address',
    shipping_city: 'City',
    shipping_country: 'Country',
    shipping_postcode: 'Postcode',
    starting_date: 'Next order after demo',
    contact_info: 'Contact info',
    interval: 'Delivery interval',
    invoices: 'Receipts',
    payment_info: 'Payment details',
    shipping_info: 'Shipping info',
    delivery: {
      email_opt_in:
        'Yes please! Sign me up for sample discounts, tailored feeding advice and dog pics.',
    },
    save_dog_info: 'Save my details and email me a copy of the meal plan',
    email_opt_in:
      'Yes please! Sign me up for sample discounts, tailored feeding advice and dog pics.',
    shipping_interval: 'Delivery interval',
    shipping_size: 'Kibbles per delivery',
    delivery_type: 'Delivery type ',
    shipping_date: 'Next order date\n',
    first_name: 'First name',
    home_delivery: 'Courier',
    phone: 'Phone number',
    delivery_address: 'Shipping address',
    card: {
      card_number: 'Card number',
      cvn: 'CVN',
      expiration: 'Valid through',
    },
    bundle_size: 'Package size',
    current_password: 'Current password',
    delay_next_order_modal: {
      interval: 'Delivery interval',
      payment_date: 'Next order date:',
    },
    email: 'Email',
    new_password: 'New password',
    new_password_repeat: 'Repeat new password',
    address: 'Address',
    city: 'City',
    po_box: 'Pick-up point',
    zip: 'Postal code',
  },
  nav: {
    recommendation: {
      confirm_navigation:
        'Hi, leaving the meal planner? You might lose your progress 🐾',
    },
    home: 'Home',
    marketing_site: 'Back to alvarpet.com',
    back_to_marketing_site: 'Back to alvarpet.com',
    benefits: 'Benefits',
    breeders_club: 'For breeders',
    frontpage: 'Frontpage',
    login: 'Login',
    logout: 'Logout',
    my_herd: 'My pack',
    orders: 'Subscription',
    profile: 'Profile',
    recommendation_engine: 'Recommendation engine',
    recommendation_machine: 'Recommendation engine',
    recommendation_machine_confirmation:
      'You have not yet completed the recommendation engine. Would you like to start over?',
    recommendation_machine_restart: 'Reset',
    my_sustainability: 'My CO2 impact',
  },
  recommendation: {
    manage_secondary: 'Change / Remove',
    feeding_guide: {
      heading: 'Feeding guide',
      calorie_overview: {
        heading: 'Calorie distribution',
      },
      lead: 'The what, why and how much {{dogName}} should eat in a day to stay happy and healthy.',
      macros: {
        heading: 'Diet plan macros',
      },
    },
    diet_plan_intro: {
      heading: '{{dogName}}’s tailored meal plan',
      lead: "Here's our recommended meal plan tailored to {{dogName}}’s needs. You can finalise your demo box contents on the next page.",
    },
    consideration_slide: {
      activity: {
        active:
          "We'll adjust the daily intake to match {{dogName}}'s active lifestyle.",
        lazy: "We'll adjust the daily intake to match {{dogName}}'s laidback lifestyle.",
        normal:
          "We'll adjust the daily intake to match {{dogName}}'s lifestyle.",
      },
      age: {
        adult: "We design the diet to fit {{dogName}}'s individual needs.",
        puppy: 'We will recommend recipes designed for puppies.',
        senior: 'We will recommend recipes designed for seniors.',
      },
      bcs: {
        chubby:
          'To help {{dogName}} with weight control, we will recommend low-energy recipes.',
        ideal:
          '{{dogName}} will get everything they need from natural, high-quality ingredients.',
        skinny:
          'To help {{dogName}} gain weight, we will recommend high-energy recipes.',
      },
      special_need: {
        heartBurn:
          'We will recommend low-fat products to steer clear from heart burn.',
        neutered:
          'As {{dogName}} is neutered, we take that into account in recipe choice and portion size.',
        pickyEater:
          "Since {{dogName}} is a picky eater, we'll add some extra YUM in the recommendation.",
      },
    },
    pages: {
      ingredients: {
        disclaimer:
          'We might not have a mono-protein recipe that is fit for {{name}} yet - but will do our best!',
      },
      email: '{{dogName}}, your meal plan is ready!',
      email_loading: "Building {{dogName}}'s diet plan..",
      activity: 'Activity level',
      diet_composition: 'Diet share',
      dog_info: 'Dog info',
      ingredients_page_title: 'Preferred ingredients',
      recommendation: 'Recommendation',
      specialNeeds: 'Special needs',
    },
    diet_plan_heading: '{{dogName}}’s tailored meal plan',
    diet_plan_lead:
      "<b>Here it is, your dog's nutritionally optimal and environmentally friendly daily diet.</b> 😍🐾 You can browse and edit the products below.",
    show_recommendation: 'Show recommendation',
    form: {
      is_breeder: {
        tooltip:
          'Breeder? Join Alvar Breeders Club! We will send you the details via email (or you can go ahead and read about it <a href="https://alvarpet.com/">here</a>).',
      },
      is_breeder_label: 'I am a breeder',
      demo_order: 'Next',
      terms_conditions_link: 'Terms and conditions',
      terms_conditions: 'I accept',
    },
    subscription_ksp: {
      body: {
        home_delivery: 'Enjoy the convenience of regular deliveries. ',
        loyalty_benefits: 'We’ll pamper you with exclusive offers and gifts.\n',
        modify_cancel_any_time:
          'Modify, delay or cancel your subscription at any time.',
        lower_prices: 'Subscribers get <b>10%</b> off on their orders, always.',
      },
      heading: {
        home_delivery: 'At your doggie doorstep',
        loyalty_benefits: 'More than just belly rubs\n',
        modify_cancel_any_time: 'Flexible to your needs',
        lower_prices: 'Best price for best friends',
      },
      section_heading: 'What happens next',
    },
    referral_link_text: 'Give 10€ - Get 10€',
    thank_you:
      '<p>We are so happy that you’ve joined us on our journey to minimise the CO2 paw print. Together we have saved already more than 2,810,632 kg CO2e!🐾\n</p> <p><strong>Want to support us and bring your dog friend along?\n</strong></p>',
    thank_you_add_another_notice: ' ',
    dog_names_meal_plan_product_count:
      'We created {{dogName}}’s meal plan with the following {{productCount}} products.',
    order_overview: 'Go to order',
    our_recommendation: 'Our recommendation',
    thank_you_add_dog: 'Add another dog',
    restart_confirmation: 'Reset the recommendation engine',
    wet_food_feeds_other: {
      'ALV-711':
        "Chicken Bowl brings welcome variety to daily meals for a dog like {{dogName}} who's not used to eating only dry food.\n",
      'ALV-402':
        "Alvar's Chicken Topper brings welcome variety to daily meals for a dog like {{dogName}} who's not used to eating only dry food.",
    },
    cart_is_empty: {
      body: 'Click below to restore the original recommendation.\n',
      header: 'Your cart is empty!\n',
    },
    change_interval: {
      explainer:
        'Delivered every {{weeks}} weeks<br/>Adjust or cancel at any time',
      made_to_fit: 'Made to fit your schedule',
      commitment_free: 'Your commitment free subscription',
      make_changes_any_time:
        'Pause deliveries, change the dates, or cancel any time.',
    },
    reset_cart_to_recommendation: 'Reset cart\n\n\n\n\n',
    secondary_product: {
      remove: 'Remove',
    },
    treats_feeds_treats: {
      'ALV-202':
        "We heard that {{dogName}} has a 'sweet tooth'. Alvar's Nuggets are a healthy and delicious choice for a daily training and pampering. ",
    },
    wet_food_feeds_wet: {
      'ALV-402':
        "Finnish Chicken Topper is a tasty and healthy meal enhancer for a dog who's used to eating wet food with their kibbles, such as {{dogName}}.",
    },
    treats_dog_is_puppy: {
      'ALV-205':
        "At this age {{dogName}} is constantly learning something new. Alvar's healthy and delicious Wild Deer treats are an excellent choice for a puppy's daily training and pampering.\n",
    },
  },
  loading: 'Fetching sticks and data 🐾',
  product_card: {
    more_information: 'Recipe information',
    about_product: 'About the product',
  },
  confirm: 'Confirm',
  action: {
    change_recipe: 'Change recipe',
    add: 'Add',
    change_dog: 'Change dog',
    remove: 'Remove',
    go_to_next: 'Next',
    show_more: 'Show more',
  },
  warning: {
    diet_composition: {
      sum_exceeds_100: 'Sum of diet exceeds 100 %.',
      sum_less_than_100_percent: 'Sum of diet must be 100 %.',
    },
  },
  validation_suggestion: {
    diet_composition: {
      reduce_to_match: 'Reduce to match',
      sum_less_than_100_percent: 'Increase kibble to match',
      sum_more_than_100_percent: 'Reduce kibble to match',
      uses_raw_food: 'Set to 0 %',
      wet_over_recommended: 'Set to 50 %',
    },
  },
  image_alt_text: {
    activity_level_1: 'Inactive dog',
    activity_level_2: 'Medium active dog',
    activity_level_3: 'Highly active dog',
    body_type_1: 'Skinny',
    body_type_2: 'Ideal dog',
    body_type_3: 'Chubby dog',
    preferred_ingredient: {
      chicken: 'Chicken',
      duck: 'Duck',
      fish: 'Fish',
      grainFree: 'Grain-free',
      insect: 'Insect',
      noPreference: 'No preference or best fit',
      plant: 'Plant',
    },
    illustrations: {
      home_delivery: 'home delivery',
      loyalty_benefits: 'gifts',
      modify_cancel_any_time: 'dog eager to taste',
      lower_prices: 'discounts',
    },
    diet_item: {
      treat: 'Treats\n',
    },
  },
  hint: {
    diet_composition: {
      diet_composition: {
        dietShareOther:
          "Our recommendation will cover 100% of your dog's nutritional needs; please note that the homemade portion won't be included in it. ",
      },
    },
  },
  forgot_password: 'Forgot your password?',
  diet_legend: {
    dry: 'Kibble',
    other: 'Other / homemade',
    supplement: 'Supplements',
    treats: 'Treats',
    wet: 'Wet food',
  },
  food: {
    nutritions: {
      recommended: {
        lead: "{{dogName}}'s optimal daily diet is {{dailyGram}} g of kibble. It's based on {{dogName}}'s age, breed and lifestyle.",
      },
    },
    preferred_ingredient: {
      chicken: 'Chicken',
      duck: 'Duck',
      fish: 'Fish',
      grain_free: 'Grain-free',
      insect: 'Insect',
      no_preference: 'No preference / Best fit',
      plant: 'Plant',
    },
    for_special_need: {
      activeDog: 'Active dog',
      agingDog: 'Aging dog',
      brainWellness: 'Brain wellness',
      delicateStomach: 'Sensitive stomach',
      grainFree: 'Grain-free',
      greedyEater: 'Greedy eater',
      growingDog: 'Growing dog',
      itchyPaws: 'Itchy paws',
      jointStiffness: 'Joint stiffness',
      loosePoop: 'Loose poop',
      neutered: 'Neutered',
      pickyEater: 'Picky eater',
      sensitiveSkin: 'Sensitive skin',
      tendencyToGainWeight: 'Tendency to gain weight',
    },
    covers_x_weeks: 'covers {{weeks}} weeks',
  },
  shipping_countries: {
    se: 'Sweden',
    no: 'Norway',
    fi: 'Finland',
    dk: 'Denmark',
    de: 'Germany',
  },
  units: {
    after_days: 'in days',
    bottle: 'bottle',
    can: 'can',
    dl: 'dl',
    from: 'starting from',
    grams: 'g',
    hour: 'h',
    kcal: 'kcal',
    kilos: 'kg',
    ml: 'ml\t',
    pcs: 'pcs',
    price_starting_abbr: 'from',
    product_plural: 'products',
    sample_order: 'trial',
    product_singular: 'product',
    tbsp: 'tbsp',
    today: 'today',
    week: 'week',
    weeks: 'weeks',
    weeks_abbr: 'Weeks',
    x_pack: '{{x}} pack',
  },
  wizard: {
    next: 'Next',
    submit: 'Submit',
  },
  navigation: {
    go_to_x: 'Go to',
  },
  footer: {
    privacy_policy: 'Privacy policy',
    disclaimer: '© {{year}} ALVAR PET OY. FOOD FOR THE CONSCIOUS PUP.',
  },
  copied_to_clipboard: 'Copied to clipboard!',
  order: {
    add_treat: 'How about these?',
    configure_dog_names_subscription: "{{dogName}}'s <b>Meal subscription</b>",
    demo_price: 'Demo price',
    dog_names_demo_order: "{{dogName}}'s <b>Demo box</b>",
    next_delivery: 'Next order date\\n',
    price_and_then: 'And after that, if you continue your subscription:',
    sample_total: 'Trial package total',
    subscription_intro:
      "Ordering a demo box starts a subscription. It's convenient, full of benefits and flexible: you can edit or cancel the orders on your customer app. Please note, that the date selection below is for your first renewal – the demo box will arrive in a couple of days regardless. ",
    download_receipt: 'Download receipt',
    next_delivery_for_dog_name: "{{dogName}}'s next order",
    order_history: 'Order history',
    orders: 'Subscriptions',
    you_can_edit_order_until: 'You can edit your order until {{untilDate}}',
    errors: {
      something_went_wrong: "Oh no, we're so sorry! Something went wrong.",
      coupon_not_valid:
        'Error! This code is not valid or you have reached the maximum times of usage',
    },
    coupon_confirm: 'Confirm',
    coupon_label_1: 'Got a coupon?',
    coupon_label_2: 'Insert code',
    completed: 'Completed',
    history_product_plural: 'products',
    total: 'Subscription total:',
    subscription_intro_name_grams:
      'As {{dogName}} eats {{dailyGrams}} g of kibble per day, this interval would fit well. But you can adjust it at any time!',
    on_hold: 'Subscription on hold',
    every_x_weeks: 'Every {{weeks}} weeks',
    interval: 'Order interval',
    per_x_week: 'Per {{weeks}} weeks',
    this_order: 'This order',
    delivery_interval: 'Delivery every {{interval_weeks}} weeks',
    history_product: 'product',
  },
  meta_description: {
    recommendation:
      'Tailor a meal plan to meet the unique needs of your dog and see improvement in your their health!',
    frontpage: 'Login to your customer page and manage your order.',
  },
  password: 'Password',
  email: 'email',
  login: {
    button: 'Log in',
    log_in: 'Login',
    remember_me: 'Remember me',
    login_in: 'Login',
    errors: {
      login_failed: 'The login failed. Please try again.',
    },
  },
  product: {
    clearance_nuggets: {
      long: 'Climate deals: Nugget',
      short: 'Nugget',
    },
    clearance_fish_oil: {
      short: 'Climate deals: Wild fish oil',
      long: "Climate deals: Alvar's Wild fish oil 100ml",
    },
    info_text: 'Product details',
    puppy_box: "Alvar's Puppy Box",
    german_fish_and_chips: {
      long: 'Climate deals: Fish & Chips',
      short: 'Fish & Chips',
    },
    'zero-emission': {
      accessory: "Alvar's essentials",
      dryFood: "Alvar's Zero-Emission Kibble",
      kibble: "Alvar's Zero-Emission Kibble",
      puppyBox: 'For puppies',
      sauce: "Alvar's Zero-Emission Supplement",
      snack: "Alvar's Zero-Emission Treat",
      supplement: "Alvar's Zero-Emission Supplement",
      wetFood: "Alvar's Zero-Emission Wet Food",
      chew: "Alvar's Chew",
      toy: 'Alvars Upcycled Toy',
      treat: "Alvar's Zero-Emission Treat",
    },
    steady_sample: 'Steady',
    root_sample: 'Root',
    bug_sample: 'Bug',
    serene_sample: 'Serene',
    slender_sample: 'Slender',
    zippy_sample: 'Zippy',
    out_of_stock: 'Temporarily out of stock',
    view: 'VIEW',
  },
  puppy_box: {
    item: {
      tote_bag: 'Tote bag',
    },
    description:
      'A puppy box is a selection of our products that will help puppies get their Carbon Pawprint right from the start. ',
  },
  accessibility: {
    open_menu: 'Open menu',
  },
  channel: {
    facebook: 'Alvar Pet',
    instagram: '@alvarpet',
    email: 'Contact us',
  },
  contact_info: {
    take_contact_lead: 'Anything on your mind, questions or requests?',
    take_contact: 'Contact us',
  },
  page_title: {
    benefits: {
      coupon_page: 'Coupons',
      frontpage: 'Alvaristi benefits',
      referral_page: 'Referrals',
      rewards_page: 'Rewards',
    },
    orders: {
      edit: 'Edit',
      frontpage: 'Orders',
    },
    frontpage: 'Customer page',
    order: 'Order',
    wrapped: '2022 Wrapped',
    profile: {
      edit_user: 'Edit your profile',
      edit_payment: 'Edit',
      edit_shipping: 'Shipping address',
      frontpage: 'Profile',
    },
    breeders: 'Alvar Breeders Club',
    local_products_lander: 'Zero-Emission Kibbles from Finland',
    my_herd: {
      edit: 'Edit',
      frontpage: 'My pack',
    },
    recommendation_machine: 'Recommendation engine',
    sustainability: "My pack's climate efforts",
  },
  change: 'Change',
  reward_block: {
    step_1_description:
      'Your friend gets -{{discount}} benefit to their first order, when they order via your link.',
    step_1_title: 'Share your referral',
    title: 'Share & earn rewards',
    share_button: 'Copy your referral link',
    step_2_description:
      'You get a {{discount}} discount for each code your friends use.\n\nYou can share your link a maximum of 20 times per year. ',
    step_2_title: 'Earn your reward',
  },
  ally_text: {
    change_subscription_configuration: 'Change configuration',
    edit_address: 'Edit address',
    edit_subscription: 'edit subscription',
  },
  product_category: {
    long: {
      dryFood: "Alvar's Zero-Emission Kibble",
      chew: "Alvar's chews",
      accessory: "Alvar's supplies",
      supplement: "Alvar's oils",
      treat: "Alvar's treats\n",
    },
    accessory: 'Supplies',
    all: 'All',
    sauce: 'Supplements',
    snack: 'Treats',
    supplement: 'Oils',
    wetFood: 'Wet foods',
  },
  recommended_option: 'Recommended',
  subscription_configuration: {
    additional_products: 'Other products',
    select_next_order: {
      heading: 'When do you want the next delivery?',
    },
    x_kg_y_weeks: '{{kg}} kg every <br/>{{weeks}} weeks',
    explainer:
      '<div class="drop-letter">💡 For your convenience, we have configured the following subscription for {{dogName}}, that will ensure you get new deliveries in time.\n</div>\nThe subscription is based on the products in the demo box but you can customise it after trying the demo order and cancel at any time.',
  },
  checkout_pre_footer: {
    if_you_continue: 'If you continue',
    join_other_pupstomers:
      'Join thousands of happy and healthy pupstomers at Alvar.',
    summary: 'Summary',
    tasting_package: 'Tasting package',
    subscription_summary: 'Subscription summary',
    this_order: 'This order',
  },
  coupon_modal: {
    discount_successfully_applied:
      'Discount successfully applied to products in your order.\n',
    no_applicable_product_in_sub:
      'No applicable products found in your subscription. Please choose products below! ',
  },
  discount_overview: {
    discount_applied: 'Discount applied',
    discount_applies_to_cheapest: 'Discount applies to the cheapest product.',
  },
  breeders: {
    box_content: {},
  },
  no_thanks: 'No, thanks',
  back: 'Back',
  link: {
    order_history: 'Order history',
  },
  alt_text: {
    complexes: {
      digestion: 'Digestion+',
      joint_support: 'Joints & Mobility+\n',
      'mother_&_puppy': 'Mother & Puppy+\n',
      puppy: 'Puppy+',
      senior: 'Senior+\n',
      sensitivity: 'Sensitivity+',
      'skin_&_coat': 'Skin & Coat+\n',
      weight_control: 'Weight Control+\n',
    },
  },
  customer_service: {
    consultation: {
      body: 'Unsure about your dog’s diet? Our vet Henna can help you!',
      link_text: 'Book a consultation',
    },
    contact: {
      body: 'If you need help, {{name}} is here for you! Send a message via:',
      link_text: 'hello@alvarpet.com',
    },
    faq: {
      body: 'If you have questions about orders and products, please visit',
      link_text: 'FAQ page',
    },
    heading: 'Customer support',
    trustpilot: {
      body: 'Are you and your dog happy with Alvar?',
      link_text: 'Review us on Trustpilot',
    },
  },
  product_option: {
    out_of_stock: 'Out of stock',
  },
  validation: {
    invalid_shipping_country: 'Invalid country. Please select.',
  },
  subscription_card: {
    action: {
      activate: 'Activate',
      add_coupon: 'Coupon',
      delay_next_order: 'Postpone next delivery',
      pay_now: 'Pay now',
      resolve_issue: 'Resolve',
    },
    status: {
      on_the_way: 'Order on its way - it will arrive in 1-3 days',
      cancelled: 'Subscription is cancelled\n',
      next_delivery_scheduled_for: 'Next delivery scheduled for {{date}}',
      on_hold: {
        needs_payment:
          'Payment failed - issues must be resolved before order can be processed',
        something_wrong:
          'There is an issue with the subscription. Please provide missing information.',
      },
    },
    notification: {
      you_unlocked_a_reward:
        'You have unlocked a new Alvaristi reward for this subscription.',
    },
  },
  subscription_list: {
    add_another_dog: 'Add another dog',
    heading: {
      order_status: 'Order status',
    },
  },
  change_dog: {
    select_dog: 'Select dog',
  },
  user: {
    start_by_adding_dog: 'Start by adding a dog',
    we_miss_u: 'We miss you',
    we_miss_u_lead:
      'Would now be a good time for a new order? If you have any worries, our customer support is here for you!',
  },
  campaign_hero: {
    product_launch: {
      deer_treat_launch: {
        body: '100 % natural dog treat from dried wild deer 🦌 Not only delicious but also natural and healthy. Made from sustainable Swedish ingredients: Wild deer and nothing else.\n',
        title: "Meet our newest natural treat: Alvar's Snack Wild Deer\n",
      },
      dental: {
        body: 'To celebrate the Alvar & Kivuton merge: new in! Choose from 3 alternatives, or test the full trio. ',
        title: 'Add dental care products to your subscription',
      },
    },
  },
  modal: {
    delay_next_order: {
      heading: 'Postpone next delivery',
    },
  },
  cancellation_form: {
    not_suitable: {
      try_sensitivity_products: {
        include_kibble: {
          tooltip: {
            'ALV-106':
              'Delicate: grain-free recipe with duck protein, medium-sized kibble. ',
            'ALV-107':
              'Zippy: grain-free recipe with poultry protein, extra small kibble. ',
            'ALV-108':
              'Steady: grain-free recipe with wildfish protein, medium-sized kibble. ',
            'ALV-108-FI':
              'Steady: grain-free recipe with wildfish protein, medium-sized kibble.\n',
            'ALV-110':
              'Bug: grain-free recipe with insect protein, medium-sized kibble. ',
          },
        },
      },
    },
    not_tasty: {
      try_picky_products: {
        include_kibble: {
          tooltip: {
            'ALV-107':
              'Zippy is a favourite among small picky eaters. Grain-free recipe heroing poultry protein, a tiny kibble size packed with flavour.',
            'ALV-108':
              'Steady is a favourite among picky eaters. Grain-free recipe heroing wild fish protein, with a medium-sized kibble. ',
          },
        },
      },
    },
  },
  subscription_switcher: {
    one_time_order: {
      heading: {
        save_on_orders: 'Save on all orders',
      },
    },
  },
  dog_info_title: 'Doggie info',
  opt_in_modal: {
    body: 'Send tailored recommendations to my dog.',
    close: 'No thanks',
    heading: 'Feeding recommendations to email?',
    submit: 'Sign me up!',
  },
  analytical_constituents: 'Analytical constituents\n',
  ash: 'Ash',
  cart: {
    delivery_price: 'Delivery',
    total: 'Total',
  },
  close: 'Close',
  fat: 'Fat',
  fiber: 'Fiber',
  protein: 'Protein',
  shipping_price: {
    calculated_at_checkout: 'calculated at checkout',
  },
  subscription_status: {
    active: 'Active',
    cancelled: 'Cancelled',
    on_hold: 'Pending action\n',
  },
  unit: {
    '%': '%',
  },
  calcium: 'Calcium',
  magnesium: 'Magnesium',
  moisture: 'Moisture',
  omega_3: 'Omega-3',
  omega_6: 'Omega-6',
  phosphorus: 'Phosphorus',
  potassium: 'Potassium',
  skip_order: {
    body: 'Your next order will be processed <b>{{nextOrder}}<b/> and arrive 1-3 days later.',
    heading: 'Are you sure?',
  },
  sodium: 'Sodium',
  order_date: 'Order date',
};
