import { ReactNode, useEffect } from 'react';
import styled from 'styled-components';

import LangSelector from './LanguageSelector';
import ContactChannels from 'app/ui/navigation/contact-channels';
import { useLocale } from '@lib/hooks';
import { ThemeProps } from '@lib/theme';

interface Props {
  className?: string;
  renderItems: (props: any) => ReactNode;
  isOpen: boolean;
  toggleMenu: (state: boolean) => void;
  matchMarketingSite: boolean;
}

interface ThemeAndMatch extends ThemeProps {
  matchMarketingSite: boolean;
}

const MenuContent = styled.ul(
  ({ theme: { breakpoints } }: ThemeProps) => `
  line-height: 4rem;
  letter-spacing: 0px;
  width: 100%;
  
  @media only screen and (min-width: ${breakpoints.smMax}) {
    columns: 2;
  }
`,
);

const MenuContainer = styled.nav(
  ({
    theme: { constants, zIndex, colors, breakpoints },
    matchMarketingSite,
  }: ThemeAndMatch) => `
    height: 100%;
    text-align: center;
    padding: calc(${constants.navbarHeight} * 2) 0 ${constants.navbarHeight};
    position: fixed;
    left: 0;
    right: 0;
    top: -100%;
    z-index: ${zIndex.navBar - 1};
    visibility: hidden;
    transition: top 0.4s ease, visibility 0s 1s;
    overflow-x: auto;
    overflow-y: scroll;
    background: ${
      matchMarketingSite
        ? colors.highlightColor
        : colors.backgroundColorInverted
    };
    color: ${colors.primaryColorInverted};

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    &.open {
      top: 0;
      transition: top 0.4s ease, visibility 0s;
      visibility: visible;
    }

    &:focus {
      outline: none;
    }

    @media only screen and (min-width: ${breakpoints.smMax}) {
      height: unset;
    }

    @media only screen and (min-width: ${breakpoints.smMax}) {
      .lang-selector, 
      .section-lang-selector {
        width: fit-content;
        margin-left: 50vw;
      }
    }
  `,
);

const Divider = styled.hr`
  border-top: 1px solid ${({ theme }) => theme.colors.highlightColorInverted};
  width: calc(100% - 2rem);
  margin: 2rem 0;
`;

const MobileWrapper = ({
  renderItems,
  isOpen,
  toggleMenu,
  matchMarketingSite,
  ...rest
}: Props) => {
  const locale = useLocale();
  useEffect(() => {
    document.body.style.overflow = isOpen ? 'hidden' : '';
    return () => {
      document.body.style.overflow = '';
    };
  }, [isOpen]);

  useEffect(() => {
    const onOutsideClick = (event: MouseEvent) => {
      if (isOpen) {
        const element = document.getElementById('mobile-menu');
        const topNavBar = document.getElementById('nav-bar');

        const isOutsideClick =
          event.target !== element &&
          event.target !== topNavBar &&
          !element?.contains(event.target as Node) &&
          !topNavBar?.contains(event.target as Node);

        if (isOutsideClick) {
          toggleMenu(false);
        }
      }
    };

    window.addEventListener('click', onOutsideClick);

    return () => {
      window.removeEventListener('click', onOutsideClick);
    };
  }, [isOpen]);

  return (
    <MenuContainer
      role="region"
      aria-labelledby="main-menu-trigger"
      matchMarketingSite={matchMarketingSite}
      tabIndex={isOpen ? 0 : -1}
      data-hidden={!isOpen}
      {...rest}
      className={isOpen ? 'open' : ''}
      id="mobile-menu"
    >
      <MenuContent>
        {renderItems({
          onClick: () => {
            toggleMenu(false);
          },
        })}
      </MenuContent>
      <LangSelector
        matchMarketingSite={matchMarketingSite}
        onClick={() => {
          toggleMenu(false);
        }}
      />
      <Divider />
      <ContactChannels locale={locale} />
    </MenuContainer>
  );
};

export default MobileWrapper;
