import styled from 'styled-components';
import { ReactComponent as ArrowIcon } from '../public/icons/arrow.svg';
import Link, { LinkProps } from './Link';

const StyledLink = styled(Link)`
  display: inline-flex;
  align-items: center;
  font-weight: 500;

  > svg {
    margin-left: ${(p) => p.theme.spacing()};
  }
`;

const ArrowLink = ({ children, ...props }: LinkProps) => (
  <StyledLink {...props}>
    {children || null}
    <ArrowIcon />
  </StyledLink>
);

export default ArrowLink;
