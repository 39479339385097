import {
  ReactNode,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import useSwr, { mutate } from 'swr';
import { useRouter } from 'next/router';
import { useRouter as appRouter } from 'next/navigation';
import {
  AccountInfoType,
  AccountProps,
  PaymentProps,
  Subscription,
  SiteLocale,
  Dog,
  WPDogPost,
  ShippingCountry,
  WpLocale,
  TokenData,
  WpProduct,
  Translate,
  CampaignSettings,
  Coupon,
  TranslateHTML,
  RewardProps,
  ExtendedProduct,
  ErrorResponse,
  DeliveryTypeCode,
  ShippingMethod,
  Courier,
  ProductType,
  Reward,
} from '../types';
import { getRefreshedToken, updateAccount, wpLogoutUser } from './api/user';
import { fetcher, noAuthFetcher } from './fetcher';
import {
  clearPersistedUser,
  clearRememberMe,
  clearSessionStorage,
  setPersistedUserToken,
} from './utils.persist';
import { mapLocaleFromSiteToWp, mapWpLocaleToSiteLocale } from './utils.locale';
import { TranslationsContext, UserContext, UserContextProps } from './context';
import {
  updateSubscriptions,
  updateDogs,
  deleteDogs,
  SubscriptionsDataProps,
} from './api/subscriptions';
import { getTranslation } from './translations';
import { convertDogToNew, convertDogToLegacy } from './utils.dataConversion';
import { getRecommendationState } from './utils.recommendation';
import { getCoupon, getUnauthorizedCoupon } from './api/coupon';
import { ModalContext, ModalState } from './context.modal';
import couponOnlyProducts from '../constants/couponOnlyProducts';
import { ModalOptions } from '../types/components';
import { reportError } from './bugsnag';
import { removePTagsFromString } from './utils';
import {
  addShippingTax,
  getTransportNames,
  isBudbeeAvailable,
  isInstaboxAvailable,
} from './utils.delivery';
import { isErrorResponse, isPostcode } from './utils.validation';
import { orderIsCompleted } from './utils.subscription';
import { getDay } from './utils.dates';
import {
  findProduct,
  findVariant,
  getSemanticProductCategory,
  premiumDogFoodEmission,
} from './utils.products';
import { useProducts } from './hooks.products';
import { isString } from 'lodash';
import { isCoupon } from '@lib/utils.coupon';
import { CampaignWithCoupon } from 'pages/api/campaign';

export interface CancelReason {
  cancel_reason: string;
  recipe_not_suitable?: string;
  don_t_like_subscription_model?: string;
  service_didn_t_match_expectations?: string;
}

interface SwrOptions {
  revalidateOnFocus?: boolean;
}

interface UpdateSubscriptionInput extends Subscription {
  coupon_code?: string;
  move_and_activate?: boolean;
  cancel_reason?: CancelReason;
  open_cancel_field?: string;
  remove_coupon_codes?: string[];
}

export function useIsLoggedIn() {
  const [{ loading, user }] = useContext(UserContext);
  return { loading, isLoggedIn: !!user, token: user?.token };
}

export function useToken() {
  const [{ user }] = useContext(UserContext);

  return (user?.token || getRecommendationState().token || '') as string;
}

export const useLoginUser = () => {
  const [, setAppState] = useContext(UserContext);

  return (user: TokenData, remember_me: boolean, persist?: string[]) => {
    // cleanup things that could be left there from eg. recommendation flow
    clearSessionStorage((s) => !(persist || []).includes(s));
    /* This cache clear is important as there could be pending requests on the bg. when user logs out. Those get
     * saved to Memo in swr, which is an issue. This removes them on login so user doesnt gain access to previous user's
     * data on the UI. A forced refresh would solve any issues, but not ideal.
     *
     * This used to be cache.clear() but since swr 2.0.0 has to be done using mutate.
     * pattern: matching function: match all keys
     * undefined: new value
     * false: revalidate
     */

    mutate(() => true, undefined, false);

    if (remember_me) {
      setPersistedUserToken(user.token);
    } else {
      clearRememberMe();
    }

    setAppState((s) => ({ ...s, loading: false, user }));
  };
};

interface UpdateAccountProps {
  type: AccountInfoType;
  payload: {};
}

interface AccountWpProps extends Omit<AccountProps, 'locale'> {
  locale: WpLocale;
}

const mapAccountProps = ({
  locale,
  ...account
}: AccountWpProps): AccountProps => ({
  ...account,
  locale: mapWpLocaleToSiteLocale(locale),
});

export function useAccount(options: SwrOptions = {}) {
  const { isLoggedIn: shouldFetch } = useIsLoggedIn();
  const token = useToken();
  const {
    data,
    mutate: accountMutate,
    ...rest
  } = useSwr<AccountWpProps>(
    shouldFetch ? `${process.env.NEXT_PUBLIC_WP_ALVAR_API}/account` : null,
    (url) => fetcher(url, { token }),
    { revalidateOnFocus: false, ...options },
  );

  const { loading = shouldFetch ? !data : false, ...accountProps } = data || {};

  const updateAccountData = async ({ type, payload }: UpdateAccountProps) => {
    accountMutate((s: any) => ({ ...s, loading: true }), false);
    const updatedData = await updateAccount({
      token,
      type,
      payload,
    });

    if (updatedData) {
      accountMutate((s) => ({ ...s, ...updatedData, loading: false }));
      return updatedData;
    }

    accountMutate((s: any) => ({ ...s, loading: false }));
    // Service is down probably
    return { error: 'Failure' };
  };

  return {
    account: mapAccountProps(accountProps as AccountWpProps),
    loading,
    updateLang: async (locale: SiteLocale) => {
      if (shouldFetch) {
        await updateAccountData({
          type: 'user',
          payload: {
            locale: mapLocaleFromSiteToWp(locale),
          },
        });
      }
    },
    updateAccount: updateAccountData,
    ...rest,
  };
}

interface UsePaymentProps {
  data: null | PaymentProps;
  stripe_pkey: null | string;
  loading?: boolean;
}

export const useShippingCountry = () => {
  const { account, loading: accountLoading } = useAccount();
  const {
    shipping_country,
  }: {
    shipping_country: ShippingCountry | undefined;
  } = getRecommendationState();

  return {
    country: shipping_country || account?.shipping?.country || undefined,
    loading: shipping_country ? false : accountLoading,
  };
};

export function usePayment() {
  const token = useToken();
  const {
    data,
    mutate: mutatePayment,
    ...rest
  } = useSwr<UsePaymentProps>(
    token ? `${process.env.NEXT_PUBLIC_WP_ALVAR_API}/card` : null,
    (url) => fetcher(url, { token }),
    { revalidateOnFocus: false },
  );

  const {
    data: payment = [],
    loading = !data,
    stripe_pkey: stripePublicKey,
  } = data || {};

  return {
    payment,
    loading,
    stripePublicKey,
    // addCard: async (paymentMethod: {}) => {
    //   mutatePayment((s: any) => ({ ...s, loading: true }), false);
    //   const updatedData = await updatePaymentMethod({
    //     token,
    //     payload: paymentMethod,
    //   });

    //   if (updatedData) {
    //     mutatePayment(
    //       (s: any) => ({ ...s, loading: false, data: updatedData }),
    //       false,
    //     );
    //     return updatedData;
    //   }

    //   mutatePayment((s: any) => ({ ...s, loading: false }));
    //   return null;
    // },
    ...rest,
  };
}

interface UsePaymentKeyProps {
  stripe_pkey: null | string;
  loading?: boolean;
}

export function usePaymentKey() {
  const {
    data,
    mutate: mutatePayment,
    ...rest
  } = useSwr<UsePaymentKeyProps>(`/api/payment-key`, noAuthFetcher, {
    revalidateOnFocus: false,
  });

  const { loading = !data, stripe_pkey: stripePublicKey } = data || {};

  return {
    loading,
    stripePublicKey,
    ...rest,
  };
}

export function useLogout() {
  const [, setAppState] = useContext(UserContext);
  const { replace } = appRouter();

  return async (cb?: () => void) => {
    setAppState((s: UserContextProps) => ({ ...s, loading: true }));

    // SWR caches to memo. Important to clear all keys.
    mutate(() => true, undefined, false);

    setAppState((s: UserContextProps) => ({
      ...s,
      loading: false,
      user: null,
    }));
    clearPersistedUser();
    clearSessionStorage();
    wpLogoutUser();
    replace('/');
    if (cb) {
      cb();
    }
  };
}

export const useLogin = () => {
  const [, setAppState] = useContext(UserContext);
  const { push } = useRouter();

  return (user: UserContextProps['user'], redirectTo?: string) => {
    if (redirectTo) {
      push(redirectTo);
    }
    setAppState((s: UserContextProps) => ({ ...s, user }));
  };
};

export function useTokenRefresh() {
  const [{ user }, setAppState] = useContext(UserContext);

  return async () => {
    if (user?.token) {
      getRefreshedToken(user?.token).then((refreshedUser) => {
        if (refreshedUser && refreshedUser?.token) {
          setAppState((s: UserContextProps) => ({ ...s, user: refreshedUser }));
        }
      });
    }
  };
}

export type MappedSubscriptions = Record<number, Subscription>;

const mapSubscriptionProducts = (
  items: WpProduct[],
  status: Subscription['subscription_status'],
  couponProducts: ExtendedProduct[],
): WpProduct[] =>
  items.map(({ unitPrice, ...item }) => {
    /*
     * it's a campaign product if there's no price and it's not discounted.
     * Campaign products are as is, no removal or editing
     * The name of product is used as key for translations and other info becasue otherwise it would have to be in WP
     */
    const campaignProduct =
      (!couponProducts.find(
        (couponOnlyProduct) =>
          couponOnlyProduct.wordpressId === item.product_id,
      ) &&
        !Number.parseFloat(`${unitPrice || 0}`) &&
        (item.discount === false || typeof item.discount === 'undefined') &&
        !item.recipe_item) ||
      undefined;

    return {
      ...item,
      campaignProduct:
        campaignProduct && status === 'trial'
          ? 'trial_incentive'
          : campaignProduct,
      unitPrice: campaignProduct ? 0 : unitPrice,
    };
  });

const seriouslyGetThatDogsId = ({
  dogs,
  product_id,
}: {
  dogs?: WPDogPost[];
  product_id: number;
}): number =>
  dogs?.find(({ product_id: pid }) => pid === product_id)?.dog_id || 0;

export const useLocale = (): SiteLocale => {
  const { query } = useRouter();
  const locale =
    isString(query.locale) &&
    Object.values(SiteLocale).find(
      (l) => `${l}` === (query.locale as SiteLocale),
    );

  return locale || SiteLocale.EN;
};

export function useTranslation(): Translate {
  const locale = useLocale();
  const translations = useContext(TranslationsContext);

  if (!locale) {
    console.error('Cant translate without locale set.');
    return (key: string) => key;
  }

  return (key: string, args, options = { fallback: null }) => {
    if (!key) {
      throw new Error('can not translate without key');
    }
    return getTranslation({
      locale,
      defaultLocale: SiteLocale.EN,
      key,
      translations,
      args,
      options,
    });
  };
}

export function useCouponOnlyProducts(): ExtendedProduct[] {
  const t = useTranslation();
  return couponOnlyProducts.map((p) => ({
    ...p,
    title: t(p.title),
    shortTitle: t(p.shortTitle),
  }));
}

const mapSubscriptions = (
  subscriptions: Subscription[],
  couponProducts: ExtendedProduct[],
  dogs?: WPDogPost[],
): MappedSubscriptions =>
  // there are some issues getting the name
  subscriptions.reduce(
    (acc: any, { items, ...sub }: Subscription): MappedSubscriptions => {
      const dogId =
        sub.dog_id ||
        seriouslyGetThatDogsId({
          dogs,
          product_id: subscriptions[0].items[0].product_id as number,
        });
      if (!dogId) {
        console.error('Subscription data invalid, missing dog_id.');
        return acc;
      }
      return {
        ...acc,
        [dogId]: {
          ...sub,
          items: mapSubscriptionProducts(
            items,
            sub.subscription_status,
            couponProducts,
          ),
        },
      };
    },
    {},
  );

const mapDogs = (dogs: WPDogPost[]): Dog[] => dogs.map(convertDogToNew);

const initialSubscriptionsProps = {
  subscriptions: undefined,
  loading: true,
  dogs: undefined,
  notices: undefined,
  delivery: undefined,
  dates: {
    last_payment: null,
    next_payment: null,
    add_payment: null,
  },
};

interface UpdateItems extends Pick<Subscription, 'items'> {
  dogId: Subscription['dog_id'];
}

/*
 * revalidateOnFocus set to false, because the data os remapped, which causes eg. forms to render during edit.
 * Also this data should not change
 */
export const useSubscriptions = (options: SwrOptions = {}) => {
  const { isLoggedIn: shouldFetch } = useIsLoggedIn();
  const token = useToken();
  const [isLocallyMutated, setLocallyMutated] = useState(false);
  const couponProducts = useCouponOnlyProducts();
  const {
    data,
    mutate: subscriptionMutate,
    ...rest
  } = useSwr<SubscriptionsDataProps>(
    shouldFetch
      ? `${process.env.NEXT_PUBLIC_WP_ALVAR_API}/subscriptions`
      : null,
    (url) => fetcher(url, { token }),
    { revalidateOnFocus: false, isPaused: () => isLocallyMutated, ...options },
  );

  useEffect(
    () => () => {
      if (isLocallyMutated) {
        setLocallyMutated(false);
      }
    },
    [isLocallyMutated],
  );

  const { subscriptions, dogs, delivery, loading, ...subscriptionMeta } =
    data || { ...initialSubscriptionsProps, loading: shouldFetch };

  return {
    loading,
    dogs: dogs?.length ? mapDogs(dogs as WPDogPost[]) : undefined,
    subscriptions: subscriptions?.length
      ? mapSubscriptions(subscriptions, couponProducts, dogs)
      : undefined,
    updateItems: ({ dogId, items }: UpdateItems) => {
      // only local changes, no updating single item or items to API
      if (!loading && subscriptions) {
        let mutated = false;
        subscriptionMutate(
          (s: any) => ({
            ...s,
            subscriptions: s.subscriptions.map((sub: Subscription) => {
              if (sub.dog_id === dogId) {
                mutated = true;
                return {
                  ...sub,
                  items,
                };
              }
              return sub;
            }),
          }),
          false,
        );
        if (mutated && !isLocallyMutated) {
          setLocallyMutated(true);
        }
      }
    },
    updateSubscription: async ({ id, ...sub }: UpdateSubscriptionInput) => {
      subscriptionMutate((s: any) => ({ ...s, loading: true }), false);

      // When no 'id' is given to subscription, a cancelled subscription can be re-activated
      const updatedData = await updateSubscriptions({
        token,
        payload: { subscriptions: [id > 0 ? { ...sub, id } : sub] },
      });

      if (updatedData) {
        subscriptionMutate(
          (s) => ({
            ...s,
            ...updatedData,
            loading: false,
          }),
          false,
        );
        return updatedData;
      }

      subscriptionMutate((s: any) => ({ ...s, loading: false }));
      if (isLocallyMutated) {
        setLocallyMutated(false);
      }
      return null;
    },
    updateDog: async ({
      name: dog_name,
      gram_need = '',
      body_condition = 3,
      ...dog
    }: Dog) => {
      const updatedData = await updateDogs({
        token,
        payload: {
          dogs: [
            convertDogToLegacy({
              ...dog,
              dog_name,
              gram_need: parseInt(gram_need),
              body_condition: body_condition.toString(),
            }),
          ],
        },
      });

      if (updatedData) {
        subscriptionMutate(
          (s) => ({
            ...s,
            ...updatedData,
            loading: false,
          }),
          false,
        );
        return updatedData;
      }

      subscriptionMutate((s: any) => ({ ...s, loading: false }));
      // Service is down probably
      return { error: 'Failure' };
    },
    deleteDog: async (minimumDog: { dog_id: number; name: string }) => {
      subscriptionMutate((s: any) => ({ ...s, loading: true }), false);
      const updatedData = await deleteDogs({
        token,
        payload: { dogs: [minimumDog] },
      });

      if (updatedData) {
        subscriptionMutate(
          (s) => ({
            ...s,
            ...updatedData,
            loading: false,
          }),
          false,
        );
        return updatedData;
      }

      subscriptionMutate((s: any) => ({ ...s, loading: false }));
      // Service is down probably
      return { error: 'Failure' };
    },
    delivery,
    ...subscriptionMeta,
    ...rest,
  };
};

export function useHtmlTranslation(): TranslateHTML {
  const t = useTranslation();

  return (key: string, args: any, options = { fallback: null }) => {
    if (!key) {
      throw new Error('can not translate without key');
    }

    // This comes from our own system, so hacker would need to have access there to make harm.
    // eslint-disable-next-line react/no-danger
    return (
      <span
        data-t={key}
        dangerouslySetInnerHTML={{ __html: t(key, args, options) }}
      />
    );
  };
}

export const useCampaigns = (settings: CampaignSettings) => {
  const token = useToken();
  const { country } = useShippingCountry();
  const [campaigns, setCampaigns] = useState<CampaignWithCoupon[]>([]);

  const { data, isLoading } = useSwr<CampaignWithCoupon[] | ErrorResponse>(
    country
      ? `/api/campaign?country=${country}&location=${settings.location}`
      : '',
    (url) => fetcher(url, { token }),
    { revalidateOnFocus: false },
  );

  useEffect(() => {
    async function filterCampaigns() {
      if (!!data && !isErrorResponse(data)) {
        const availableToUser = await data.reduce(
          async (acc, { couponCode, type, ...campaign }) => {
            const memo = await acc;
            if (!couponCode && type === 'referral') {
              const coupon = {} as Coupon;
              memo.push({ ...campaign, type, couponCode, coupon });
              return memo;
            }

            const couponData = token
              ? await getCoupon({
                  coupon_code: couponCode,
                  token,
                })
              : await getUnauthorizedCoupon(couponCode);

            if (isCoupon(couponData)) {
              const couponLimit = couponData.limit_usage_to_x_items || Infinity;
              const hasUsesLeft = couponData.usage_count < couponLimit;
              if (hasUsesLeft) {
                memo.push({
                  ...campaign,
                  type,
                  couponCode,
                  coupon: couponData,
                });
              }
            }
            return memo;
          },
          Promise.resolve([] as CampaignWithCoupon[]),
        );
        setCampaigns(availableToUser);
      }
    }
    filterCampaigns();
  }, [data]);

  return {
    loading: isLoading,
    campaigns,
  };
};

interface CouponState {
  error?: null | string;
  valid?: boolean;
  loading?: boolean;
  coupon?: Coupon;
  submitted: boolean;
}

export const useCoupon = () => {
  const initialState = { submitted: false };
  const [{ error, valid, loading, coupon, submitted }, setState] =
    useState<CouponState>(initialState);
  const token = useToken();
  const t = useTranslation();

  const validate = useCallback(
    async (couponCode: string) => {
      try {
        if (couponCode && !loading) {
          setState((s) => ({
            ...s,
            error: null,
            loading: true,
            submitted: true,
          }));
          const couponData = await getCoupon({
            token,
            coupon_code: couponCode.trim(),
          });

          if (
            couponData &&
            typeof (couponData as { error: string })?.error === 'undefined'
          ) {
            setState((s) => ({
              ...s,
              coupon: couponData as Coupon,
              valid: true,
              loading: false,
            }));
            return couponData as Coupon;
          }

          setState((s) => ({
            ...s,
            loading: false,
            valid: false,
            error: t('order.errors.coupon_not_valid'),
          }));
        }
      } catch (err) {
        console.error(err);
        setState((s) => ({
          ...s,
          loading: false,
          valid: false,
          error: t('order.errors.something_went_wrong'),
        }));
      }
      return null;
    },
    [t, token, loading],
  );

  const reset = () => {
    setState(initialState);
  };

  const hideCoupon = (coupon: Coupon): boolean =>
    coupon.hide === true || coupon.hide === '1';

  return {
    coupon,
    error,
    valid,
    loading,
    submitted,
    reset,
    validate,
    setError: (b: boolean) =>
      setState((s) => ({
        ...s,
        error: b ? t('order.errors.coupon_not_valid') : null,
      })),
    hideCoupon,
  };
};

interface IModalContext {
  resetModal: () => void;
  setModal: (value: SetStateAction<ModalState>) => void;
  openModal: (component: ReactNode, options?: ModalOptions) => void;
  closeModal: () => void;
  isOpen: boolean;
  options: ModalOptions;
  modalId: string;
}

export function useModal(): IModalContext {
  const context = useContext(ModalContext);
  if (context === undefined) {
    throw new Error('useModal must be used within a ModalProvider');
  }
  return context as IModalContext;
}

export const useRewards = () => {
  const token = useToken();
  const { country } = useShippingCountry();

  const { data, error } = useSwr<RewardProps>(
    country
      ? `${process.env.NEXT_PUBLIC_WP_ALVAR_API}/rewards?country=${country}`
      : '',
    (url) => fetcher(url, { token }),
    { revalidateOnFocus: false },
  );

  if (error) {
    reportError(error);
  }

  return {
    loading: !data && !error,
    error,
    user_reward_step: data?.user_reward_step,
    dog_rewards: data?.dog_rewards,
    unclaimedRewards: (dogId: number): Reward[] =>
      (data?.dog_rewards?.[dogId]?.rewards || []).filter(
        (r) => r.available && !r.used,
      ),
  };
};

interface BlogResponse {
  id: number;
  link: string;
  title: {
    rendered: string;
  };
  excerpt: {
    rendered: string;
  };
  featured_media: number;
}

export interface BlogPreview {
  id: number;
  link: string;
  title: string;
  excerpt: string;
  src: string;
}
interface BlogPreviewState {
  loading: boolean;
  previews: BlogPreview[];
}

export function useBlogPreviews(tags?: string): BlogPreviewState {
  const [{ previews, loading }, setState] = useState<BlogPreviewState>({
    previews: [],
    loading: true,
  });

  useEffect(() => {
    async function fetchBlogImage(id: number) {
      try {
        const res = await fetch(
          `${process.env.NEXT_PUBLIC_WP_HOME}/wp-json/wp/v2/media/${id}`,
        );
        if (!res.ok) {
          return '';
        }
        const data = await res.json();
        return data.source_url;
      } catch (e) {
        reportError(e);
        return '';
      }
    }

    async function fetchBlogPosts() {
      try {
        setState((s) => ({ ...s, loading: true }));
        const res = await fetch(
          `${process.env.NEXT_PUBLIC_WP_HOME}/wp-json/wp/v2/posts${
            tags ? `?${new URLSearchParams({ tags }).toString()}` : ''
          }`,
        );

        if (!res?.ok) {
          setState((s) => ({ ...s, loading: false }));
        }

        const data = await res.json();
        const blogPreviews = await Promise.all(
          (data as BlogResponse[]).map(async (post) => {
            const {
              id,
              link,
              title: { rendered: title },
              excerpt: { rendered: renderedExcerpt },
              featured_media,
            } = post;

            const excerpt = removePTagsFromString(renderedExcerpt);
            const image = await fetchBlogImage(featured_media);

            return {
              id,
              link,
              title,
              excerpt,
              src: image,
            };
          }),
        );
        setState((s) => ({ ...s, loading: false, previews: blogPreviews }));
        return;
      } catch (e) {
        reportError(e);
      }
    }
    fetchBlogPosts();
  }, [tags]);

  return { previews, loading };
}

type ShippingPriceObject = {
  enabled: boolean;
  isFreeShippingMethod: boolean;
  minimumSpend: number;
  deliveryTypeCode: DeliveryTypeCode;
  grantsFreeShipping: boolean;
  grantsFreeOrDiscounted?: boolean;
  price: string;
  regularPrice: string;
  courier: Courier;
  shippingMethodType: ShippingMethod;
};

enum WcShippingMethodId {
  free = 'free_shipping',
  flatRate = 'flat_rate',
}

type WcShippingMethod = {
  title: string;
  enabled: boolean;
  method_id: WcShippingMethodId;
  settings: Record<string, any> & { label: string; value: string };
};

type ShippingResponse = WcShippingMethod[] | ErrorResponse;

const defaultShippingPriceObject = {
  cheapestPaidOption: undefined,
  freeShippingAvailable: false,
  minimumSpendForFreeDelivery: undefined,
  methods: [],
};

const getSubscriptionShippingPrice = (
  settings?: WcShippingMethod['settings'],
): number | undefined =>
  settings
    ? parseFloat(
        Object.values(settings)
          .find((s) => s.label.toLowerCase().includes('subscription shipping'))
          ?.value?.replace(',', '.'),
      )
    : undefined;

export interface ShippingPriceValues {
  cheapestPaidOption?: string;
  freeShippingAvailable: boolean;
  minimumSpendForFreeDelivery?: string;
  methods: ShippingPriceObject[];
}

export function useShippingPrice(
  country: ShippingCountry,
  coupons: Coupon[] = [],
  priceWithoutShipping?: number,
): ShippingPriceValues {
  const [priceSettings, setPriceSettings] = useState<ShippingPriceValues>(
    defaultShippingPriceObject,
  );

  const { data, error } = useSwr<ShippingResponse>(
    `/api/shipping-prices?country=${country}`,
    noAuthFetcher,
    { revalidateOnFocus: false },
  );

  const hasFreeShippingCoupon = coupons.some((c) => c?.free_shipping);

  useEffect(() => {
    if (data && Array.isArray(data)) {
      const wcData = data as WcShippingMethod[];
      const methods: ShippingPriceObject[] = wcData.reduce<
        ShippingPriceObject[]
      >((acc, { enabled, ...i }) => {
        if (enabled) {
          const isFreeShippingMethod = i.method_id === WcShippingMethodId.free;
          const isDiscountedMethod =
            !!i.settings.min_amount_for_discounted_price?.value;
          const subscriptionPrice = getSubscriptionShippingPrice(i.settings);
          const price = isFreeShippingMethod ? 0 : subscriptionPrice;
          if (!Number.isNaN(price)) {
            let regularPrice: number | undefined;
            const shippingMethodType =
              i.settings.shipping_delivery_method.value;
            const deliveryTypeCode = i.settings.shipping_delivery_type.value;
            const courier = i.settings.shipping_delivery_courier.value;
            if (isFreeShippingMethod || isDiscountedMethod) {
              const paidVersion = wcData.find(
                (x) =>
                  x.enabled &&
                  x.method_id === WcShippingMethodId.flatRate &&
                  x.settings.shipping_delivery_type.value ===
                    deliveryTypeCode &&
                  x.settings.shipping_delivery_courier.value === courier,
              )?.settings;
              regularPrice = getSubscriptionShippingPrice(paidVersion);
            } else {
              regularPrice = price;
            }

            if (regularPrice) {
              let availableAtSpend = '0';
              if (i.settings.min_amount && i.settings.min_amount?.value) {
                availableAtSpend = i.settings.min_amount.value;
              } else if (
                i.settings.min_amount_for_discounted_price &&
                i.settings.min_amount_for_discounted_price?.value
              ) {
                availableAtSpend =
                  i.settings.min_amount_for_discounted_price?.value;
              }

              const minimumSpend: number = parseFloat(availableAtSpend);
              const minimumReached =
                typeof priceWithoutShipping === 'number' &&
                minimumSpend <= priceWithoutShipping;
              const freeBecauseOfMinimum =
                minimumReached && i.settings.requires?.value !== 'coupon';
              const freeBecauseOfCoupon =
                hasFreeShippingCoupon &&
                (i.settings.requires?.value === 'coupon' ||
                  i.settings.requires?.value === 'either');

              const grantsFreeShipping =
                isFreeShippingMethod &&
                (freeBecauseOfMinimum || freeBecauseOfCoupon);
              const grantsDiscountedShipping =
                isDiscountedMethod && minimumReached;

              const grantsFreeOrDiscounted =
                grantsFreeShipping || grantsDiscountedShipping;

              return [
                ...acc,
                {
                  enabled,
                  deliveryTypeCode,
                  shippingMethodType,
                  isFreeShippingMethod,
                  minimumSpend,
                  courier,
                  grantsFreeShipping,
                  grantsFreeOrDiscounted,
                  price: addShippingTax(price, country),
                  regularPrice: addShippingTax(regularPrice, country),
                },
              ];
            }
          }
        }
        return acc;
      }, []);

      const freeShippingAvailable = methods.some(
        (method) => method.grantsFreeShipping,
      );

      const cheapestPaidOption = methods.reduce<string>((acc, method) => {
        if (
          !acc ||
          (acc && parseFloat(`${method.regularPrice}`) < parseFloat(`${acc}`))
        ) {
          return method.regularPrice;
        }
        return acc;
      }, methods[0]?.regularPrice);

      const offeredMethods = methods.reduce<ShippingPriceObject[]>((acc, m) => {
        const alreadyAdded = acc.find(
          (a) => a.deliveryTypeCode === m.deliveryTypeCode,
        );

        if (
          (alreadyAdded && !m.grantsFreeOrDiscounted) ||
          (m.isFreeShippingMethod && !m.grantsFreeShipping)
        ) {
          return acc;
        }
        return [
          ...acc.filter((a) => a.deliveryTypeCode !== m.deliveryTypeCode),
          m,
        ];
      }, []);

      const minimumSpendForFreeDelivery = methods.reduce<string | undefined>(
        (acc, method) => {
          if (
            (!acc && method.isFreeShippingMethod) ||
            (method.isFreeShippingMethod &&
              method.minimumSpend &&
              method.minimumSpend < parseFloat(`${acc}`))
          ) {
            return method.minimumSpend.toString();
          }
          return acc;
        },
        undefined,
      );
      setPriceSettings({
        cheapestPaidOption,
        freeShippingAvailable: freeShippingAvailable || hasFreeShippingCoupon,
        minimumSpendForFreeDelivery,
        methods: offeredMethods,
      });
    }
  }, [country, hasFreeShippingCoupon, priceWithoutShipping, data]);

  return priceSettings;
}

export interface ShippingOption {
  value: string;
  label: string;
  disabled: boolean;
  loading: boolean;
  price: number | undefined;
  regularPrice: number | undefined;
  discountedPrice: number | undefined;
  freeShippingAvailable: boolean;
  minimumSpend: number | undefined;
}

interface DeliveryTypeOption {
  value: string;
  node: string;
  countries: ShippingCountry[];
}

export const useShippingOptions = (
  method: ShippingMethod,
  postcode: undefined | string,
  coupons: Coupon[],
  priceWithoutShipping?: number,
): {
  deliveryOptions: ShippingOption[];
  deliveryTypeOptions: DeliveryTypeOption[];
} => {
  const t = useTranslation();
  const { country } = useShippingCountry();
  const { methods } = useShippingPrice(country, coupons, priceWithoutShipping);
  const transportNames = getTransportNames(country) || [];
  const availableOptions = transportNames.map((value) => ({
    value,
    label: `form.delivery.${value}`,
    disabled: value === 'budbee' || value === 'instabox',
    loading: false,
    price: undefined,
    regularPrice: undefined,
    discountedPrice: undefined,
    freeShippingAvailable: false,
    minimumSpend: undefined,
  }));
  const [deliveryOptions, setDeliveryOptions] =
    useState<ShippingOption[]>(availableOptions);

  useEffect(() => {
    async function updateOptions() {
      if (methods.length === 0) {
        setDeliveryOptions(
          availableOptions.map((o) => ({ ...o, loading: true })),
        );
        return;
      }

      if (postcode !== undefined && isPostcode(postcode, country)) {
        setDeliveryOptions(
          availableOptions.map((o) => ({
            ...o,
            loading: o.value === 'budbee' || o.value === 'instabox',
          })),
        );
        const budbeeIsAvailable = await isBudbeeAvailable(
          country,
          postcode,
          method,
        );
        const instaboxIsAvailable = await isInstaboxAvailable(
          country,
          postcode,
          method,
        );
        const newOptions = availableOptions.reduce((acc, o) => {
          const deliveryType = methods.find(
            (m) =>
              m.courier.toLowerCase() === o.value.toLowerCase() &&
              m.shippingMethodType === method,
          );
          const shippingMethods = methods.filter(
            (m) => m.deliveryTypeCode == deliveryType?.deliveryTypeCode,
          );

          if (shippingMethods.length === 0) {
            return acc;
          }

          const freeShippingMethodSettings = shippingMethods.find(
            (m) => m.isFreeShippingMethod,
          );
          const paidShippingMethods = shippingMethods
            .filter((m) => !m.isFreeShippingMethod)
            .sort((a, b) => parseFloat(b.price) - parseFloat(a.price));
          const paidShippingMethodSettings = paidShippingMethods[0];
          const discountedShippingMethodSettings = shippingMethods
            .filter((m) => !m.isFreeShippingMethod && m.grantsFreeOrDiscounted)
            .sort((a, b) => parseFloat(b.price) - parseFloat(a.price))[0];

          const price = parseFloat(
            freeShippingMethodSettings?.price ||
              paidShippingMethodSettings?.price ||
              '',
          );

          const regularPrice = parseFloat(
            freeShippingMethodSettings?.regularPrice ||
              paidShippingMethodSettings?.regularPrice ||
              shippingMethods[0]?.regularPrice ||
              '',
          );

          const discountedPrice = discountedShippingMethodSettings?.price
            ? parseFloat(discountedShippingMethodSettings?.price || '')
            : undefined;

          return [
            ...acc,
            {
              ...o,
              loading: false,
              disabled:
                (o.value === 'budbee' && !budbeeIsAvailable) ||
                (o.value === 'instabox' && !instaboxIsAvailable),
              price,
              regularPrice,
              discountedPrice,
              freeShippingAvailable: !!freeShippingMethodSettings,
              minimumSpend:
                freeShippingMethodSettings?.minimumSpend ||
                discountedShippingMethodSettings?.minimumSpend,
            },
          ];
        }, [] as ShippingOption[]);
        setDeliveryOptions(newOptions);
      } else {
        setDeliveryOptions(availableOptions);
      }
    }
    updateOptions();
  }, [country, method, postcode, priceWithoutShipping, methods]);

  const deliveryTypeOptions = [
    {
      value: 'post',
      node: t('form.delivery.post'),
      countries: [ShippingCountry.FI, ShippingCountry.DK, ShippingCountry.SE],
    },
    {
      value: 'home',
      node: t('form.delivery.home'),
      countries: [ShippingCountry.FI, ShippingCountry.DK, ShippingCountry.SE],
    },
  ].filter(({ countries }) => countries.includes(country));

  return {
    deliveryOptions,
    deliveryTypeOptions,
  };
};

export const getProductEmissionData = (
  p: ExtendedProduct & { quantity: number },
) => {
  const v = findVariant(p.wordpressId, [p]);
  let emission: number | undefined;
  let emissionSaving: number | undefined;
  let comparison: number | undefined;

  const emissionPerQty =
    p.type === ProductType.DryFood ? p.emission : v?.emission;
  const comparisonNumber =
    v?.emissionComparison ||
    p.emissionComparison ||
    (p.type === ProductType.DryFood && premiumDogFoodEmission);

  if (emissionPerQty) {
    emission = emissionPerQty * p.quantity;
    if (comparisonNumber) {
      emissionSaving = comparisonNumber * p.quantity - emission;
      comparison = Math.round((emissionPerQty / comparisonNumber) * 100 - 100);
    }
  }
  return { emission, emissionSaving, comparison };
};

const defaultEmissionObject = {
  [ProductType.DryFood]: 0,
  [ProductType.WetFood]: 0,
  [ProductType.Treat]: 0,
  [ProductType.Chew]: 0,
  [ProductType.Supplement]: 0,
  [ProductType.Sauce]: 0,
  [ProductType.Accessory]: 0,
  total: 0,
};

export const useAccountEmissions = () => {
  const { account } = useAccount();
  const { products = [] } = useProducts();

  return useMemo(
    () =>
      Object.values(account.history || {}).reduce<any>(
        (acc, value) => {
          const { dog_id, items, status, date_created } = value;
          const orderYear = getDay(date_created).year().toString();

          if (!orderIsCompleted(status)) {
            return acc;
          }

          const dogValues = {
            ...(acc[dog_id] || {
              allTime: {
                emissions: { ...defaultEmissionObject },
                savings: { ...defaultEmissionObject },
              },
            }),
            [orderYear]: {
              savings: {
                ...(acc[dog_id]?.[orderYear]?.savings || defaultEmissionObject),
              },
              emissions: {
                ...(acc[dog_id]?.[orderYear]?.emissions ||
                  defaultEmissionObject),
              },
            },
          };

          const accountValues = {
            ...acc.account,
            [orderYear]: {
              savings: {
                ...(acc.account[orderYear]?.savings || defaultEmissionObject),
              },
              emissions: {
                ...(acc.account[orderYear]?.emissions || defaultEmissionObject),
              },
            },
          };

          items.forEach(({ product_id, quantity }) => {
            const product = findProduct(product_id, products);
            if (product) {
              const { emission, emissionSaving } = getProductEmissionData({
                ...product,
                wordpressId: product_id,
                quantity,
              });

              const category =
                product.type === ProductType.Sauce
                  ? ProductType.Supplement
                  : getSemanticProductCategory(product.type, product.id);

              if (category && emission && emissionSaving) {
                dogValues[orderYear].savings[category] += emissionSaving;
                dogValues[orderYear].savings.total += emissionSaving;
                dogValues[orderYear].emissions[category] += emission;
                dogValues[orderYear].emissions.total += emission;

                dogValues.allTime.savings[category] += emissionSaving;
                dogValues.allTime.savings.total += emissionSaving;
                dogValues.allTime.emissions[category] += emission;
                dogValues.allTime.emissions.total += emission;

                accountValues[orderYear].savings[category] += emissionSaving;
                accountValues[orderYear].savings.total += emissionSaving;
                accountValues[orderYear].emissions[category] += emission;
                accountValues[orderYear].emissions.total += emission;

                accountValues.allTime.savings[category] += emissionSaving;
                accountValues.allTime.savings.total += emissionSaving;
                accountValues.allTime.emissions[category] += emission;
                accountValues.allTime.emissions.total += emission;
              }
            }
          });

          return { ...acc, account: accountValues, [dog_id]: dogValues };
        },
        {
          account: {
            allTime: {
              savings: { ...defaultEmissionObject },
              emissions: { ...defaultEmissionObject },
            },
          },
        },
      ),
    [account],
  );
};

type ProductWithHistoricQty = ExtendedProduct & { quantity: number };

export const useMostOrderedProducts = (limit?: number) => {
  const { account } = useAccount();
  const { products = [] } = useProducts();

  const itemCounts = useMemo(
    () =>
      Object.values(account.history || {}).reduce<
        Record<string, Record<string, number>>
      >((acc, value) => {
        const { dog_id, items, status } = value;

        if (!orderIsCompleted(status)) {
          return acc;
        }

        const dog = {
          ...acc[dog_id],
        };

        const account = {
          ...acc.account,
        };

        items.forEach(({ product_id, quantity }) => {
          dog[product_id] = (dog[product_id] || 0) + quantity;
          account[product_id] = (account[product_id] || 0) + quantity;
        });

        return { ...acc, [dog_id]: dog, account };
      }, {}),
    [account],
  );

  const sortedProducts = Object.entries(itemCounts).reduce<{
    [key: string]: ProductWithHistoricQty[];
  }>((acc, [key, val]) => {
    const sorted = Object.entries(val).sort(([_, a], [__, b]) => b - a);
    const populated = sorted.reduce<ProductWithHistoricQty[]>(
      (acc, [id, quantity]) => {
        if (limit && limit <= acc.length) {
          return acc;
        }
        const wordpressId = parseInt(id);
        const p = findProduct(wordpressId, products);
        return p ? [...acc, { ...p, wordpressId, quantity }] : acc;
      },
      [],
    );

    return {
      ...acc,
      [key]: populated,
    };
  }, {});

  return sortedProducts;
};

export interface FAQ {
  q: string;
  a: string;
}

type FAQResponse = FAQ[] | ErrorResponse;

const responseIsFAQ = (response: any): response is FAQ[] =>
  Array.isArray(response) &&
  response.every(
    (i) => !!i.q && !!i.a && typeof i.q === 'string' && typeof i.a === 'string',
  );

export const useFAQ = (): { loading: boolean; faq: FAQ[] } => {
  const locale = useLocale();
  const { data = [], error } = useSwr<FAQResponse>(
    `/api/faq?lang=${locale}`,
    noAuthFetcher,
    {
      revalidateOnFocus: false,
    },
  );

  let faq: FAQ[] = [];
  const loading = !data && !error;

  if (responseIsFAQ(data)) {
    faq = data;
  }
  return { loading, faq };
};
