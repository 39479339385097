import { WizardFormValues } from '@components/forms/Recommendation/types';
import { DietItem, Dog, WPDogPost, SpecialNeedsObject } from '../types';
import { parseWeight } from './utils.dog';
/*
 * This doesnt really belong in the app, but it's probably the fastest solution for now.
 * Basically migration between old data form and new. Old being WP DB and new Recommendation API
 */

const specialNeedsConversions: [string, SpecialNeedsObject['name'] | null][] = [
  ['ei erityistarpeita', null],
  ['leikattu', 'neutered'],
  ['herkkä vatsa', 'delicateStomach'],
  ['herkkä iho', 'sensitiveSkin'],
  ['jäykät nivelet', 'jointStiffness'],
  ['perso ruoalle', 'greedyEater'],
  ['nirso ruoalle', 'pickyEater'],
  ['tassujen kutina', 'itchyPaws'],
  ['löysä kakka', 'loosePoop'],
];

export const convertSpecialNeedToLegacy = (
  key: SpecialNeedsObject['name'],
): string =>
  (specialNeedsConversions.find(([, match]) => match === key) || [])[0] || key;

export const convertSpecialNeedsToLegacy = (
  keys: SpecialNeedsObject['name'][],
): string[] => keys.map(convertSpecialNeedToLegacy);

export const convertSpecialNeedToNew = (
  key: string,
): string | SpecialNeedsObject['name'] =>
  (specialNeedsConversions.find(([match]) => match === key) || [])[1] || key;

export const convertSpecialNeedsToNew = (
  keys: string[],
): [] | SpecialNeedsObject['name'][] =>
  (keys || []).map(convertSpecialNeedToNew);

/*
 * Convert body types
 */
const bodyTypeConversions: [string, number][] = [
  ['Under ideal', 3],
  ['Ideal', 5],
  ['Over ideal', 7],
];

export const convertBodyTypeToNew = (key: string): number | undefined =>
  (bodyTypeConversions.find(([match]) => match === key) || [])[1] ||
  (key ? parseInt(key, 10) : undefined);

export const convertBodyTypeToLegacy = (key: number | string): string =>
  (bodyTypeConversions.find(([, match]) => match === parseInt(`${key}`, 10)) ||
    [])[0] || `${key || ''}`;

/*
 * Convert activity levels
 */
const activityLevelConversions: [string, number][] = [
  ['<1', 1],
  ['1-3', 2],
  ['>3', 3],
];

export const convertActivityLevelToNew = (activityLevel?: string): string =>
  `${
    (activityLevelConversions.find(([match]) => match === activityLevel) || [
      '',
      activityLevel ? parseInt(activityLevel, 10) : '2',
    ])[1]
  }`;

export const convertActivityLevelToLegacy = (
  activityLevel: number | string,
): string =>
  (activityLevelConversions.find(
    ([, match]) => match === parseInt(`${activityLevel}`, 10),
  ) || [`${activityLevel || ''}`])[0];

const getDietSum = (
  dry: string | number,
  wet: string | number,
  treats: string | number,
  other: string | number,
): number => parseInt(`${+dry + +wet + +treats + +other}`, 10);

export const convertDogToNew = ({
  special_needs,
  special,
  body_type,
  body_condition,
  activity_level,
  preferred_ingredients,
  diet_share_dry: dry = '0',
  diet_share_wet: wet = '0',
  diet_share_treats: treats = '0',
  diet_share_other: otherFood = '0',
  ...dog
}: WPDogPost): Dog => {
  const dietSum = getDietSum(dry, wet, treats, otherFood);

  return {
    special_needs: convertSpecialNeedsToNew(
      (special_needs || special) as string[],
    ).filter(Boolean),
    preferred_ingredients: (preferred_ingredients || '').split(','),
    body_condition: convertBodyTypeToNew(body_type || body_condition || ''),
    activity_level: convertActivityLevelToNew(activity_level),
    dietShareDry: dietSum === 100 ? parseInt(`${dry}`, 10) : 100,
    dietShareWet: dietSum === 100 ? parseInt(`${wet}`, 10) : 0,
    dietShareTreats: dietSum === 100 ? parseInt(`${treats}`, 10) : 0,
    dietShareOther: dietSum === 100 ? parseInt(`${otherFood}`, 10) : 0,
    ...dog,
  };
};

const getGramNeed = (product?: DietItem): string | undefined => {
  if (!product) {
    return undefined;
  }
  const { dailyGrams, shareOfDiet } = product;
  return (dailyGrams / shareOfDiet).toFixed();
};

export interface ConvertDogToLegacyProps
  extends Pick<
    WizardFormValues,
    | 'dog_id'
    | 'dog_name'
    | 'birthday'
    | 'breed'
    | 'body_condition'
    | 'gender'
    | 'special_needs'
    | 'activity_level'
    | 'weight'
    | 'preferred_ingredients'
    | 'dietShareDry'
    | 'dietShareWet'
    | 'dietShareTreats'
    | 'dietShareOther'
  > {
  product?: DietItem;
  initial_recommendation?: string;
  initial_recommendation_id?: number;
  gram_need: number;
}

export const convertDogToLegacy = ({
  birthday,
  breed,
  dog_id,
  gender,
  weight,
  special_needs,
  preferred_ingredients,
  body_condition,
  activity_level,
  dog_name,
  dietShareDry,
  dietShareWet,
  dietShareTreats,
  dietShareOther,
  product,
}: ConvertDogToLegacyProps): WPDogPost => ({
  birthday,
  breed,
  dog_id,
  gender,
  weight: parseWeight(weight),
  special_needs: convertSpecialNeedsToLegacy(special_needs || ['']),
  preferred_ingredients: (preferred_ingredients || ['']).join(','),
  body_type: convertBodyTypeToLegacy(body_condition),
  body_condition: convertBodyTypeToLegacy(body_condition),
  activity_level: convertActivityLevelToLegacy(activity_level),
  name: dog_name,
  diet_share_dry: parseInt(`${dietShareDry}`).toString(),
  diet_share_wet: parseInt(`${dietShareWet}`).toString(),
  diet_share_treats: parseInt(`${dietShareTreats}`).toString(),
  diet_share_other: parseInt(`${dietShareOther}`).toString(),
  gram_need: getGramNeed(product),
  recipe: product?.shortTitle,
  product_id: product?.wordpressId,
});

export const getDlNeed = (gramsNeeded: number, density: number) =>
  ((gramsNeeded / density) * 10).toFixed(1);
