import { useRouter } from 'next/router';
import styled from 'styled-components';
import Image from './Image';
import Link from './Link';
import { useTranslation, useShippingCountry, useLocale } from '../lib/hooks';

import LINKS from '../constants/links';
import { getPaymentMethods } from '../lib/utils';
import { ShippingCountry } from '../types';
import { ThemeProps } from '@lib/theme';
import LogoutButton from './LogoutButton';
import { Small, Text } from '@components/Typography';
import FlexWrapper from '@components/wrappers/FlexWrapper';
import LanguageSelector from '@components/Navigation/LanguageSelector';
import { useIsLoggedIn } from '@lib/hooks';
import { mapLocaleToShippingCountry } from '@lib/utils.locale';
import ContactChannels from 'app/ui/navigation/contact-channels';
import { shippingCountries } from '@lib/utils.delivery';

interface ThemeAndMatch extends ThemeProps {
  matchMarketingSite: boolean;
}

const SiteFooter = styled.footer(
  ({ theme: { colors, constants }, matchMarketingSite }: ThemeAndMatch) => `
  position: relative;
  margin-top: 2rem; // distance to other elements
  padding-top: ${constants.footerWavesHeight}; // space taken by waves (below)
  
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: ${constants.footerWavesHeight};
    background-color: ${
      matchMarketingSite
        ? colors.highlightColor
        : colors.backgroundColorInverted
    };
    mask-image: url('/icons/waves.svg');
    -webkit-mask-image: url('/icons/waves.svg');
  }
`,
);

const UpperFooter = styled.div(
  ({
    theme: { colors, spacing, breakpoints },
    matchMarketingSite,
  }: ThemeAndMatch) => `
  display: flex;
  flex-direction: column;
  color: ${colors.primaryColorInverted};
  background: ${
    matchMarketingSite ? colors.highlightColor : colors.backgroundColorInverted
  };
  padding: ${spacing(2)};

  .logo {
    margin-bottom: ${spacing(2)};

    a {
      color: ${colors.primaryColorInverted};
      font-weight: 600;
    }
  }

  .footer-navigation {
    display: none;
  }

  @media only screen and (min-width: ${breakpoints.mdMax}) {
    display: grid;
    grid-template-columns: repeat(3, 1fr);

    .footer-section {
      padding: ${spacing()} ${spacing(2)};
    }

    .middle {      
      border-right: 1px solid ${colors.primaryColorInverted};;
      border-left: 1px solid ${colors.primaryColorInverted};;
    }

    .logo {
      margin-bottom: 0;

      .image {
        margin-bottom: ${spacing()};
      }
    }
    
    .footer-navigation {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-column-gap: ${spacing(2)};
      height: fit-content;
      
      & li {
        margin-bottom: ${spacing()};
      }
    }
  }
`,
);

const LowerFooter = styled(FlexWrapper)
  .attrs({ justify: 'center' })
  .withConfig({
    shouldForwardProp: (p) => !['matchMarketingSite'].includes(p),
  })(
  ({
    theme: { colors, breakpoints, spacing },
    matchMarketingSite,
  }: ThemeAndMatch) => `
  background: ${
    matchMarketingSite ? colors.card.lightGreen : colors.lowerFooterBackground
  };
  color: ${
    matchMarketingSite ? colors.textColorThin : colors.primaryColorInverted
  };
  display: grid;
  padding: ${spacing(2)} ${spacing()};
  grid-template-areas:
    'payment-methods'
    'links'
    'rights-reserved';

  .rights-reserved {
    grid-area: rights-reserved;
    font-weight: 400;
    font-size: 12px;
    color: ${matchMarketingSite ? colors.highlightColor : colors.faded};
    margin: auto 0;
  }

  .links {
    grid-area: links;
    margin: 0 auto 0.5rem;
    
    & a {
      text-decoration: underline;
      font-weight: 500;
      padding-right: ${spacing(0.5)};
      
      &:not(:first-child) {
        padding-left: ${spacing(0.5)};
      }
    }
  }
  
  .payment-methods {
    grid-area: payment-methods;
    margin-bottom: ${spacing(2)};

    .image:not(:first-child) {
      margin-left: ${spacing(0.25)};
    }
  }

  
    
  @media only screen and (min-width: ${breakpoints.smMax}) {
    justify-content: space-evenly;
    grid-template-areas:
      'links payment-methods'
      'rights-reserved payment-methods';
    
    .payment-methods{
      margin: auto ${spacing(0.5)};
    }
  }
    
  @media only screen and (min-width: ${breakpoints.mdMax}) {
    padding: ${spacing()};
    grid-template-areas: 'links payment-methods rights-reserved';
   
    .links {
      margin: auto 0;
    }
  }
  `,
);

const footerNavItems = {
  public: [
    {
      href: '/recommendation?reset',
      title: 'nav.recommendation_machine',
    },
    {
      href: '/',
      title: 'nav.login',
    },
  ],
  auth: [
    {
      href: '/',
      title: 'nav.frontpage',
    },
    {
      href: '/orders',
      title: 'nav.orders',
    },
    {
      href: '/profile',
      title: 'nav.profile',
    },
    {
      href: '/my-herd',
      title: 'nav.my_herd',
    },
    {
      href: '/benefits',
      title: 'nav.benefits',
    },
  ],
};

const Footer = () => {
  const t = useTranslation();
  const locale = useLocale();
  const { isLoggedIn } = useIsLoggedIn();
  const { route } = useRouter();
  const matchMarketingSite = !isLoggedIn && route.includes('/recommendation');

  const { country = mapLocaleToShippingCountry(locale) || ShippingCountry.FI } =
    useShippingCountry();

  return (
    <SiteFooter matchMarketingSite={matchMarketingSite}>
      <UpperFooter matchMarketingSite={matchMarketingSite}>
        <FlexWrapper
          className="footer-section logo"
          direction="column"
          alignItems="center"
        >
          <Image src="/logo.svg" width="120" height="62" alt="Alvar Pet logo" />
          <Text>
            {t('navigation.go_to_x')}{' '}
            <Link href={LINKS.MARKETING_SITE[locale]}>
              {LINKS.MARKETING_SITE[locale].split('www.')[1]}
            </Link>
          </Text>
        </FlexWrapper>

        <FlexWrapper
          className="footer-section middle"
          direction="column"
          alignItems="center"
        >
          <ContactChannels locale={locale} />
          <LanguageSelector matchMarketingSite={matchMarketingSite} />
        </FlexWrapper>
        <FlexWrapper as="ul" className="footer-section footer-navigation">
          {shippingCountries.includes(country) &&
            footerNavItems[isLoggedIn ? 'auth' : 'public'].map(
              ({ href, title }) => (
                <li key={title}>
                  <Link href={href}>{t(title)}</Link>
                </li>
              ),
            )}
          {isLoggedIn && (
            <li key="logout">
              <LogoutButton />
            </li>
          )}
        </FlexWrapper>
      </UpperFooter>
      <LowerFooter matchMarketingSite={matchMarketingSite}>
        <FlexWrapper justify="center" className="payment-methods">
          {shippingCountries.includes(country) &&
            getPaymentMethods(country).map((method) => (
              <Image
                src={`/icons/payment-methods/${method}.svg`}
                height={22}
                width={46}
                objectFit="contain"
                alt={method}
                key={method}
              />
            ))}
        </FlexWrapper>
        <FlexWrapper wrap justify="center" className="links">
          <Link href={LINKS.TERMS_CONDITIONS[locale]}>
            {t('recommendation.form.terms_conditions_link')}
          </Link>
          <Link href={LINKS.PRIVACY_POLICY[locale]}>
            {t('footer.privacy_policy')}
          </Link>
        </FlexWrapper>

        <Small align="center" transform="uppercase" className="rights-reserved">
          {t('footer.disclaimer', { year: new Date().getFullYear() })}
        </Small>
      </LowerFooter>
    </SiteFooter>
  );
};

export default Footer;
