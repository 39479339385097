import { ReactNode } from 'react';
import styled from 'styled-components';

import Footer from './Footer';
import Navigation from './Navigation';
import AccessControl from './AccessControl';
import { NotificationProvider } from '../lib/notification';

interface LayoutProps {
  children: ReactNode;
  route: string;
}

const Main = styled.main`
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-top: ${({ theme }) => theme.constants.navbarHeight};
`;

const LayoutContainer = styled.div`
  margin: 0 auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;
  background: ${(p) => p.theme.colors.backgroundColor};
  transition: background 0.125s ease-in;
`;

const ContentLayout = styled.div`
  margin: 0 auto;
  width: 100%;
  max-width: ${(p) => p.theme.maxWidth};
`;

/*
 * Public made routes. Only looks at the first param, so everything under this route is to be public.
 */
const PUBLIC_ROUTES = ['recommendation', '404', 'admin-switch-user'];

export const isAuthRoute = (path: string) =>
  !PUBLIC_ROUTES.includes(
    path.replace('/[locale]', '').split('/').filter(Boolean)[0],
  );

const Layout = ({ children, route }: LayoutProps) => {
  return (
    <LayoutContainer>
      <Navigation />
      <Main>
        <ContentLayout>
          <NotificationProvider>
            {isAuthRoute(route) ? (
              <AccessControl>{children}</AccessControl>
            ) : (
              children
            )}
          </NotificationProvider>
        </ContentLayout>
      </Main>
      <Footer />
    </LayoutContainer>
  );
};

export default Layout;
